import React, {useEffect, useRef} from 'react';
import './AutoFocusedContainer.scss';

type Props = {
  className?: string,
  preventScroll: boolean,
  children?: React.ReactNode
};

export function AutoFocusedContainer({className, preventScroll, children}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.focus({preventScroll});
    }
  }, [preventScroll]);
  return (
    <div className={`autoFocusedContainer ${className ?? ''}`} tabIndex={-1} ref={ref}>
      {children}
    </div>
  );
}