import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ARROW} from '../../constants/constants';
import {BezierCurve, Box, Dimensions, RoundedBox} from '../../types/Geometry';
import {
  boxFromPoint,
  clamp,
  combineXY,
  equal,
  expandBox,
  getAngleTo,
  getBezierCurvePoint,
  getBoundingBox,
  getBoundingBoxOfBoxes,
  getBoxCenter,
  getBoxSize,
  getDistance,
  getMiddle,
  getPointAtAngle,
  greater,
  insideRoundedBox,
  less,
  reverseBezierCurve,
  smoothBezierTransition,
  trimBezierCurve
} from '../../helpers/geometryHelper';
import {EventDispatcher} from '../../helpers/eventDispatcher';
import {domRectToBox, isButtonClickKey, pixel, viewportToDocument} from '../../helpers/domHelper';
import {getAdjacentPairs, getLast, ifNotNull, nonNull} from '../../helpers/arrayHelper';
import {choice} from '../../helpers/syntaxHelper';
import {useUniqueId} from '../../hooks/useUniqueId';
import {Popup, PopupPosition, PopupType} from '../Popup/Popup';
import {AutoFocusedContainer} from '../AutoFocusedContainer/AutoFocusedContainer';
import './MultiArrow.scss';

const backgroundHalfWidthVariableName = '--multiArrow-background-half-width';

export enum PopupPositionPreference {
  Regular = 'regular',
  Top = 'top'
}

type End = {
  point: Dimensions,
  cuttingBoxes?: RoundedBox[],
  requiresBracePointer?: boolean,
  includeBox?: {
    box: Box,
    boxMargin?: number,
    popupAnchor?: boolean
  }
};

export enum ArrowSide {
  Input = 'input',
  Output = 'output'
}

type Props = {
  popupPositionPreference?: PopupPositionPreference,
  input: End[],
  inputAsSecondary?: boolean,
  outputs: {
    output: End[],
    symmetricInput?: End[] | null,
    symmetricOutput?: End[] | null
  }[],
  outputsAsSecondary?: boolean,
  straightEnd?: ArrowSide,
  dashed?: boolean,
  label: React.ReactNode,
  popupLabel: string,
  children: React.ReactNode,
  autoShowPopup?: boolean,
  moveEventDispatcher: EventDispatcher
};

type PrimitiveShapes = {
  primitive: React.ReactNode,
  background: React.ReactNode,
  secondary: React.ReactNode,
  boundingBoxes: Box[]
};

function getPrimitive(primitive: React.ReactNode, background: React.ReactNode, secondary: React.ReactNode,
                      boundingPoints: Dimensions[], addMargin: boolean = true): PrimitiveShapes {
  const boundingBoxes: Box[] = boundingPoints.length === 0 ? [] : [
    expandBox(getBoundingBox(boundingPoints), addMargin ? ARROW.backgroundHalfWidth : 0)
  ];
  return {primitive, background, secondary, boundingBoxes};
}

function intersectWithBox(curve: BezierCurve, box: RoundedBox): number {
  type Data = {
    time: number,
    point: Dimensions
  };
  const getData = (time: number): Data => ({
    time,
    point: getBezierCurvePoint(curve, time)
  });
  let [left, right] = [0, 1].map((time) => getData(time));
  if (!insideRoundedBox(left.point, box)) {
    return left.time;
  }
  if (insideRoundedBox(right.point, box)) {
    return right.time;
  }
  while (getDistance(left.point, right.point) >= ARROW.intersectionSearchGranularity) {
    const middle = getData((left.time + right.time) / 2);
    if (insideRoundedBox(middle.point, box)) {
      left = middle;
    } else {
      right = middle;
    }
  }
  return right.time;
}

function boundBoxAndPoint({box, borderRadius}: RoundedBox, point: Dimensions): RoundedBox {
  return {
    box: getBoundingBoxOfBoxes([box, expandBox(boxFromPoint(point), ARROW.precisionTolerance)]),
    borderRadius
  };
}

function cutBezierCurve(curve: BezierCurve,
                        inputCuttingBoxes: RoundedBox[], outputCuttingBoxes: RoundedBox[]): BezierCurve | null {
  const getT = (point: Dimensions, cuttingBoxes: RoundedBox[], curve: BezierCurve) => Math.max(0, ...cuttingBoxes.map(
    (box) => intersectWithBox(curve, box)
  ));
  const initialT = getT(curve.a, inputCuttingBoxes, curve);
  const finalT = 1 - getT(curve.b, outputCuttingBoxes, reverseBezierCurve(curve));
  return less(initialT, finalT) ? trimBezierCurve(curve, initialT, finalT) : null;
}

function endsToBezierCurve(input: Dimensions, output: Dimensions, straightEnd: ArrowSide): BezierCurve {
  const controlDistance = Math.abs(input.y - output.y) * ARROW.controlDistanceRatio;
  const control = straightEnd === ArrowSide.Input
    ? getPointAtAngle(input, Math.PI / 2, controlDistance)
    : getPointAtAngle(output, -Math.PI / 2, controlDistance);
  const minDistance = Math.min(getDistance(input, control), getDistance(control, output));
  const getTrimmedControl = (point: Dimensions) => getPointAtAngle(point, getAngleTo(point, control), minDistance);
  return {
    a: input,
    ca: getTrimmedControl(input),
    cb: getTrimmedControl(output),
    b: output
  };
}

function endsToLine(a: Dimensions, b: Dimensions): BezierCurve {
  const middle = getMiddle(a, b);
  return {
    a,
    ca: middle,
    cb: middle,
    b
  };
}

function string(point: Dimensions): string {
  return `${point.x} ${point.y}`;
}

function reverseBezierCurves(curves: BezierCurve[]): BezierCurve[] {
  return curves.map((curve) => reverseBezierCurve(curve)).reverse();
}

function getArrowShapes(curves: BezierCurve[], asSecondary: boolean, dashed: boolean, drawChevron: boolean,
                        key: string): PrimitiveShapes {
  if (curves.length === 0) {
    return getPrimitive(null, null, null, []);
  }
  const lastCurve = getLast(curves)!;
  const outputNormal = getAngleTo(lastCurve.b, lastCurve.cb);
  const outputTip = lastCurve.b;
  const chevron = drawChevron ? [1, -1].map((orientation) => getPointAtAngle(
    outputTip, outputNormal + orientation * ARROW.chevron.angle / 2, ARROW.chevron.length
  )) : null;
  const path = reverseBezierCurves(curves).map( // The reversal is so that dash starts at the chevron
    ({a, ca, cb, b}) => `M ${string(a)} C ${string(ca)} ${string(cb)} ${string(b)}`
  ).join(' ');
  return getPrimitive(
    asSecondary ? null : (
      <g key={key}>
        <path d={path} className={`multiArrow_arrow multiArrow_arrow-${dashed ? 'dashed' : 'normal'}`} />
        {chevron && <path d={`M ${string(chevron[0])} ${string(outputTip)} L ${string(chevron[1])}`} />}
      </g>
    ),
    asSecondary ? null : <path key={key} d={path} />,
    asSecondary ? <path key={key} d={path} /> : null,
    curves.flatMap(({a, ca, cb, b}) => [a, ca, cb, b])
  );
}

function getArrowCurves(input: End, intermediate: Dimensions[], output: End, smoothingSide: ArrowSide | null,
                        straightEnd: ArrowSide): BezierCurve[] {
  const preparedIntermediate: Dimensions[] = intermediate.length === 2 ? [
    intermediate[0],
    getMiddle(intermediate[0], intermediate[1]),
    intermediate[1]
  ] : intermediate;
  const shouldConsiderSmoothing = (side: ArrowSide | null) =>
    intermediate.length > 1 || (intermediate.length === 1 && smoothingSide === side);
  const getCuttingBoxes = ({point, cuttingBoxes}: End) => (cuttingBoxes ?? []).map(
    (box) => boundBoxAndPoint(box, point)
  );
  const inputCuttingBoxes = getCuttingBoxes(input);
  const outputCuttingBoxes = getCuttingBoxes(output);
  const getCurve = (a: Dimensions, b: Dimensions, aIndex: number, bIndex: number, length: number,
                    straightEnd: ArrowSide): BezierCurve => {
    const first = aIndex === 0;
    const last = bIndex === length - 1;
    if (first && last) {
      return endsToBezierCurve(a, b, straightEnd);
    } else if (first !== last) {
      return endsToBezierCurve(a, b, first ? ArrowSide.Output : ArrowSide.Input);
    } else {
      return endsToLine(a, b);
    }
  };
  const curves = getAdjacentPairs([input.point, ...preparedIntermediate, output.point]).map(
    ([inputPoint, outputPoint, inputIndex, outputIndex, allEnds]) => cutBezierCurve(getCurve(
      inputPoint, outputPoint, inputIndex, outputIndex, allEnds.length, straightEnd
    ), inputCuttingBoxes, outputCuttingBoxes)
  );
  const considerSmoothing = (first: BezierCurve | null, second: BezierCurve | null) => {
    const nonSmoothed = [first, second];
    if (first === null || second === null) {
      return nonSmoothed;
    }
    type Deltas = {dx: number, dy: number};
    const getDeltas = (curve: BezierCurve) => ({
      dx: Math.abs(curve.b.x - curve.a.x),
      dy: Math.abs(curve.b.y - curve.a.y)
    });
    const deltas = {
      first: getDeltas(first),
      second: getDeltas(second)
    };
    const isVertical = ({dx}: Deltas) => equal(dx, 0, ARROW.precisionTolerance);
    const getSlope = ({dx, dy}: Deltas) => dy / dx;
    const verticals = {
      first: isVertical(deltas.first),
      second: isVertical(deltas.second)
    };
    const getSmoothed = (nonVertical: Deltas, vertical: Deltas, directOrder: boolean) => {
      const slope = getSlope(nonVertical);
      if (less(slope, ARROW.smoothing.minSlope, ARROW.precisionTolerance)) {
        const nonVerticalRatio = Math.min(
          1 - slope / ARROW.smoothing.minSlope,
          ARROW.smoothing.maxDistance / nonVertical.dx
        );
        const verticalRatio = equal(vertical.dy, 0, ARROW.precisionTolerance)
          ? 1
          : Math.min(1, nonVerticalRatio * nonVertical.dx / vertical.dy);
        const [firstRatio, secondRatio] = directOrder
          ? [1 - nonVerticalRatio, verticalRatio]
          : [1 - verticalRatio, nonVerticalRatio];
        return smoothBezierTransition(first, firstRatio, second, secondRatio);
      } else {
        return nonSmoothed;
      }
    };
    if (!verticals.first && verticals.second) {
      return getSmoothed(deltas.first, deltas.second, true);
    } else if (verticals.first && !verticals.second) {
      return getSmoothed(deltas.second, deltas.first, false);
    } else {
      return nonSmoothed;
    }
  };
  const considerSmoothingInput = (curves: (BezierCurve | null)[]): (BezierCurve | null)[] => {
    return shouldConsiderSmoothing(ArrowSide.Input) && curves.length >= 2 ? [
      ...considerSmoothing(curves[0], curves[1]),
      ...curves.slice(2)
    ] : curves;
  };
  const considerSmoothingOutput = (curves: (BezierCurve | null)[]): (BezierCurve | null)[] => {
    return shouldConsiderSmoothing(ArrowSide.Output) && curves.length >= 2 ? [
      ...curves.slice(0, -2),
      ...considerSmoothing(curves[curves.length - 2], curves[curves.length - 1])
    ] : curves;
  };
  return nonNull(considerSmoothingInput(considerSmoothingOutput(curves)));
}

function getArrow(input: End, intermediate: Dimensions[], output: End, smoothingSide: ArrowSide | null,
                  asSecondary: boolean, straightEnd: ArrowSide, dashed: boolean, drawChevron: boolean,
                  key: string): PrimitiveShapes {
  return getArrowShapes(
    getArrowCurves(input, intermediate, output, smoothingSide, straightEnd),
    asSecondary, dashed, drawChevron, key
  );
}

function getEndsBoundingBox(ends: End[]): Box {
  return getBoundingBoxOfBoxes(ends.flatMap(
    ({cuttingBoxes = [], point}) => [...cuttingBoxes.map(({box}) => box), boxFromPoint(point)]
  ));
}

function getBrace(ends: End[], asSecondary: boolean, side: ArrowSide, key: string): [PrimitiveShapes[], Dimensions] {
  const boundingBox = getEndsBoundingBox(ends);
  const [left, top, right, bottom] = [boundingBox.min.x, boundingBox.min.y, boundingBox.max.x, boundingBox.max.y];
  const baseY = side === ArrowSide.Input ? bottom : top;
  const verticalMultiplier = side === ArrowSide.Input ? 1 : -1;
  const horizontalMiddle = getBoxCenter(boundingBox).x;
  const leftHalfSize = (horizontalMiddle - left) / 2;
  const rightHalfSize = (right - horizontalMiddle) / 2;
  const leftEndpoint = {x: left, y: baseY - verticalMultiplier * ARROW.brace.end};
  const rightEndpoint = {x: right, y: baseY - verticalMultiplier * ARROW.brace.end};
  const tip = {x: horizontalMiddle, y: baseY + verticalMultiplier * ARROW.brace.middle};
  const path = `M
  ${string(leftEndpoint)}
  C
  ${string({x: left, y: baseY})}
  ${string({x: left, y: baseY})}
  ${string({x: left + Math.min(ARROW.brace.endControl, leftHalfSize), y: baseY})}
  L
  ${string({x: horizontalMiddle - Math.min(ARROW.brace.middleControl, leftHalfSize), y: baseY})}
  C
  ${string({x: horizontalMiddle, y: baseY})}
  ${string({x: horizontalMiddle, y: baseY})}
  ${string(tip)}
  C
  ${string({x: horizontalMiddle, y: baseY})}
  ${string({x: horizontalMiddle, y: baseY})}
  ${string({x: horizontalMiddle + Math.min(ARROW.brace.middleControl, rightHalfSize), y: baseY})}
  L
  ${string({x: right - Math.min(ARROW.brace.endControl, rightHalfSize), y: baseY})}
  C
  ${string({x: right, y: baseY})}
  ${string({x: right, y: baseY})}
  ${string(rightEndpoint)}
  `;
  const getPointerPath = (point: Dimensions) => `M
  ${string({x: point.x, y: baseY})}
  L
  ${string({x: point.x, y: baseY - verticalMultiplier * ARROW.backgroundHalfWidth / 2})}
  `;
  const primitives: PrimitiveShapes[] = [
    getPrimitive(
      asSecondary ? null : <path key={key} d={path} />,
      asSecondary ? null : <path key={key} d={path} />,
      asSecondary ? <path key={key} d={path} /> : null,
      [leftEndpoint, rightEndpoint, tip]
    ),
    ...(asSecondary ? [] : ends.map(({point, requiresBracePointer}, index) => getPrimitive(requiresBracePointer ? (
      <path key={`${key}-${index}`} d={getPointerPath(point)} />
    ) : null, null, null, [])))
  ];
  return [primitives, tip];
}

function getBoxPrimitives(ends: End[], innerAnchorRef: React.RefObject<SVGPathElement>,
                          key: string): PrimitiveShapes[] {
  return ends.flatMap(({includeBox}, index) => {
    if (!includeBox) {
      return [];
    }
    const innerBox = includeBox.box;
    const outerBox = expandBox(innerBox, includeBox.boxMargin ?? 0);
    const getPath = (box: Box) => `
      M
      ${string(box.min)}
      L
      ${string(combineXY(box.min, box.max))}
      L
      ${string(box.max)}
      L
      ${string(combineXY(box.max, box.min))}
      Z
    `;
    return [
      getPrimitive(
        null,
        <path key={`${key}-${index}-outer`} className="multiArrow_box" d={getPath(outerBox)} />,
        null,
        [outerBox.min, outerBox.max],
        false
      ),
      getPrimitive(
        null,
        <path key={`${key}-${index}-inner`} className="multiArrow_box" d={getPath(innerBox)}
              ref={includeBox.popupAnchor ? innerAnchorRef : null} />,
        null,
        [],
        false
      )
    ];
  });
}

function getEndsHorizontalMiddle(ends: End[]): number {
  return getBoxCenter(getEndsBoundingBox(ends)).x;
}

function constructPrimitives(
  input: End[], symmetricInput: End[] | null, inputAsSecondary: boolean, shouldAddInput: boolean,
  output: End[], symmetricOutput: End[] | null, outputAsSecondary: boolean,
  straightEnd: ArrowSide, dashed: boolean, innerAnchorRef: React.RefObject<SVGPathElement>, index: number
): PrimitiveShapes[] {
  const shapes: PrimitiveShapes[] = [];
  if (input.length === 0 || output.length === 0) {
    return shapes;
  }
  const process = (ends: End[], symmetricEnds: End[] | null, asSecondary: boolean, side: ArrowSide, otherSide: End[],
                   shapes: PrimitiveShapes[], shouldAdd: boolean): {main: End, intermediate: Dimensions | null} => {
    const processInitial = (ends: End[]): [End, PrimitiveShapes[]] => {
      if (ends.length === 1) {
        return [ends[0], []];
      } else {
        const [braces, tip] = getBrace(ends, asSecondary, side, `brace-${index}-${side}`);
        return [{
          point: tip,
          cuttingBoxes: [{
            box: expandBox(boxFromPoint(tip), ARROW.brace.cuttingBoxHalfSize),
            borderRadius: ARROW.brace.cuttingBoxHalfSize
          }]
        }, braces];
      }
    };
    const [initialEnd, braces] = processInitial(ends);
    if (shouldAdd) {
      shapes.push(...getBoxPrimitives(ends, innerAnchorRef, `box-${index}`));
      shapes.push(...braces);
    }
    const getIntermediate = (): Dimensions | null => {
      if (symmetricEnds === null) {
        return null;
      } else {
        const [{point}] = processInitial(symmetricEnds);
        return (side === ArrowSide.Input && greater(point.y, initialEnd.point.y, ARROW.precisionTolerance)) ||
               (side === ArrowSide.Output && less(point.y, initialEnd.point.y, ARROW.precisionTolerance))
          ? {
            x: 2 * getEndsHorizontalMiddle(otherSide) - getEndsHorizontalMiddle(symmetricEnds),
            y: point.y
          }
          : null;
      }
    };
    return {
      main: initialEnd,
      intermediate: getIntermediate()
    };
  };
  const inputEnds = process(input, symmetricInput, inputAsSecondary, ArrowSide.Input, output, shapes, shouldAddInput);
  const outputEnds = process(output, symmetricOutput, outputAsSecondary, ArrowSide.Output, input, shapes, true);
  const intermediate = [
    ...ifNotNull(outputEnds.intermediate), // outputEnds should really go first in the case both are defined
    ...ifNotNull(inputEnds.intermediate)
  ];
  const [hasInputIntermediate, hasOutputIntermediate] = [inputEnds.intermediate, outputEnds.intermediate].map(
    (item) => item !== null
  );
  const smoothingSide = choice([
    [hasInputIntermediate && !hasOutputIntermediate, ArrowSide.Input],
    [!hasInputIntermediate && hasOutputIntermediate, ArrowSide.Output]
  ], null);
  shapes.push(getArrow(
    inputEnds.main, intermediate, outputEnds.main, smoothingSide,
    outputAsSecondary, straightEnd, dashed, true, `arrow-${index}`
  ));
  return shapes;
}

export function MultiArrow({
  popupPositionPreference = PopupPositionPreference.Regular,
  input, inputAsSecondary = false,
  outputs, outputsAsSecondary = false,
  straightEnd = ArrowSide.Input, dashed = false,
  label, popupLabel, children, autoShowPopup, moveEventDispatcher
}: Props) {
  const labelId = useUniqueId();
  const containerRef = useRef<HTMLDivElement>(null);
  const innerAnchorRef = useRef<SVGPathElement>(null);
  const [popupAnchorBox, setPopupAnchorBox] = useState<Box | null>(null);
  const [focused, setFocused] = useState(false);
  const clampToDocument = useCallback((point: Dimensions): Dimensions => ({
    x: clamp(point.x, 0, document.documentElement.scrollWidth),
    y: clamp(point.y, 0, document.documentElement.scrollHeight)
  }), []);
  const clampBoxToDocument = useCallback(({min, max}: Box): Box => ({
    min: clampToDocument(min),
    max: clampToDocument(max)
  }), [clampToDocument]);
  const showPopup = useCallback((anchorBox: Box) =>
    setPopupAnchorBox((box) => box || clampBoxToDocument(viewportToDocument(anchorBox))), [clampBoxToDocument]);
  const hidePopup = useCallback(() => setPopupAnchorBox(null), []);
  const positionPreference: PopupPosition[] = useMemo(() => {
    switch (popupPositionPreference) {
      case PopupPositionPreference.Regular:
        return [
          PopupPosition.Right,
          PopupPosition.Left,
          PopupPosition.Bottom,
          PopupPosition.Top,
          PopupPosition.Bottom
        ];
      case PopupPositionPreference.Top:
        return [
          PopupPosition.Top,
          PopupPosition.Bottom
        ];
    }
  }, [popupPositionPreference]);
  const displayPopup = useCallback(() => {
    if (innerAnchorRef.current !== null && innerAnchorRef.current.isConnected) {
      showPopup(domRectToBox(innerAnchorRef.current.getBoundingClientRect()));
    } else if (containerRef.current !== null) {
      const bounds = containerRef.current.getBoundingClientRect();
      showPopup(boxFromPoint({
        x: bounds.left + bounds.width / 2,
        y: bounds.top + bounds.height / 2
      }));
    }
  }, [showPopup]);
  useEffect(() => {
    if (autoShowPopup) {
      const timeout = setTimeout(() => displayPopup(), ARROW.popup.autoShowDelay);
      return () => clearTimeout(timeout);
    }
  }, [autoShowPopup, displayPopup]);
  const primitives = outputs.flatMap(
    ({output, symmetricOutput = null, symmetricInput = null}, index) => constructPrimitives(
      input, symmetricInput, inputAsSecondary, index === 0,
      output, symmetricOutput, outputsAsSecondary,
      straightEnd, dashed, innerAnchorRef, index
    )
  );
  const boundingBoxes = primitives.flatMap(({boundingBoxes}) => boundingBoxes);
  if (boundingBoxes.length === 0 || !primitives.some(({primitive}) => primitive !== null)) {
    return null;
  }
  const boundingBox = getBoundingBoxOfBoxes(boundingBoxes);
  // We need the bounding box so that Firefox properly scrolls the viewport when focusing with Tab
  // As a bonus, this is potentially more performant, as the browser doesn't have to care about lots of large boxes
  const [left, top] = [boundingBox.min.x, boundingBox.min.y];
  const size = getBoxSize(boundingBox);
  const viewBox = `${left} ${top} ${size.x} ${size.y}`;
  const viewBoxStyles = {
    left: pixel(left),
    top: pixel(top),
    width: pixel(size.x),
    height: pixel(size.y)
  };
  return (
    <>
      <div className={`multiArrow` +
        ` multiArrow-${popupAnchorBox === null ? 'inactivePopup' : 'activePopup'}` +
        ` multiArrow-${focused ? 'focused' : 'nonFocused'}`}
           style={{
             ...viewBoxStyles,
             ...{[backgroundHalfWidthVariableName]: pixel(ARROW.backgroundHalfWidth)} as React.CSSProperties}
           }
           ref={containerRef}
           role="button"
           aria-haspopup="dialog"
           aria-labelledby={labelId}
           tabIndex={outputsAsSecondary ? -1 : 0}
           onClick={(event) => popupAnchorBox === null ? showPopup(expandBox(boxFromPoint({
             x: event.clientX,
             y: event.clientY
           }), ARROW.popup.margin)) : hidePopup()}
           onKeyDown={(event) => {
             if (isButtonClickKey(event) && popupAnchorBox === null) {
               displayPopup();
             }
           }}
           onFocus={() => setFocused(true)}
           onBlur={() => setFocused(false)}
      >
        <span id={labelId} hidden>{label}</span>
        <svg className="multiArrow_backgroundContainer" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
          <g className="multiArrow_backgroundContainerGroup">
            <g className="multiArrow_secondaries">
              {primitives.map(({secondary}) => secondary)}
            </g>
            <g className="multiArrow_backgrounds">
              {primitives.map(({background}) => background)}
            </g>
          </g>
        </svg>
        <svg className="multiArrow_primitiveContainer" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
          <g className="multiArrow_primitives">
            {primitives.map(({primitive}) => primitive)}
          </g>
        </svg>
      </div>
      {popupAnchorBox && (
        <Popup
          type={PopupType.Popover}
          label={popupLabel}
          shrinkToContent={true}
          anchor={containerRef}
          anchorBox={popupAnchorBox}
          positionPreference={positionPreference}
          moveEventDispatcher={moveEventDispatcher}
          onDismissed={hidePopup}
        >
          <AutoFocusedContainer className="multiArrow_popupContainer" preventScroll={true}>
            {children}
          </AutoFocusedContainer>
        </Popup>
      )}
    </>
  );
}