import React from 'react';
import {ARROW, TREE} from '../../constants/constants';
import {WordWithMeasurements, WordWithMeasurementsAndPosition} from '../../types/WordGeometry';
import {Box, Dimensions} from '../../types/Geometry';
import {APIEtymologyWord} from '../../types/APIResponse';
import {addVectors, divideVector} from '../geometryHelper';
import {pixel} from '../domHelper';
import {ReactComponent as EqualSvg} from '../../images/equal.svg';
import {ReactComponent as LinkSvg} from '../../images/link.svg';
import {ReactComponent as NoteSvg} from '../../images/note.svg';
import './treeHelper.scss';

export type W = APIEtymologyWord<WordWithMeasurements>;
export type WP = APIEtymologyWord<WordWithMeasurementsAndPosition>;

export type Positionable = {
  size: Dimensions,
  center: Dimensions,
  position: Dimensions
};

export type Graphic = Positionable & {
  content: React.ReactNode
};

enum GraphicSize {
  Regular = 'regular',
  Wide = 'wide'
}

export enum GraphicImage {
  Equal = 'equal',
  Link = 'link',
  Note = 'note'
}

export function getPositioned(item: Positionable): Box {
  return {
    min: {
      x: item.position.x - item.center.x,
      y: item.position.y - item.center.y
    },
    max: {
      x: item.position.x + item.size.x - item.center.x,
      y: item.position.y + item.size.y - item.center.y
    }
  };
}

export function getBranchSpacing(): number {
  return 2 * TREE.itemArrowMargin + ARROW.backgroundHalfWidth + TREE.spacing.horizontal.branchExtra;
}

export function getWordDistance(left: WordWithMeasurements, right: WordWithMeasurements,
                                sameBranch: boolean = true): number {
  return left.size.x - left.center.x + right.center.x
    + (sameBranch ? TREE.spacing.horizontal.word : getBranchSpacing());
}

export function shiftItem<T extends Positionable>(item: T, shift: Dimensions): T {
  return {
    ...item,
    position: addVectors(item.position, shift)
  };
}

export function shiftItems<T extends Positionable>(items: T[], shift: Dimensions): T[] {
  return items.map((item) => shiftItem(item, shift));
}

function getGraphicData(image: GraphicImage): [React.FunctionComponent<React.SVGProps<SVGSVGElement>>, GraphicSize] {
  switch (image) {
    case GraphicImage.Equal:
      return [EqualSvg, GraphicSize.Wide];
    case GraphicImage.Link:
      return [LinkSvg, GraphicSize.Wide];
    case GraphicImage.Note:
      return [NoteSvg, GraphicSize.Regular];
  }
}

function graphicSizeToDimensions(size: GraphicSize): Dimensions {
  return {
    x: TREE.iconSize,
    y: size === GraphicSize.Wide ? TREE.iconSize / 2 : TREE.iconSize
  };
}

export function getGraphic(image: GraphicImage, position: Dimensions, label: string | null = null): Graphic {
  const [Svg, size] = getGraphicData(image);
  const dimensions = graphicSizeToDimensions(size);
  return {
    content: (
      <Svg className="treeHelper_icon"
           {...(label === null
             ? {'aria-hidden': true}
             : {'aria-label': label, role: 'img'})}
           style={{
             '--treeHelper-icon-width': pixel(dimensions.x),
             '--treeHelper-icon-height': pixel(dimensions.y)
           } as React.CSSProperties}
      />
    ),
    size: dimensions,
    center: divideVector(dimensions, 2),
    position
  };
}