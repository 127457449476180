export class IncrementalPosition {
  distances: number[] = [];
  nextIndex: number = 0;
  nextPosition: number = 0;

  constructor(shift: number = 0) {
    this.nextPosition = shift;
  }

  addDistance(distance: number): void {
    this.distances.push(distance);
    this.nextPosition -= distance / 2;
  }

  getPosition(): number {
    const position = this.nextPosition;
    if (this.nextIndex < this.distances.length) {
      this.nextPosition += this.distances[this.nextIndex];
      this.nextIndex++;
    }
    return position;
  }
}