import {LANGUAGES_OF_INTEREST, EXTRA_LANGUAGE} from '../constants/constants';
import {Language} from '../types/Language';

const separators = {
  languages: ',',
  parts: ':'
};

const booleans = {
  false: '0',
  true: '1'
};

function parseLanguages(string: string): Language[] {
  return string.split(separators.languages).flatMap((part) => {
    const subParts = part.split(separators.parts).map((subPart) => decodeURIComponent(subPart).trim());
    return subParts.length === 2 && subParts[0] !== '' && subParts[1] !== '' ? [{
      code: subParts[0],
      name: subParts[1]
    }] : [];
  });
}

function stringifyLanguages(languages: Language[]): string {
  return languages.map(
    (language) => [language.code, language.name || ''].map((part) => encodeURIComponent(part)).join(separators.parts)
  ).join(separators.languages);
}

function parseBoolean(string: string): boolean {
  return string === booleans.true;
}

function stringifyBoolean(value: boolean): string {
  return value ? booleans.true : booleans.false;
}

export function getLanguagesOfInterest(): Language[] {
  const languagesInStorage = parseLanguages(localStorage.getItem(LANGUAGES_OF_INTEREST.key) ?? '');
  return languagesInStorage.length > 0 ? languagesInStorage : LANGUAGES_OF_INTEREST.default;
}

export function getLanguagesOfInterestInitialized(): boolean {
  return localStorage.getItem(LANGUAGES_OF_INTEREST.key) !== null;
}

export function setLanguagesOfInterest(languages: Language[]): void {
  localStorage.setItem(LANGUAGES_OF_INTEREST.key, stringifyLanguages(languages));
}

export function getExtraLanguage(): Language | null {
  const languagesInStorage = parseLanguages(localStorage.getItem(EXTRA_LANGUAGE.key) ?? '');
  return languagesInStorage.length > 0 ? languagesInStorage[0] : null;
}

export function getExtraLanguageEnabled(): boolean {
  return getExtraLanguage() ? parseBoolean(localStorage.getItem(EXTRA_LANGUAGE.enabledKey) ?? '') : false;
}

export function setExtraLanguage(language: Language): void {
  localStorage.setItem(EXTRA_LANGUAGE.key, stringifyLanguages([language]));
}

export function setExtraLanguageEnabled(enabled: boolean): void {
  localStorage.setItem(EXTRA_LANGUAGE.enabledKey, stringifyBoolean(enabled));
}