import {useEffect, useRef} from 'react';
import {SLOW_LOADING_THRESHOLD} from '../constants/constants';
import {APIResponse, APIResponseStatus} from '../types/APIResponse';
import {APIRequest, APIRequestType} from '../types/APIRequest';
import {WordWithMeasurements} from '../types/WordGeometry';
import {requestToApiUrl} from '../helpers/requestToApiUrl';
import {cache, retrieveCached} from '../helpers/requestCacher';
import {timeoutPromise} from '../helpers/promiseHelper';
import {getWordMeasurements} from '../helpers/wordMeasurer/wordMeasurer';
import {useUpdated} from './useUpdated';
import {useForceUpdate} from './useForceUpdate';

function loadingResponse(slow: boolean): APIResponse<WordWithMeasurements> {
  return {
    status: APIResponseStatus.Loading,
    slow
  };
}

function dataResponse(type: APIRequestType, response: any): APIResponse {
  return {
    status: APIResponseStatus.OK,
    data: {
      type,
      response
    }
  };
}

function errorResponse(status: APIResponseStatus.Error | APIResponseStatus.Quota): APIResponse {
  return {
    status
  };
}

async function load(url: string, type: APIRequestType): Promise<APIResponse> {
  const cached = await retrieveCached(url);
  if (cached !== null) {
    return dataResponse(type, cached);
  }
  try {
    const response = await fetch(url);
    switch (response.status) {
      case 200:
        const data = await response.json();
        cache(url, data).then();
        return dataResponse(type, data);
      case 429:
        return errorResponse(APIResponseStatus.Quota);
      default:
        return errorResponse(APIResponseStatus.Error);
    }
  } catch (e) {
    return errorResponse(APIResponseStatus.Error);
  }
}

export function useApi(request: null): null;
export function useApi(request: APIRequest): APIResponse<WordWithMeasurements>;
export function useApi(request: APIRequest | null): APIResponse<WordWithMeasurements> | null;
export function useApi(request: APIRequest | null): APIResponse<WordWithMeasurements> | null {
  const state = useRef<APIResponse<WordWithMeasurements> | null>(null);
  const relevancy = {};
  const relevancyRef = useRef(relevancy);
  const url = request === null ? null : requestToApiUrl(request);
  const type = request === null ? null : request.type;
  const requestUpdated = useUpdated(url, true);
  const forceUpdate = useForceUpdate();
  const initialResponse = request === null ? null : loadingResponse(false);
  useEffect(() => {
    if (!requestUpdated) {
      return;
    }
    relevancyRef.current = relevancy;
    state.current = initialResponse;
    if (url === null || type === null) { // The second comparison is for type checking
      return;
    }
    const isRelevant = () => relevancyRef.current === relevancy;
    timeoutPromise(SLOW_LOADING_THRESHOLD).then(() => {
      if (isRelevant() && state.current?.status === APIResponseStatus.Loading) {
        state.current = loadingResponse(true);
        forceUpdate();
      }
    });
    load(url, type).then(async (response) => {
      if (isRelevant()) {
        const measured = await getWordMeasurements(response);
        if (isRelevant()) {
          state.current = measured;
          forceUpdate();
        }
      }
    });
  });
  return requestUpdated ? initialResponse : state.current;
}