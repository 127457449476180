import React, {useRef} from 'react';
import {pixel} from '../../helpers/domHelper';
import {useUpdated} from '../../hooks/useUpdated';
import './Collapsable.scss';

type Props = {
  children: React.ReactNode,
  collapsed: boolean
};

export function Collapsable({children, collapsed}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const lastHeight = useRef(0);
  const collapsedUpdated = useUpdated(collapsed);
  if (collapsed && collapsedUpdated && ref.current !== null) {
    lastHeight.current = ref.current.offsetHeight;
  }
  return (
    <div className={`collapsable collapsable-${collapsed ? 'collapsed' : 'expanded'}`}
         style={{'--collapsable-height': pixel(lastHeight.current)} as React.CSSProperties}
         ref={ref}
    >
      {children}
    </div>
  );
}