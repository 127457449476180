import React, {useContext} from 'react';
import {Section} from '../../types/Section';
import {StateContext} from '../../context/StateContext';
import {AboutHeader} from '../AboutHeader/AboutHeader';
import {MainHeader} from '../MainHeader/MainHeader';
import './Header.scss';

export function Header() {
  const state = useContext(StateContext)!;
  return (
    <header className="header" role={state.section !== Section.About ? 'search' : undefined}>
      {state.section === Section.About ? <AboutHeader /> : <MainHeader />}
    </header>
  );
}