export const LOCALIZATION = {
  title: {
    notFound: 'Etymology not found',
    about: 'About Etym.io',
    generalTemplate: '[<s>] – Etym.io',
    etymology: 'Etymology',
    etymologyOf: '“[<a>]” etymology',
    relation: 'Word relation',
    relationOf: '“[<a>]”/“[<b>]” relationship',
    cognates: 'Cognates',
    cognatesOf: '“[<a>]” cognates'
  },
  notFound: {
    heading: '404 – Not found',
    text: 'Unfortunately, we couldn’t trace the origin of the URL you’ve entered. It might contain a typo or refer to a page that no longer exists.',
    link: 'Return to the dictionary'
  },
  menu: {
    label: 'Website section navigation',
    etymology: 'Etymology',
    relation: 'Word relation',
    cognates: 'Cognates',
    about: ['About', '?'],
    show: {
      title: 'show',
      label: 'Show website navigation and search'
    },
    hide: {
      title: 'hide',
      label: 'Hide website navigation and search'
    }
  },
  popups: {
    closeButtonLabel: 'Close popup window',
    closedLabel: 'Popups closed'
  },
  languagesOfInterest: {
    title: 'My languages',
    titleConnector: ': ',
    languageConnector: ', ',
    label: 'My preferred languages',
    help: {
      text: 'Suggestions in these languages will appear first as you type.',
      label: 'What are my preferred languages for?'
    },
    add: {
      title: 'Add language(s)',
      label: 'Add more languages'
    },
    edit: {
      title: 'Edit languages',
      label: 'Edit selected languages'
    },
    replace: {
      title: 'Replace [<language>]',
      label: '[<language>] is set; press to replace with a different language',
      popupLabel: 'Choose language'
    },
    mainPopup: {
      label: 'Manage selected languages',
      ariaUpdate: {
        template: 'Current language list: [<languages>]',
        connector: ', '
      },
      add: {
        multipleLeft: 'You can add [<n>] more languages:',
        singleLeft: 'You can add one more language:',
        noneLeft: 'Maximum number of languages selected.'
      },
      list: 'Reorder or remove languages:'
    }
  },
  listEditor: {
    up: {
      title: 'Move [<item>] up one position',
      label: 'Move [<item>] up one position'
    },
    remove: {
      title: 'Remove [<item>]',
      label: 'Remove [<item>]'
    }
  },
  customLanguages: {
    label: 'Language selection',
    languagesOfInterest: 'in my languages',
    languagesOfInterestLabelNonSpecific: 'Search in my preferred languages',
    languagesOfInterestLabelSpecific: 'Search in my preferred languages instead of [<language>]',
    customExtraLanguage: '[<connector>or ][set language]',
    customExtraLanguageChangeLabel: 'Set language (default is my preferred languages if not set)',
    specificExtraLanguage: '[in ][<language>]',
    specificExtraLanguageLabel: 'Switch to [<language>] instead of my preferred languages',
    specificExtraLanguageChangeLabel: 'Change language selection from [<language>]',
    specificExtraLanguageChangeLabelEdited: '[<language>] selected; press to select a different language',
    popupLabel: 'Choose language'
  },
  senses: {
    single: '[<a>]',
    double: '[<a>] / [<b>]',
    missing: '([<n>])',
    label: 'Word sense selection'
  },
  genericInputs: {
    cancel: {
      close: {
        title: 'Close dropdown',
        label: 'Close dropdown'
      },
      clear: {
        title: 'Clear input',
        label: 'Clear input'
      }
    },
    restore: {
      title: 'Restore to “[<text>]”',
      label: '[<restore>Restore:] [<text>]'
    }
  },
  wordInputs: {
    placeholders: {
      first: 'word or phrase',
      second: 'another word or phrase'
    },
    labels: {
      first: 'First word or phrase',
      second: 'Second word or phrase',
      only: 'Word or phrase to search'
    },
    buttons: {
      language: {
        title: 'Change language',
        label: '[<language>] selected; press to change'
      },
      invalid: {
        title: 'Select valid word',
        label: 'No valid word is selected; press to select'
      }
    },
    invalid: 'select',
    edit: {
      title: 'Change word or phrase',
      label: 'Change [<word>]'
    },
    loading: 'Loading...',
    error: 'We couldn’t connect to our servers. Please try again later.',
    quota: 'Our [request quota] has been exceeded. Please try again later.',
    notFound: 'We couldn’t find any matching words or phrases.',
    quickEditHint: {
      label: 'Search box shortcut tooltip',
      text: 'Press [<key>] (US layout) to jump to the box.'
    }
  },
  languageInputs: {
    placeholders: {
      main: 'language name',
      replacement: 'new language',
      new: 'new language'
    },
    labels: {
      main: 'Language name',
      replacement: 'New language name',
      new: 'New language name'
    },
    loading: 'Loading...',
    error: 'We couldn’t connect to our servers. Please try again later.',
    quota: 'Our [request quota] has been exceeded. Please try again later.',
    notFound: 'We couldn’t find any matching languages.',
    wordsForLanguageConnector: ', '
  },
  alerts: {
    loadingLabel: 'Loading data...',
    diagramLabel: 'Diagram',
    noData: {
      etymology: 'It looks like we don’t have the etymology for [<a>].',
      relation: 'It seems we don’t know of any relationship between [<a>] and [<b>].',
      relationSame: 'It appears we don’t have any information on an etymological relationship between different senses of [<a>].',
      cognates: 'It seems we’re not aware of any cognates for [<word>] in [<languages>].',
      wordTemplate: '[<word>] ([<language>])',
      languageConnectors: {
        default: ', ',
        betweenTwo: ' and ',
        lastConnector: ', and '
      }
    },
    serverError: 'We’re having trouble connecting to our servers. Please [try again] in a few moments.',
    quota: 'Our [request quota] has been exceeded. Please try again later.'
  },
  scrollContainer: {
    zoomIn: {
      title: 'Zoom in',
      label: 'Zoom in'
    },
    zoomOut: {
      title: 'Zoom out',
      label: 'Zoom out'
    }
  },
  words: {
    fullDefinition: 'Full definition',
    reconstructed: 'This form is reconstructed.',
    rangesLabel: {
      template: 'Shared: [<ranges>]',
      connector: ', '
    }
  },
  connections: {
    identicalWordsLabel: 'The words are identical',
    uncertain: 'This link is uncertain.',
    onomatopoeic: {
      singular: 'The word is [onomatopoeic].',
      plural: 'The words are [onomatopoeic].',
      link: 'https://en.wikipedia.org/wiki/Onomatopoeia'
    },
    label: {
      template: '[<uncertain>uncertain ][<onomatopoeic>onomatopoeic ]connection[<ends>]',
      to: ' to [<words>]',
      from: ' from [<words>]',
      fromUnknownCommonOrigin: ' from an unknown common origin',
      wordConnector: ', '
    },
    popupLabel: 'Connection details'
  },
  sources: {
    label: 'Sources',
    parentConnector: ', ',
    onomatopoeiaParent: '♪',
    parentToChildArrow: '→',
    parentToChildSymmetricArrow: '↔',
    itemTemplate: 'Refer to [<sources>].',
    sourceTemplate: '[<entries>] [<preposition>] [<source>]',
    sourcesConnector: '; ',
    withinSourceConnector: ', ',
    missing: 'Unfortunately, we are unable to provide any sources at this time.'
  }
};