import React from 'react';
import {Word} from '../types/Word';
import {WordWithMeasurements} from '../types/WordGeometry';
import {Language} from '../types/Language';
import {Section} from '../types/Section';
import {Location} from '../types/Location';
import {APIRequest} from '../types/APIRequest';
import {APIResponse} from '../types/APIResponse';
import {urlToLocation} from '../helpers/urlToLocation';
import {locationToRequest} from '../helpers/locationToRequest';
import {extractAFromLocation, extractBFromLocation} from '../helpers/wordExtractor';
import {
  getExtraLanguage, getExtraLanguageEnabled,
  getLanguagesOfInterest,
  getLanguagesOfInterestInitialized
} from '../helpers/languagesOfInterest';

export type InputState = {
  text: string,
  word: Word | null,
  lastWord: Word | null,
  shouldGetFocused: boolean
};

export type LanguagesOfInterestState = {
  languages: Language[],
  initialized: boolean
};

export type ExtraLanguageState = {
  language: Language | null,
  enabled: boolean
};

export type State = {
  section: Section,
  inputA: InputState,
  inputB: InputState,
  languagesOfInterest: LanguagesOfInterestState,
  extraLanguage: ExtraLanguageState,
  coldStart: boolean, // Whether the page was initially loaded with some content
  touched: boolean,
  pendingApi: {
    request: APIRequest,
    location: Location
  } | null,
  api: {
    request: APIRequest,
    location: Location,
    subrequest: string | null,
    response: APIResponse<WordWithMeasurements>
  } | null,
  incompleteLanguage: string | null,
  topCollapsed: boolean
};

export const StateContext = React.createContext<State | null>(null);

export function getInitialState(path: string, query: string): State | null {
  const location = urlToLocation(path, query);
  if (location === null) {
    return null;
  }
  const wordA = extractAFromLocation(location);
  const wordB = extractBFromLocation(location);
  const extraLanguage = getExtraLanguage();
  const pendingRequest = locationToRequest(location);
  return {
    section: location.section,
    inputA: {
      text: wordA?.word ?? '',
      word: wordA,
      lastWord: null,
      shouldGetFocused: wordA === null
    },
    inputB: {
      text: wordB?.word ?? '',
      word: wordB,
      lastWord: null,
      shouldGetFocused: false
    },
    languagesOfInterest: {
      languages: getLanguagesOfInterest(),
      initialized: getLanguagesOfInterestInitialized()
    },
    extraLanguage: {
      language: extraLanguage,
      enabled: extraLanguage !== null && getExtraLanguageEnabled()
    },
    coldStart: pendingRequest !== null,
    touched: false,
    pendingApi: pendingRequest === null ? null : {
      request: pendingRequest,
      location
    },
    api: null,
    incompleteLanguage: null,
    topCollapsed: false
  };
}