import {QUERY_PARAMETER_SEPARATOR} from '../constants/constants';
import {Language} from '../types/Language';

export function encodeStrict(string: string): string {
  return encodeURIComponent(string);
}

export function encode(string: string): string {
  return encodeStrict(string.replace(/ /g, '_'));
}

export function decodeStrict(string: string): string {
  return decodeURIComponent(string);
}

export function decode(string: string): string {
  return decodeStrict(string).replace(/_/g, ' ');
}

function doEncodeLanguages(languages: Language[], encoder: (string: string) => string): string {
  return languages.map(({code}) => encoder(code)).join(QUERY_PARAMETER_SEPARATOR);
}

export function encodeLanguagesStrict(languages: Language[]): string {
  return doEncodeLanguages(languages, encodeStrict);
}

export function encodeLanguages(languages: Language[]): string {
  return doEncodeLanguages(languages, encode);
}