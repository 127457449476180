import seedrandom from 'seedrandom';

export type Randomizer = {
  getDouble: (from: number, to: number) => number,
  getBoolean: () => boolean,
  getKey: () => string
};

export function getDeterministicRandomizer(seed = ''): Randomizer {
  const randomizer = seedrandom(seed);
  return {
    getDouble: (from, to) => from + (from >= to ? 0 : randomizer.double() * (to - from)),
    getBoolean: () => randomizer.int32() % 2 === 0,
    getKey: () => `k${randomizer.double().toString().replace('.', '')}`
  };
}

export function getNonDeterministicRandomizer(): Randomizer {
  return getDeterministicRandomizer(Math.random().toString());
}

export function getRandomKey(): string {
  return getNonDeterministicRandomizer().getKey();
}