import React, {useCallback, useState} from 'react';
import {EventDispatcher} from '../helpers/eventDispatcher';
import {ScrollContainer, ScrollContainerProps} from '../components/ScrollContainer/ScrollContainer';

export function useScrollWithDispatcher(): [typeof ScrollContainer, EventDispatcher] {
  const [moveEventDispatcher] = useState(() => new EventDispatcher());
  const onMove = useCallback(() => moveEventDispatcher.dispatch(), [moveEventDispatcher]);
  const component = useCallback(
    ({children, onScroll, onScale, ...props}: ScrollContainerProps) => {
      return (
        <ScrollContainer
          {...props}
          onScroll={() => {
            onScroll?.();
            onMove();
          }}
          onScale={() => {
            onScale?.();
            onMove();
          }}
        >
          {children}
        </ScrollContainer>
      );
    },
    [onMove]
  );
  return [component, moveEventDispatcher];
}