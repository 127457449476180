import React, {useMemo} from 'react';
import {COGNATES} from '../../constants/constants';
import {APICognateWord, APISense} from '../../types/APIResponse';
import {Word} from '../../types/Word';
import {WordWithMeasurements, WordWithMeasurementsAndPosition} from '../../types/WordGeometry';
import {Box, Dimensions} from '../../types/Geometry';
import {ExtendedLocation} from '../../types/Location';
import {
  addVectors,
  boxesIntersect,
  getBoxCenter,
  getDistance,
  getPointAtAngle,
  multiplyVector,
  subtractVectors
} from '../../helpers/geometryHelper';
import {getDeterministicRandomizer, Randomizer} from '../../helpers/randomHelper';
import {pixel} from '../../helpers/domHelper';
import {useScrollWithDispatcher} from '../../hooks/useScrollWithDispatcher';
import {WordBox} from '../WordBox/WordBox';
import {ReactComponent as CognatesSvg} from '../../images/cognates.svg';
import './Cognates.scss';

type W = APICognateWord<WordWithMeasurements>;
type WP = APICognateWord<WordWithMeasurementsAndPosition>;

type Props = {
  words: W[],
  relatedWord: {
    word: Word,
    sense: APISense
  },
  currentLocation: ExtendedLocation
};

type Layout = {
  words: WP[],
  size: Dimensions
};

function getWordUtility(word: W): number {
  return word.similarity.form + word.similarity.sense;
}

function findBox(word: W, boxes: Box[], randomizer: Randomizer): Box {
  const origin = {
    x: randomizer.getDouble(-COGNATES.maxShift.x, COGNATES.maxShift.x),
    y: randomizer.getDouble(-COGNATES.maxShift.y, COGNATES.maxShift.y)
  };

  const halfSize = addVectors(multiplyVector(word.size, 0.5), COGNATES.wordPadding);
  const getBox = (center: Dimensions) => ({
    min: subtractVectors(center, halfSize),
    max: addVectors(center, halfSize)
  });

  const checkBox = (box: Box) => !boxes.some((otherBox) => boxesIntersect(box, otherBox));

  const findBoxAtDirection = (angle: number): Box => {
    const getBoxAt = (distance: number): Box => getBox(getPointAtAngle(origin, angle, distance));
    const check = (distance: number): boolean => checkBox(getBoxAt(distance));

    let lastDistance: number | null = null;
    let nextDistance = 0;
    let step = 1;
    while (!check(nextDistance)) {
      lastDistance = nextDistance;
      nextDistance += step;
      step *= COGNATES.search.stepMultiplier;
    }
    if (lastDistance !== null) {
      while (nextDistance - lastDistance > COGNATES.search.granularity) {
        const middleDistance: number = (nextDistance + lastDistance) / 2;
        if (check(middleDistance)) {
          nextDistance = middleDistance;
        } else {
          lastDistance = middleDistance;
        }
      }
    }
    return getBoxAt(nextDistance);
  };

  const getBoxDistanceFromCenter = (box: Box) => getDistance(getBoxCenter(box), origin);

  const initialAngle = randomizer.getDouble(0, 2 * Math.PI);
  let bestBox: Box | null = null;
  for (let direction = 0; direction < COGNATES.search.directionCandidates; direction++) {
    const angle = initialAngle + direction * 2 * Math.PI / COGNATES.search.directionCandidates;
    const box = findBoxAtDirection(angle);
    if (bestBox === null || getBoxDistanceFromCenter(box) < getBoxDistanceFromCenter(bestBox)) {
      bestBox = box;
    }
  }
  return bestBox!;
}

function computeBoxes(words: W[]): Box[] {
  const randomizer = getDeterministicRandomizer(words.length > 0 ? words[0].word : '');
  const boxes: Box[] = [];
  for (const word of words) {
    boxes.push(findBox(word, boxes, randomizer));
  }
  return boxes;
}

function computeLayout(words: W[]): Layout {
  const sorted = words.sort((a, b) => getWordUtility(b) - getWordUtility(a)).slice(0, COGNATES.maxWordsDisplayed);
  const boxes = computeBoxes(sorted);
  const maxDimensions: Dimensions = boxes.reduce((max, current) => ({
    x: Math.max(max.x, Math.abs(current.min.x), Math.abs(current.max.x)),
    y: Math.max(max.y, Math.abs(current.min.y), Math.abs(current.max.y))
  }), {
    x: COGNATES.minSize / 2,
    y: COGNATES.minSize / 2
  });
  const centers = boxes.map((box) => getBoxCenter(box));
  return {
    words: sorted.map((word, index) => ({
      ...word,
      position: addVectors(centers[index], maxDimensions)
    })),
    size: multiplyVector(maxDimensions, 2)
  };
}

export function Cognates({words, relatedWord, currentLocation}: Props) {
  const [ScrollContainer, moveEventDispatcher] = useScrollWithDispatcher();
  return useMemo(() => {
    const {words: wordsPositioned, size} = computeLayout(words);
    const sizeToStyles = (size: Dimensions) => ({
      width: pixel(size.x),
      height: pixel(size.y)
    });
    const positionToStyles = (position: Dimensions, size: Dimensions) => ({
      left: pixel(position.x - size.x / 2),
      top: pixel(position.y - size.y / 2)
    });
    return (
      <ScrollContainer initialScrollRatio={{x: 0.5, y: 0.5}}>
        <div className="cognates" style={sizeToStyles(size)}>
          <div className="cognates_backgroundContainer">
            <CognatesSvg className="cognates_background" aria-hidden={true} />
          </div>
          <div className="cognates_words" style={{
            '--cognates-word-horizontal-padding': pixel(COGNATES.wordPadding.x),
            '--cognates-word-vertical-padding': pixel(COGNATES.wordPadding.y)
          } as React.CSSProperties}>
            {wordsPositioned.map((word, index) => (
              <div className="cognates_word" key={index} style={positionToStyles(word.position, word.size)}>
                <WordBox
                  word={word}
                  relatedWord={{
                    word: relatedWord.word,
                    sense: {
                      text: relatedWord.sense.text,
                      id: relatedWord.sense.id
                    }
                  }}
                  currentLocation={currentLocation}
                  moveEventDispatcher={moveEventDispatcher}
                />
              </div>
            ))}
          </div>
        </div>
      </ScrollContainer>
    );
  }, [
    words, relatedWord.word, relatedWord.sense.text, relatedWord.sense.id, currentLocation,
    ScrollContainer, moveEventDispatcher
  ]);
}