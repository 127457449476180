import React from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {SoftAlert} from '../SoftAlert/SoftAlert';
import {ReactComponent as LoadingSvg} from '../../images/loading.svg';
import './Loading.scss';

type Props = {
  slow: boolean
};

export function Loading({slow}: Props) {
  return (
    <SoftAlert className="loading">
      <LoadingSvg className="loading_preloader" role="progressbar" aria-label={LOCALIZATION.alerts.loadingLabel} />
    </SoftAlert>
  );
}