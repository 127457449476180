import React, {useContext, useLayoutEffect, useMemo} from 'react';
import assert from 'assert';
import {LOCALIZATION} from '../../constants/localization';
import {APIDoubleSense, APIResponseData, APIResponseStatus, APISingleSense} from '../../types/APIResponse';
import {APIRequestType} from '../../types/APIRequest';
import {WordWithMeasurements} from '../../types/WordGeometry';
import {ExtendedLocation} from '../../types/Location';
import {StateContext} from '../../context/StateContext';
import {UpdateContext} from '../../context/UpdateContext';
import {locationToUrl} from '../../helpers/locationToUrl';
import {extractAFromResponseData, extractBFromResponseData} from '../../helpers/wordExtractor';
import {getSenseUrlText} from '../../helpers/senseHelper';
import {
  formatDoubleSubrequestLabel,
  formatDoubleSubrequestUrl,
  formatMissingSubrequestLabel,
  formatMissingSubrequestUrl,
  formatSingleSubrequestLabel,
  formatSingleSubrequestUrl
} from '../../helpers/subrequestFormatter';
import {SubrequestOptions} from '../SubrequestOptions/SubrequestOptions';
import {Missing} from '../Missing/Missing';
import {Etymology} from '../Etymology/Etymology';
import {Relation} from '../Relation/Relation';
import {Cognates} from '../Cognates/Cognates';
import './MainContent.scss';

type SubrequestOption = {
  text: string,
  hash: string
};

function getSubrequestOptions(data: APIResponseData): SubrequestOption[] {
  switch (data.type) {
    case APIRequestType.Etymology:
    case APIRequestType.Cognates:
      return (data.response.data as APISingleSense[]).map(
        ({sense}, index) => {
          const urlText = getSenseUrlText(sense);
          return {
            text: sense.text ? formatSingleSubrequestLabel(sense.text) : formatMissingSubrequestLabel(index),
            hash: urlText ? formatSingleSubrequestUrl(urlText) : formatMissingSubrequestUrl(index)
          };
        }
      );
    case APIRequestType.Relation:
      return (data.response.data as APIDoubleSense[]).map(
        ({senses: [a, b]}, index) => {
          const urlTextA = getSenseUrlText(a);
          const urlTextB = getSenseUrlText(b);
          return {
            text: a.text && b.text ? formatDoubleSubrequestLabel(a.text, b.text) : formatMissingSubrequestLabel(index),
            hash: urlTextA && urlTextB
              ? formatDoubleSubrequestUrl(urlTextA, urlTextB)
              : formatMissingSubrequestUrl(index)
          };
        }
      );
    default:
      assert(false);
  }
}

export function MainContent() {
  const state = useContext(StateContext)!;
  const update = useContext(UpdateContext);
  assert(state.api?.response.status === APIResponseStatus.OK);
  const baseUrl = locationToUrl(state.api.location);
  const options = getSubrequestOptions(state.api.response.data).map(({text, hash}) => ({
    text,
    hash,
    href: `${baseUrl}#${hash}`
  }));
  const optionHashes = options.map(({hash}) => hash);
  const selectedIndex = state.api.subrequest === null ? -1 : optionHashes.indexOf(state.api.subrequest);
  const wordA = extractAFromResponseData(state.api.response.data);
  const wordB = extractBFromResponseData(state.api.response.data);
  const api = state.api;
  const currentLocation: ExtendedLocation = useMemo(() => ({
    location: api.location,
    subrequest: api.subrequest
  }), [api.location, api.subrequest]);
  const getDiagram = (data: APIResponseData<WordWithMeasurements>, selectedIndex: number) => {
    switch (data.type) {
      case APIRequestType.Etymology:
        return (
          <Etymology
            word={data.response.word}
            etymology={data.response.data[selectedIndex].tree}
            currentLocation={currentLocation}
          />
        );
      case APIRequestType.Relation:
        return (
          <Relation
            words={data.response.words}
            relation={data.response.data[selectedIndex].tree}
            currentLocation={currentLocation}
          />
        );
      case APIRequestType.Cognates:
        return (
          <Cognates
            words={data.response.data[selectedIndex].cognates}
            relatedWord={{
              word: data.response.word,
              sense: data.response.data[selectedIndex].sense
            }}
            currentLocation={currentLocation}
          />
        );
    }
  };
  useLayoutEffect(() => {
    if (selectedIndex < 0 && optionHashes.length > 0) {
      update.resetHash(optionHashes[0]);
    }
  }, [optionHashes, selectedIndex, update]);
  return (
    <React.Fragment key={baseUrl}>
      {options.length === 0 ? <Missing data={state.api.response.data} /> : (selectedIndex >= 0 &&
        <div className="mainContent">
          <div className="mainContent_options">
            <SubrequestOptions options={options}
                               selectedIndex={selectedIndex}
                               onSwitched={(index) => update.setUrl(options[index].href)}
                               leftWord={wordA === null ? null : {
                                 word: wordA,
                                 onClicked: () => update.setInputAFocused()
                               }}
                               rightWord={wordB === null ? null : {
                                 word: wordB,
                                 onClicked: () => update.setInputBFocused()
                               }}
                               wordsAsButtons={state.api.location.section === state.section}
                               topCollapsed={state.topCollapsed}
                               onTopSwitchClicked={() => update.setTopCollapsed(!state.topCollapsed)}
            />
          </div>
          <section className="mainContent_diagram" aria-label={LOCALIZATION.alerts.diagramLabel}>
            <React.Fragment key={state.api.subrequest}>
              {getDiagram(state.api.response.data, selectedIndex)}
            </React.Fragment>
          </section>
        </div>
    )}
    </React.Fragment>
  );
}