import React from 'react';
import {SoftAlert} from '../SoftAlert/SoftAlert';
import {ReactComponent as WarningSvg} from '../../images/warning.svg';
import './Error.scss';

type Props = {
  children: React.ReactNode
};

export function Error({children}: Props) {
  return (
    <SoftAlert className="error">
      <WarningSvg className="error_icon" aria-hidden={true} />
      {children}
    </SoftAlert>
  );
}