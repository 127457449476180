import {DEFAULT_SECTION, QUERY_PARAMETER_SEPARATOR} from '../constants/constants';
import {Section} from '../types/Section';
import {Location} from '../types/Location';
import {Word} from '../types/Word';
import {Language} from '../types/Language';
import {decode} from './urlEncoder';

type ProcessedPath = {
  section: string
  parameters: string[]
};

type ProcessedQuery = {
  [key: string]: string[]
};

function processPath(path: string): ProcessedPath {
  const parts = path.replace(/^\//g, '').split('/');
  return {
    section: parts[0] ?? '',
    parameters: parts.slice(1).map((part) => decode(part))
  };
}

function processQuery(query: string): ProcessedQuery {
  return Object.fromEntries(query.replace(/^\?/, '').split('&').map((part) => {
    const subParts = part.split('=', 2);
    const key = decode(subParts[0]).trim();
    const values = decode(subParts[1] ?? '')
      .split(QUERY_PARAMETER_SEPARATOR).map((value) => value.trim()).filter((value) => value !== '');
    return [key, values];
  }).filter(([key]) => key !== ''));
}

function language(code: string): Language {
  return {
    code
  };
}

function word(code: string, word: string): Word {
  return {
    language: language(code),
    word
  };
}

export function urlToLocation(path: string, query: string): Location | null {
  const {section, parameters} = processPath(path);
  if (parameters.includes('')) {
    return null;
  }
  const queryParameters = processQuery(query);
  switch (section) {
    case Section.Etymology:
      if (parameters.length === 0) {
        return {
          section: Section.Etymology,
          query: null
        };
      } else if (parameters.length === 2) {
        return {
          section: Section.Etymology,
          query: {
            word: word(parameters[0], parameters[1])
          }
        };
      } else {
        return null;
      }
    case Section.Relation:
      if (parameters.length === 0) {
        return {
          section: Section.Relation,
          query: null
        };
      } else if (parameters.length === 4) {
        return {
          section: Section.Relation,
          query: {
            wordA: word(parameters[0], parameters[1]),
            wordB: word(parameters[2], parameters[3])
          }
        };
      } else {
        return null;
      }
    case Section.Cognates:
      if (parameters.length === 0) {
        return {
          section: Section.Cognates,
          query: null
        };
      } else if (parameters.length === 2
        && queryParameters.targetLanguages
        && queryParameters.targetLanguages.length > 0) {
        return {
          section: Section.Cognates,
          query: {
            word: word(parameters[0], parameters[1]),
            targetLanguages: queryParameters.targetLanguages.map((code) => language(code))
          }
        };
      } else {
        return null;
      }
    case Section.About:
      return parameters.length === 0 ? {
        section: Section.About
      } : null;
    case '':
      return parameters.length === 0 ? {
        section: DEFAULT_SECTION,
        query: null
      } : null;
    default:
      return null;
  }
}