import React from 'react';
import './DashedText.scss';

type Props = {
  children: React.ReactNode,
  outline: boolean,
  truncate: boolean,
  className?: string
};

export function DashedText({children, outline, truncate, className}: Props) {
  return (
    <span className={
      `dashedText ${outline ? 'dashedText-outline' : ''} ${truncate ? 'dashedText-truncate' : ''} ${className ?? ''}`
    }>
      <span className="dashedText_text">
        {children}
      </span>
    </span>
  );
}