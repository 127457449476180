import React, {useContext, useEffect, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {DROPDOWN} from '../../constants/constants';
import {Section, StaticSubsection} from '../../types/Section';
import {APILanguages, APIResponse, APIResponseStatus} from '../../types/APIResponse';
import {APIRequest, APIRequestType} from '../../types/APIRequest';
import {Language} from '../../types/Language';
import {StateContext} from '../../context/StateContext';
import {UpdateContext} from '../../context/UpdateContext';
import {embedNodes, joinNodes} from '../../helpers/embedders/nodeEmbedder';
import {Font, FontCoverage, getFontCoverage} from '../../helpers/fontChecker';
import {getWordWithPrefix} from '../../helpers/wordHelper';
import {getLanguageName} from '../../helpers/languageHelper';
import {Select, SelectBorderSize, SelectContent, SelectTextSize, SelectTextStyle} from '../Select/Select';
import {Link} from '../Link/Link';
import './SelectLanguage.scss';

type Props = {
  placeholder: string,
  label: string,
  expandParent: boolean,
  autoFocus: boolean,
  autoFocusKey?: string,
  preventScrollOnFocus: boolean,
  disabled?: boolean,
  exclude: Language[],
  onSelected: (language: Language) => void,
  onFilledStateUpdated?: (filled: boolean) => void,
  onLoadingStateChanged?: (loading: boolean) => void,
  onActiveStateChanged?: (active: boolean) => void
};

function queryToRequest(exclude: Language[], query: string): APIRequest | null {
  const fragment = query.trim();
  return fragment === '' ? null : {
    type: APIRequestType.Languages,
    fragment,
    excludedLanguages: exclude
  };
}

function formatWordsForLanguage(language: Language): React.ReactNode {
  if (!language.wordsForLanguage ||
      language.wordsForLanguage.some(({word}) => getFontCoverage(word, Font.Cursive) === FontCoverage.Partial)) {
      // We don't have enough control over the browser to make such words look aesthetically pleasing, so we hide them
    return null;
  } else {
    return joinNodes(
      language.wordsForLanguage.map((word) => getWordWithPrefix(word)),
      LOCALIZATION.languageInputs.wordsForLanguageConnector
    );
  }
}

function responseToContent(response: APIResponse): SelectContent {
  switch (response.status) {
    case APIResponseStatus.Loading:
      return <>{LOCALIZATION.languageInputs.loading}</>;
    case APIResponseStatus.Error:
      return <>{LOCALIZATION.languageInputs.error}</>;
    case APIResponseStatus.Quota:
      return (
        <>
          {embedNodes(LOCALIZATION.languageInputs.quota, (text) => (
            <Link to={{section: Section.About}} subrequest={StaticSubsection.Quota}>{text}</Link>
          ))}
        </>
      );
    case APIResponseStatus.OK:
      const languages = response.data.response as APILanguages;
      return languages.length === 0 ? <>{LOCALIZATION.languageInputs.notFound}</> : languages.map((language) => ({
        labels: [
          getLanguageName(language, true),
          formatWordsForLanguage(language) ?? '' // An empty label is still needed to support proper line height
        ],
        key: language.code,
        data: language
      }));
  }
}

export function SelectLanguage({
  placeholder, label, expandParent, autoFocus, autoFocusKey, preventScrollOnFocus, disabled, exclude,
  onSelected, onFilledStateUpdated, onLoadingStateChanged, onActiveStateChanged
}: Props) {
  const effectiveAutoFocusKey = autoFocusKey ?? '';
  const [text, setText] = useState('');
  const [shouldGetFocused, setShouldGetFocused] = useState((autoFocus ?? false) && !(disabled ?? false));
  const [lastAutoFocusKey, setLastAutoFocusKey] = useState(effectiveAutoFocusKey);
  const state = useContext(StateContext)!;
  const update = useContext(UpdateContext);
  useEffect(() => {
    if (autoFocus && effectiveAutoFocusKey !== lastAutoFocusKey) {
      setLastAutoFocusKey(effectiveAutoFocusKey);
      setShouldGetFocused(true);
    }
  }, [autoFocus, effectiveAutoFocusKey, lastAutoFocusKey]);
  const updateText = (text: string) => {
    const fragment = text.trim();
    update.setIncompleteLanguage(fragment || null);
    setText(text);
    if (onFilledStateUpdated) {
      onFilledStateUpdated(fragment !== '');
    }
  };
  return (
    <div className="selectLanguage">
      <Select
        appearance={{
          placeholder,
          label,
          focusedBorderSize: SelectBorderSize.Small,
          mainTextSize: SelectTextSize.Normal,
          optionComposition: [{
            size: SelectTextSize.Normal,
            style: SelectTextStyle.Normal
          }, {
            size: SelectTextSize.Normal,
            style: SelectTextStyle.Illustration
          }],
          maxOptionCount: DROPDOWN.languagesDisplayed,
          hideRestoreText: false,
          expandParent
        }}
        state={{
          text,
          restore: state.incompleteLanguage === null ? null : {
            formatted: <span lang="" translate="no">{state.incompleteLanguage}</span>,
            text: state.incompleteLanguage,
            data: state.incompleteLanguage
          },
          valid: false,
          button: null,
          shouldGetFocused,
          preventScrollOnFocus,
          disabled
        }}
        handlers={{
          onTextSet: updateText,
          onRestoreClicked: (text: string) => {
            updateText(text);
            setShouldGetFocused(true);
          },
          onOptionSelected: (language: Language) => {
            updateText('');
            onSelected(language);
          },
          onMarkFocused: () => setShouldGetFocused(false),
          onLoadingStateChanged,
          onActiveStateChanged
        }}
        converters={{
          queryToRequest: queryToRequest.bind(null, exclude),
          responseToContent
        }}
      />
    </div>
  );
}