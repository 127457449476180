import {useEffect, useRef} from 'react';

export function useNewItems<T>(list: T[], initialList: T[] = list): T[] {
  const last = useRef(initialList);
  const newItems = list.filter((item) => !last.current.includes(item));
  useEffect(() => {
    // We do this here instead of directly assigning the new value in order for renders not to have side effects
    last.current = list;
  });
  return newItems;
}