import {BezierCurve, Box, Dimensions, RoundedBox, Segment, unsetDimensions} from '../types/Geometry';

const defaultError = 1e-5;

export function less(a: number, b: number, error: number = defaultError): boolean {
  return a + error < b;
}

export function greater(a: number, b: number, error: number = defaultError): boolean {
  return a > b + error;
}

export function equal(a: number, b: number, error: number = defaultError): boolean {
  return Math.abs(a - b) <= error;
}

export function equalModulo(a: number, b: number, m: number, error: number = defaultError): boolean {
  return equal(modulo(a, m), modulo(b, m), error);
}

export function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}

export function dimensionsEqual(a: Dimensions, b: Dimensions): boolean {
  return a.x === b.x && a.y === b.y;
}

export function getDistance(a: Dimensions, b: Dimensions): number {
  return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
}

export function getMiddle(a: Dimensions, b: Dimensions): Dimensions {
  return averageVectors(a, b);
}

export function getWeight(position: number, from: number, weightFrom: number, to: number, weightTo: number): number {
  return ((position - from) * weightTo + (to - position) * weightFrom) / (to - from);
}

export function addVectors(a: Dimensions, b: Dimensions): Dimensions {
  return {
    x: a.x + b.x,
    y: a.y + b.y
  };
}

export function sumVectors(...vectors: Dimensions[]): Dimensions {
  return vectors.reduce(addVectors, {
    x: 0,
    y: 0
  });
}

export function averageVectors(...vectors: Dimensions[]): Dimensions {
  return divideVector(sumVectors(...vectors), vectors.length);
}

export function averageWeightedVectors(a: Dimensions, aWeight: number, b: Dimensions, bWeight: number): Dimensions {
  const total = aWeight + bWeight;
  return {
    x: (a.x * aWeight + b.x * bWeight) / total,
    y: (a.y * aWeight + b.y * bWeight) / total
  };
}

export function subtractVectors(a: Dimensions, b: Dimensions): Dimensions {
  return {
    x: a.x - b.x,
    y: a.y - b.y
  };
}

export function multiplyVector(a: Dimensions, c: number): Dimensions {
  return {
    x: a.x * c,
    y: a.y * c
  };
}

export function divideVector(a: Dimensions, c: number): Dimensions {
  return multiplyVector(a, 1 / c);
}

export function segmentsIntersect(a: Segment, b: Segment): boolean {
  return !(Math.max(a.a, a.b) < Math.min(b.a, b.b) || Math.max(b.a, b.b) < Math.min(a.a, a.b));
}

export function insideSegment(point: number, segment: Segment): boolean {
  return point > Math.min(segment.a, segment.b) && point < Math.max(segment.a, segment.b);
}

export function combineXY(xFrom: Dimensions, yFrom: Dimensions): Dimensions {
  return {
    x: xFrom.x,
    y: yFrom.y
  };
}

export function shiftBox(box: Box, shift: Dimensions): Box {
  return {
    min: addVectors(box.min, shift),
    max: addVectors(box.max, shift)
  };
}

export function boxesIntersect(a: Box, b: Box): boolean {
  return segmentsIntersect({
    a: a.min.x,
    b: a.max.x
  }, {
    a: b.min.x,
    b: b.max.x
  }) && segmentsIntersect({
    a: a.min.y,
    b: a.max.y
  }, {
    a: b.min.y,
    b: b.max.y
  });
}

export function insideBox(point: Dimensions, box: Box): boolean {
  return insideSegment(point.x, {
    a: box.min.x,
    b: box.max.x
  }) && insideSegment(point.y, {
    a: box.min.y,
    b: box.max.y
  });
}

export function insideRoundedBox(point: Dimensions, {box, borderRadius}: RoundedBox): boolean {
  if (!insideBox(point, box)) {
    return false;
  }
  const xs: [number, number] = [box.min.x, box.max.x];
  const ys: [number, number] = [box.min.y, box.max.y];
  const withinRadius = (x: number, y: number) => getDistance({x, y}, point) <= borderRadius;
  const getCoordinate = (endPoints: [number, number], index: number) =>
    getNumberAtDirection(endPoints[index], endPoints[1 - index], borderRadius);
  for (let xi = 0; xi <= 1; xi++) {
    for (let yi = 0; yi <= 1; yi++) {
      if (withinRadius(xs[xi], ys[yi]) && !withinRadius(getCoordinate(xs, xi), getCoordinate(ys, yi))) {
        return false;
      }
    }
  }
  return true;
}

export function boxFromPoint(point: Dimensions): Box {
  return {
    min: {
      ...point
    },
    max: {
      ...point
    }
  };
}

export function getBoundingBox(points: Dimensions[]): Box {
  return points.length === 0 ? boxFromPoint(unsetDimensions) : points.reduce((box, point) => ({
    min: {
      x: Math.min(box.min.x, point.x),
      y: Math.min(box.min.y, point.y)
    },
    max: {
      x: Math.max(box.max.x, point.x),
      y: Math.max(box.max.y, point.y)
    }
  }), boxFromPoint(points[0]));
}

export function getBoundingBoxOfBoxes(boxes: Box[]): Box {
  return getBoundingBox(boxes.flatMap((box) => [box.min, box.max]));
}

export function getBoxCenter(box: Box): Dimensions {
  return getMiddle(box.min, box.max);
}

export function getBoxSize(box: Box): Dimensions {
  return {
    x: box.max.x - box.min.x,
    y: box.max.y - box.min.y
  };
}

export function expandBox(box: Box, margin: number): Box {
  return {
    min: {
      x: box.min.x - margin,
      y: box.min.y - margin
    },
    max: {
      x: box.max.x + margin,
      y: box.max.y + margin
    }
  };
}

export function getNumberAtDirection(anchor: number, destination: number, distance: number): number {
  return anchor + distance * Math.sign(destination - anchor);
}

export function getPointAtAngle(anchor: Dimensions, angle: number, distance: number): Dimensions {
  return {
    x: anchor.x + distance * Math.cos(angle),
    y: anchor.y + distance * Math.sin(angle)
  };
}

export function getAngle(vector: Dimensions): number {
  return Math.atan2(vector.y, vector.x);
}

export function getAngleTo(from: Dimensions, to: Dimensions): number {
  return getAngle(subtractVectors(to, from));
}

export function reverseAngle(angle: number): number {
  return angle + Math.PI;
}

export function modulo(a: number, b: number): number {
  return ((a % b) + b) % b;
}

export function getLineCoefficients(origin: Dimensions, angle: number): [number, number, number] {
  const a = -Math.sin(angle);
  const b = Math.cos(angle);
  const c = -(a * origin.x + b * origin.y);
  return [a, b, c];
}

export function intersect(aOrigin: Dimensions, aAngle: number, bOrigin: Dimensions, bAngle: number): Dimensions | null {
  if (equalModulo(aAngle, bAngle, Math.PI)) {
    return null;
  }
  const [a1, b1, c1] = getLineCoefficients(aOrigin, aAngle);
  const [a2, b2, c2] = getLineCoefficients(bOrigin, bAngle);
  return {
    x: (c1 * b2 - b1 * c2) / (b1 * a2 - a1 * b2),
    y: (a1 * c2 - c1 * a2) / (b1 * a2 - a1 * b2)
  };
}

export function reverseBezierCurve(curve: BezierCurve): BezierCurve {
  return {
    a: curve.b,
    ca: curve.cb,
    cb: curve.ca,
    b: curve.a
  };
}

export function getBezierCurvePoint(curve: BezierCurve, t: number): Dimensions {
  const u = 1 - t;
  return sumVectors(
    multiplyVector(curve.a, u ** 3),
    multiplyVector(curve.ca, 3 * u ** 2 * t),
    multiplyVector(curve.cb, 3 * u * t ** 2),
    multiplyVector(curve.b, t ** 3)
  );
}

export function getBezierCurveRelativeControl(curve: BezierCurve, t0: number, t1: number): Dimensions {
  const u0 = 1 - t0;
  const u1 = 1 - t1;
  return sumVectors(
    multiplyVector(curve.a, u0 ** 2 * u1),
    multiplyVector(curve.ca, 2 * t0 * u0 * u1 + t1 * u0 ** 2),
    multiplyVector(curve.cb, 2 * t0 * t1 * u0 + t0 ** 2 * u1),
    multiplyVector(curve.b, t0 ** 2 * t1)
  );
}

export function trimBezierCurve(curve: BezierCurve, t0: number, t1: number): BezierCurve {
  return {
    a: getBezierCurvePoint(curve, t0),
    ca: getBezierCurveRelativeControl(curve, t0, t1),
    cb: getBezierCurveRelativeControl(curve, t1, t0),
    b: getBezierCurvePoint(curve, t1)
  };
}

export function smoothBezierTransition(from: BezierCurve, fromRatio: number,
                                       to: BezierCurve, toRatio: number): BezierCurve[] {
  const fromTrimmed = trimBezierCurve(from, 0, fromRatio);
  const fromPoint = fromTrimmed.b;
  const fromAngle = fromRatio > 0 ? getAngleTo(fromTrimmed.cb, fromTrimmed.b) : getAngleTo(from.a, from.ca);
  const toTrimmed = trimBezierCurve(to, toRatio, 1);
  const toPoint = toTrimmed.a;
  const toAngle = toRatio < 1 ? getAngleTo(toTrimmed.ca, toTrimmed.a) : getAngleTo(to.b, to.cb);
  const intersection = intersect(fromPoint, fromAngle, toPoint, toAngle);
  const controlDistance = intersection === null
    ? getDistance(fromPoint, toPoint) / 2
    : Math.min(getDistance(fromPoint, intersection), getDistance(toPoint, intersection));
  return [
    ...(fromRatio > 0 ? [fromTrimmed] : []),
    {
      a: fromPoint,
      ca: getPointAtAngle(fromPoint, fromAngle, controlDistance),
      cb: getPointAtAngle(toPoint, toAngle, controlDistance),
      b: toPoint
    },
    ...(toRatio < 1 ? [toTrimmed] : [])
  ];
}