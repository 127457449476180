import React from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {DROPDOWN} from '../../constants/constants';
import {APIResponse, APIResponseStatus, APIWords} from '../../types/APIResponse';
import {APIRequest, APIRequestType} from '../../types/APIRequest';
import {Word} from '../../types/Word';
import {Language} from '../../types/Language';
import {Section, StaticSubsection} from '../../types/Section';
import {InputState} from '../../context/StateContext';
import {embedNodes,} from '../../helpers/embedders/nodeEmbedder';
import {embedText} from '../../helpers/embedders/textEmbedder';
import {getWordWithPrefix, serializeWord} from '../../helpers/wordHelper';
import {getLanguageName} from '../../helpers/languageHelper';
import {Select, SelectBorderSize, SelectContent, SelectTextSize, SelectTextStyle} from '../Select/Select';
import {Link} from '../Link/Link';
import './SelectWord.scss';

type Props = {
  state: InputState,
  placeholder: string,
  label: string,
  languagesOfInterest: Language[],
  onSelected: (word: Word) => void,
  onTextSet: (text: string) => void,
  onMarkFocused: () => void,
  catchQuickEditKey?: boolean
};

function queryToRequest(languagesOfInterest: Language[], query: string): APIRequest | null {
  const fragment = query.trim();
  return fragment === '' ? null : {
    type: APIRequestType.Words,
    fragment,
    targetLanguages: languagesOfInterest
  };
}

function responseToContent(response: APIResponse): SelectContent {
  switch (response.status) {
    case APIResponseStatus.Loading:
      return <>{LOCALIZATION.wordInputs.loading}</>;
    case APIResponseStatus.Error:
      return <>{LOCALIZATION.wordInputs.error}</>;
    case APIResponseStatus.Quota:
      return (
        <>
          {embedNodes(LOCALIZATION.wordInputs.quota, (text) => (
            <Link to={{section: Section.About}} subrequest={StaticSubsection.Quota}>{text}</Link>
          ))}
        </>
      );
    case APIResponseStatus.OK:
      const words = response.data.response as APIWords;
      return words.length === 0 ? <>{LOCALIZATION.wordInputs.notFound}</> : words.map((word) => ({
        labels: [getWordWithPrefix(word), getLanguageName(word.language, true)],
        key: serializeWord(word),
        data: word
      }));
  }
}

export function SelectWord({
  state, placeholder, label, languagesOfInterest, onSelected, onTextSet, onMarkFocused, catchQuickEditKey
}: Props) {
  return (
    <div className="selectWord">
      <Select
        appearance={{
          placeholder,
          label,
          focusedBorderSize: SelectBorderSize.Large,
          mainTextSize: SelectTextSize.Larger,
          optionComposition: [{
            size: SelectTextSize.Normal,
            style: SelectTextStyle.Normal
          }, {
            size: SelectTextSize.Smaller,
            style: SelectTextStyle.Caption
          }],
          maxOptionCount: DROPDOWN.wordsDisplayed,
          hideRestoreText: true,
          expandParent: false
        }}
        state={{
          text: state.text,
          restore: state.lastWord && {
            formatted: getWordWithPrefix(state.lastWord),
            text: state.lastWord.word, // No reconstructed prefix would probably be clearer
            data: state.lastWord
          },
          valid: state.word !== null,
          button: state.word !== null ? {
            full: getLanguageName(state.word.language, false), // We want this to be unified with 'short', hence <false>
            short: state.word.language.code,
            title: LOCALIZATION.wordInputs.buttons.language.title,
            label: embedText(
              LOCALIZATION.wordInputs.buttons.language.label,
              getLanguageName(state.word.language, true)
            )
          } : {
            full: LOCALIZATION.wordInputs.invalid,
            short: LOCALIZATION.wordInputs.invalid,
            title: LOCALIZATION.wordInputs.buttons.invalid.title,
            label: LOCALIZATION.wordInputs.buttons.invalid.label
          },
          shouldGetFocused: state.shouldGetFocused,
          preventScrollOnFocus: false
        }}
        handlers={{
          onTextSet,
          onRestoreClicked: onSelected,
          onOptionSelected: onSelected,
          onMarkFocused
        }}
        converters={{
          queryToRequest: queryToRequest.bind(null, languagesOfInterest),
          responseToContent
        }}
        quickEditLabels={catchQuickEditKey ? LOCALIZATION.wordInputs.quickEditHint : undefined}
      />
    </div>
  );
}