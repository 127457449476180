import React, {useContext} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {APIResponseStatus} from '../../types/APIResponse';
import {Section, StaticSubsection} from '../../types/Section';
import {StateContext} from '../../context/StateContext';
import {locationToUrl} from '../../helpers/locationToUrl';
import {embedNodes} from '../../helpers/embedders/nodeEmbedder';
import {About} from '../About/About';
import {Blank} from '../Blank/Blank';
import {Loading} from '../Loading/Loading';
import {Error} from '../Error/Error';
import {MainContent} from '../MainContent/MainContent';
import {Link} from '../Link/Link';

export function Content() {
  const state = useContext(StateContext)!;
  if (state.section === Section.About) {
    return <About />;
  }
  if (state.api === null) {
    return state.pendingApi === null ? <Blank /> : <Loading slow={false} />;
  }
  switch (state.api.response.status) {
    case APIResponseStatus.Loading: {
      return <Loading slow={state.api.response.slow} />;
    }
    case APIResponseStatus.Error: {
      const location = state.api.location;
      return (
        <Error>
          {embedNodes(LOCALIZATION.alerts.serverError, (text) => (
            <a href={locationToUrl(location)} className="a-noVisited">{text}</a>
          ))}
        </Error>
      );
    }
    case APIResponseStatus.Quota: {
      return (
        <Error>
          {embedNodes(LOCALIZATION.alerts.quota, (text) => (
            <Link to={{section: Section.About}} subrequest={StaticSubsection.Quota}>{text}</Link>
          ))}
        </Error>
      );
    }
    case APIResponseStatus.OK: {
      return <MainContent />;
    }
  }
}