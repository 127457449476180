import React, {useContext} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {Section} from '../../types/Section';
import {StateContext} from '../../context/StateContext';
import {useActiveApiLocation} from '../../hooks/useActiveApiLocation';
import {Link} from '../Link/Link';
import './Menu.scss';

type UpdateUrlIfActiveRequest = boolean;

const sections: [Section, string, UpdateUrlIfActiveRequest][] = [
  [Section.Etymology, 'etymology', false],
  [Section.Relation, 'relation', false],
  [Section.Cognates, 'cognates', false],
  [Section.About, 'about', true]
];

export function Menu() {
  const state = useContext(StateContext)!;
  const apiLocation = useActiveApiLocation(state);
  return (
    <nav className="menu" aria-label={LOCALIZATION.menu.label}>
      <ul className="menu_container">
      {
        sections.map(([section, sectionSubclass, updateUrlIfActiveRequest]) => {
          const label = LOCALIZATION.menu[section];
          const wrap = (text: string) => <span className="menu_text">{text}</span>;
          const [mainText, mainElement, mobileElement] = Array.isArray(label)
            ? [label[0], wrap(label[0]), wrap(label[1])]
            : [label, wrap(label), null];

          const getMainClass = (base: string) => `menu_${base}${mobileElement === null ? '' : ` menu_${base}-desktop`}`;
          const getMobileClass = (base: string) => `menu_${base} menu_${base}-mobile`;
          const getClass = (base: string, main: boolean) => (main ? getMainClass : getMobileClass)(base);
          const getExtraAttributes = (main: boolean) => {
            const value = main ? undefined : mainText;
            return {
              title: value,
              'aria-label': value
            };
          };
          const getContent = (main: boolean) => main ? mainElement : mobileElement;

          const getHeading = (main: boolean) => (
            <h1
              className={getClass('heading', main)}
              {...getExtraAttributes(main)}
              aria-current={true}
            >
              {getContent(main)}
            </h1>
          );

          const getLink = (main: boolean) => (
            <Link
              className={`${getClass('link', main)} a-colored a-noOutline`}
              {...getExtraAttributes(main)}
              to={apiLocation?.section === section ? apiLocation : {section, query: null}}
              focusInput={apiLocation?.section !== section}
              shouldUpdateUrl={() => updateUrlIfActiveRequest || apiLocation === null}
            >
              {getContent(main)}
            </Link>
          );

          const getCombinedContent = (getter: (main: boolean) => React.ReactNode) => (
            <>
              {getter(true)}
              {mobileElement !== null && getter(false)}
            </>
          );

          const baseItemClass = 'menu_item';
          const active = state.section === section;
          const activeClass = active ? `${baseItemClass}-active` : '';
          const sectionClass = `${baseItemClass}-${sectionSubclass}`;

          return (
            <li
              className={`${baseItemClass} ${sectionClass} ${activeClass}`}
              key={section}
              onTransitionEnd={(e) => {
                if (e.propertyName === 'text-decoration-color') {
                  // The following code is a workaround for a bug in Safari (iOS and macOS) where the
                  // text-decoration-color property is not properly transitioned
                  const element = e.target as HTMLElement;
                  setTimeout(() => {
                    element.style.display = 'none';
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const _ = element.offsetHeight;
                    element.style.display = '';
                  }, 0);
                }
              }}
            >
              {active ? getCombinedContent(getHeading) : getCombinedContent(getLink)}
            </li>
          );
        })
      }
      </ul>
    </nav>
  );
}