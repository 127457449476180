import React, {useMemo} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {ARROW, TREE} from '../../constants/constants';
import {APISources} from '../../types/APIResponse';
import {ExtendedLocation} from '../../types/Location';
import {Box} from '../../types/Geometry';
import {
  addVectors,
  expandBox,
  getBoundingBoxOfBoxes,
  getBoxSize,
  multiplyVector,
  shiftBox
} from '../../helpers/geometryHelper';
import {getPositioned, Graphic, Positionable, WP} from '../../helpers/treeHelper/treeHelper';
import {pixel} from '../../helpers/domHelper';
import {embedNodes, joinNodes} from '../../helpers/embedders/nodeEmbedder';
import {useScrollWithDispatcher} from '../../hooks/useScrollWithDispatcher';
import {WordBox} from '../WordBox/WordBox';
import {ArrowSide, MultiArrow, PopupPositionPreference} from '../MultiArrow/MultiArrow';
import {SimpleWordContainer} from '../SimpleWordContainer/SimpleWordContainer';
import {Sources} from '../Sources/Sources';
import './Diagram.scss';

export type Item = WP | Graphic;
type ArrowItem = Item & {
  requiresBracePointer?: boolean
};

export type Arrow = {
  popupPositionPreference?: PopupPositionPreference,
  input: ArrowItem[],
  inputAsSecondary?: boolean,
  outputs: {
    output: ArrowItem[],
    symmetricOutput?: ArrowItem[],
    symmetricInput?: ArrowItem[]
  }[],
  outputsAsSecondary?: boolean,
  straightEnd?: ArrowSide,
  certain: boolean,
  onomatopoeic?: boolean,
  sources: APISources[],
  inverseLabel?: boolean,
  commonOrigin?: boolean,
  autoShowPopup?: boolean
};

export type ItemOrArrow = {
  item: Item,
  isArrow: false
} | {
  arrow: Arrow,
  isArrow: true
};

type Props = {
  itemsAndArrows: ItemOrArrow[],
  currentLocation: ExtendedLocation
};

export function fromItem(item: Item): ItemOrArrow {
  return {
    item,
    isArrow: false
  };
}

export function fromArrow(arrow: Arrow): ItemOrArrow {
  return {
    arrow,
    isArrow: true
  };
}

function isGraphic(item: WP | Graphic): item is Graphic {
  return item.hasOwnProperty('content');
}

function getFrame(items: Item[]): Box {
  const allBoxes: Box[] = items.map((item) => getPositioned(item));
  const bounds = getBoundingBoxOfBoxes(allBoxes.map(
    (box) => expandBox(box, TREE.itemArrowMargin + ARROW.backgroundHalfWidth)
  ));
  const maxAbsX = Math.max(Math.abs(bounds.min.x), Math.abs(bounds.max.x));
  return {
    min: {
      x: -maxAbsX,
      y: bounds.min.y
    },
    max: {
      x: maxAbsX,
      y: bounds.max.y
    }
  };
}

function removeEmptySources(sources: APISources[]): APISources[] {
  return sources.filter((sourceSet) => sourceSet.length > 0);
}

export function Diagram({itemsAndArrows, currentLocation}: Props) {
  const [ScrollContainer, moveEventDispatcher] = useScrollWithDispatcher();
  return useMemo(() => {
    const frame = getFrame(itemsAndArrows.flatMap((itemOrArrow) => itemOrArrow.isArrow ? [] : [itemOrArrow.item]));
    const size = getBoxSize(frame);
    const shift = multiplyVector(frame.min, -1);
    const sizeStyles = {
      width: pixel(size.x),
      height: pixel(size.y)
    };
    const getItemStyles = (item: Positionable) => {
      const position = addVectors(getPositioned(item).min, shift);
      return {
        left: pixel(position.x),
        top: pixel(position.y)
      };
    };
    const prepareBox = (box: Box) => ({
      box: shiftBox(expandBox(box, TREE.itemArrowMargin), shift),
      borderRadius: TREE.itemArrowMargin
    });
    const prepareCuttingBoxes = (item: ArrowItem) => {
      const positioned = getPositioned(item);
      return isGraphic(item)
        ? [prepareBox(positioned)]
        : item.labelBoxes.map((box) => prepareBox(shiftBox(box, positioned.min)));
    };
    const prepareInput = (input: ArrowItem[]) => input.map((item) => ({
      point: addVectors(item.position, shift),
      cuttingBoxes: prepareCuttingBoxes(item),
      requiresBracePointer: item.requiresBracePointer,
      includeBox: isGraphic(item) ? {
        box: shiftBox(getPositioned(item), shift),
        boxMargin: TREE.itemArrowMargin,
        popupAnchor: true
      } : undefined
    }));
    const prepareOutput = (output: ArrowItem[]) => output.map((item) => ({
      point: addVectors(item.position, shift),
      cuttingBoxes: prepareCuttingBoxes(item)
    }));
    const getItem = (item: Item) => (
      <div className={`diagram_item diagram_item-${isGraphic(item) ? 'graphic' : 'word'}`}
           style={getItemStyles(item)}
      >
        {isGraphic(item) ? item.content : (
          <WordBox word={item} currentLocation={currentLocation} moveEventDispatcher={moveEventDispatcher} />
        )}
      </div>
    );
    const getSources = (sources: APISources[]) => sources.length === 0 ? LOCALIZATION.sources.missing : (
      <Sources sources={sources} />
    );
    const getArrow = ({popupPositionPreference, input, inputAsSecondary, outputs, outputsAsSecondary,
                       straightEnd, certain, onomatopoeic, sources,
                       inverseLabel, commonOrigin, autoShowPopup}: Arrow) => (
      <MultiArrow
        moveEventDispatcher={moveEventDispatcher}
        popupPositionPreference={popupPositionPreference}
        input={prepareInput(input)}
        inputAsSecondary={inputAsSecondary}
        outputs={outputs.map(({output, symmetricOutput, symmetricInput}) => ({
          output: prepareOutput(output),
          symmetricOutput: symmetricOutput && prepareOutput(symmetricOutput),
          symmetricInput: symmetricInput && prepareInput(symmetricInput)
        }))}
        outputsAsSecondary={outputsAsSecondary}
        straightEnd={straightEnd}
        dashed={!certain}
        autoShowPopup={autoShowPopup}
        label={embedNodes(LOCALIZATION.connections.label.template, (text, name) => {
          const embed = (template: string, items: ArrowItem[]) => {
            const words = items.flatMap((item) => isGraphic(item) ? [] : [item]);
            return words.length === 0 ? null : embedNodes(template, joinNodes(words.map((word, index) => (
              <>
                {word.language.name && (index === 0 || word.language.name !== words[index - 1].language.name)
                  ? word.language.name + ' '
                  : null}
                <SimpleWordContainer word={word} />
              </>
            )), LOCALIZATION.connections.label.wordConnector));
          };
          switch (name) {
            case 'uncertain':
              return certain ? '' : text;
            case 'onomatopoeic':
              return onomatopoeic ? text : '';
            case 'ends':
              const to = embed(LOCALIZATION.connections.label.to, outputs.flatMap(({output}) => output));
              const from = embed(LOCALIZATION.connections.label.from, input)
                ?? (commonOrigin && !onomatopoeic ? LOCALIZATION.connections.label.fromUnknownCommonOrigin : null);
              return inverseLabel ? <>{from}{to}</> : <>{to}{from}</>;
            default:
              return text;
          }
        })}
        popupLabel={LOCALIZATION.connections.popupLabel}
      >
        {onomatopoeic && <span className="diagram_onomatopoeic">{
          embedNodes(LOCALIZATION.connections.onomatopoeic[
            outputs.flatMap(({output}) => output).length > 1 ? 'plural' : 'singular'
          ], (text) => <a href={LOCALIZATION.connections.onomatopoeic.link}>{text}</a>)
        }</span>}
        {/* The onomatopoeic comment is not aria-hidden because it contains a link; the uncertainty comment is
            aria-hidden because the fact that the connection is uncertain is included in the arrow's label */}
        {!certain && <span className="diagram_uncertain" aria-hidden={true}>{LOCALIZATION.connections.uncertain}</span>}
        {getSources(removeEmptySources(sources))}
      </MultiArrow>
    );
    return (
      <ScrollContainer initialScrollRatio={{x: 0.5, y: 1}}>
        <div className="diagram" style={sizeStyles}>
          {itemsAndArrows.map((itemOrArrow, index) => (
            <React.Fragment key={index}>
              {itemOrArrow.isArrow && getArrow(itemOrArrow.arrow)}
              {!itemOrArrow.isArrow && getItem(itemOrArrow.item)}
            </React.Fragment>
          ))}
        </div>
      </ScrollContainer>
    );
  }, [itemsAndArrows, currentLocation, ScrollContainer, moveEventDispatcher]);
}