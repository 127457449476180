import React from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {APISources} from '../../types/APIResponse';
import {Word} from '../../types/Word';
import {embedNodes, joinNodes} from '../../helpers/embedders/nodeEmbedder';
import {getLanguageName} from '../../helpers/languageHelper';
import {getWordWithPrefix} from '../../helpers/wordHelper';
import {useUniqueId} from '../../hooks/useUniqueId';
import './Sources.scss';

type Props = {
  sources: APISources[],
  className?: string
};

export function Sources({sources}: Props) {
  const id = useUniqueId();

  const getWord = (word: Word) => {
    return (
      <span className="sources_word">
        {getWordWithPrefix(word)}
      </span>
    );
  };

  const getLanguage = (word: Word) => {
    return (
      <span className="sources_language">
        {getLanguageName(word.language, true)}
      </span>
    );
  };

  const getRelation = (
    parents: Word[] | null,
    child: Word,
    symmetric: boolean,
    itemGetter: (word: Word) => React.ReactNode,
    className: string
  ) => {
    return (
      <span className={`sources_relation ${className}`}>
            {parents === null
              ? LOCALIZATION.sources.onomatopoeiaParent
              : joinNodes(parents.map(
                  (parent) => itemGetter(parent)),
                LOCALIZATION.sources.parentConnector
              )}
        {' '}
        {symmetric ? LOCALIZATION.sources.parentToChildSymmetricArrow : LOCALIZATION.sources.parentToChildArrow}
        {' '}
        {itemGetter(child)}
      </span>
    );
  };

  return (
    <section className="sources" aria-labelledby={id}>
      <h2 id={id} hidden>{LOCALIZATION.sources.label}</h2>
      {sources.map((sourceSet, index) => (
        <section key={index} className="sources_set">
          {sourceSet.map(({parents, child, symmetric, sources}, index) => (
            <article key={index} className="sources_item">
              {getRelation(parents, child, symmetric, getWord, 'sources_words')}
              {getRelation(parents, child, symmetric, getLanguage, 'sources_languages')}
              {embedNodes(
                LOCALIZATION.sources.itemTemplate,
                joinNodes(
                  sources.map(({name: source, preposition, entries}) => embedNodes(
                    LOCALIZATION.sources.sourceTemplate,
                    (_, name) => name === 'entries' ? joinNodes(
                      entries.map(({ name: entryName, url }) => <a href={url}>{entryName}</a>),
                      LOCALIZATION.sources.withinSourceConnector
                    ) : {source, preposition}[name as 'source' | 'preposition']
                  )),
                  LOCALIZATION.sources.sourcesConnector
                )
              )}
            </article>
          ))}
        </section>
      ))}
    </section>
  );
}