import React, {useCallback, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {LANGUAGES_OF_INTEREST} from '../../constants/constants';
import {Language} from '../../types/Language';
import {LanguagesOfInterestState} from '../../context/StateContext';
import {embedText} from '../../helpers/embedders/textEmbedder';
import {getLanguageName} from '../../helpers/languageHelper';
import {useUniqueId} from '../../hooks/useUniqueId';
import {DashedText} from '../DashedText/DashedText';
import {Popup, PopupType} from '../Popup/Popup';
import {SelectLanguage} from '../SelectLanguage/SelectLanguage';
import {ListEditor} from '../ListEditor/ListEditor';
import {AriaAlert} from '../AriaAlert/AriaAlert';
import {ReactComponent as HelpSvg} from '../../images/help.svg';
import {ReactComponent as AddSvg} from '../../images/add.svg';
import {ReactComponent as SettingsSvg} from '../../images/settings.svg';
import './LanguagesOfInterest.scss';

type Props = {
  state: LanguagesOfInterestState,
  onLanguagesUpdated: (languages: Language[]) => void
};

enum PopupState {
  Empty,
  Loading,
  Selecting
}

export function LanguagesOfInterest({state, onLanguagesUpdated}: Props) {
  const [helpShownTime, setHelpShownTime] = useState<number | null>(null);
  const [, setHelpToBeShown] = useState(false);
  const showHelpDelayed = useCallback(() => {
    setTimeout(() => setHelpToBeShown((helpToBeShown) => {
      if (helpToBeShown) {
        setHelpShownTime((lastTime) => lastTime || new Date().getTime());
      }
      return false;
    }), LANGUAGES_OF_INTEREST.helpDelay);
    setHelpToBeShown(true);
  }, []);
  const showHelp = useCallback(() => {
    setHelpToBeShown(false);
    setHelpShownTime((lastTime) => lastTime || new Date().getTime());
  }, []);
  const hideHelp = useCallback(() => {
    setHelpToBeShown(false);
    setHelpShownTime(null);
  }, []);
  const helpRef = useRef<HTMLButtonElement>(null);
  const replaceButtonRef = useRef<HTMLButtonElement>(null);
  const controlRef = useRef<HTMLButtonElement>(null);

  const [replacementPopupShown, setReplacementPopupShown] = useState(false);
  const showReplacementPopup = useCallback(() => setReplacementPopupShown(true), []);
  const hideReplacementPopup = useCallback(() => setReplacementPopupShown(false), []);

  const [popupState, setPopupState] = useState(PopupState.Empty);
  const [popupSelectActive, setPopupSelectActive] = useState(false);
  const [languagesAdded, setLanguagesAdded] = useState(0);
  const [languagesEdited, setLanguagesEdited] = useState(0);
  const [editPopupShown, setEditPopupShown] = useState(false);
  const showEditPopup = useCallback(() => setEditPopupShown(true), []);
  const hideEditPopup = useCallback(() => {
    setEditPopupShown(false);
    setPopupState(PopupState.Empty);
    setPopupSelectActive(false);
  }, []);

  const onFilledStateUpdated = useCallback((filled) => {
    if (filled) {
      if (popupState === PopupState.Empty) {
        setPopupState(PopupState.Loading);
      }
    } else {
      if (popupState !== PopupState.Empty) {
        setPopupState(PopupState.Empty);
      }
    }
  }, [popupState]);

  const onLoadingStateChanged = useCallback((loading) => {
    if (!loading && popupState === PopupState.Loading) {
      setPopupState(PopupState.Selecting);
    }
  }, [popupState]);

  const onActiveStateChanged = useCallback((active) => setPopupSelectActive(active), []);

  const localization = LOCALIZATION.languagesOfInterest;
  const countLeft = Math.max(0, LANGUAGES_OF_INTEREST.maxCount - state.languages.length);
  const popupAddText = localization.mainPopup.add;
  const singleLanguage = state.languages.length === 1 ? state.languages[0] : null;

  const popupAddLabelId = useUniqueId();
  const popupOrderLabelId = useUniqueId();

  return (
    <section
      className={
        `languagesOfInterest` +
        `${state.initialized ? '' : ' languagesOfInterest-hidden'}` +
        `${singleLanguage !== null ? ' languagesOfInterest-singleLanguage' : ''}`
      }
      aria-label={localization.label}
    >
      <button
        className={
          `languagesOfInterest_help languagesOfInterest_help-${helpShownTime === null ? 'inactive' : 'active'}`
        }
        tabIndex={0}
        onBlur={(event) => {
          if (event.relatedTarget instanceof Element) {
            hideHelp();
          }
        }}
        onMouseEnter={showHelpDelayed}
        onMouseLeave={hideHelp}
        onClick={(event) => {
          if ((event.target instanceof Element) && !event.currentTarget.contains(event.target)) {
            return; // This is a click or key press inside the tooltip
          }
          if (helpShownTime === null) {
            showHelp();
          } else if (event.detail === 0 // Enter or Space are pressed
            || new Date().getTime() - helpShownTime > LANGUAGES_OF_INTEREST.clickDelay) {
            hideHelp();
          }
        }}
        aria-pressed={helpShownTime !== null}
        aria-label={localization.help.label}
        ref={helpRef}
      >
        <HelpSvg className="languagesOfInterest_helpIcon" />
        {helpShownTime !== null && (
          <Popup
            type={PopupType.Tooltip}
            label={localization.help.label}
            anchor={helpRef}
            onDismissed={hideHelp}
          >
            {localization.help.text}
          </Popup>
        )}
      </button>
      <span className="languagesOfInterest_text">
        <span className="languagesOfInterest_title">
          {localization.title}{localization.titleConnector}
        </span>
        <span
          className="languagesOfInterest_list"
          title={singleLanguage !== null
            ? ''
            : state.languages.map((language) => getLanguageName(language, true)).join(localization.languageConnector)}
        >
          {singleLanguage !== null ? (
            <button className="languagesOfInterest_replaceableLanguage"
                    ref={replaceButtonRef}
                    onClick={() => {
                      if (editPopupShown) {
                        hideEditPopup();
                      }
                      if (replacementPopupShown) {
                        hideReplacementPopup()
                      } else {
                        showReplacementPopup();
                      }
                    }}
                    title={embedText(localization.replace.title, getLanguageName(singleLanguage, true))}
                    aria-label={embedText(localization.replace.label, getLanguageName(singleLanguage, true))}
                    aria-haspopup="dialog"
            >
              <DashedText outline={true} truncate={false}>
                {getLanguageName(singleLanguage, true)}
              </DashedText>
            </button>
          ) : state.languages.map((language) => getLanguageName(language, true)).join(localization.languageConnector)}
        </span>
      </span>
      <button className={`languagesOfInterest_control ${editPopupShown ? 'languagesOfInterest_control-active' : ''}`}
              ref={controlRef}
              onClick={() => {
                if (replacementPopupShown) {
                  hideReplacementPopup();
                }
                if (editPopupShown) {
                  hideEditPopup()
                } else {
                  showEditPopup();
                }
              }}
              aria-label={singleLanguage !== null ? localization.add.label : localization.edit.label}
              aria-haspopup="dialog"
      >
        <div className="languagesOfInterest_controlIconContainer">
          {singleLanguage !== null
            ? <AddSvg className="languagesOfInterest_controlIcon" title={localization.add.title} />
            : <SettingsSvg className="languagesOfInterest_controlIcon" title={localization.edit.title} />}
        </div>
      </button>
      {replacementPopupShown && (
        <Popup
          type={PopupType.Popover}
          label={localization.replace.popupLabel}
          anchor={replaceButtonRef}
          onDismissed={hideReplacementPopup}
        >
          <SelectLanguage
            placeholder={LOCALIZATION.languageInputs.placeholders.replacement}
            label={LOCALIZATION.languageInputs.labels.replacement}
            expandParent={true}
            autoFocus={true}
            preventScrollOnFocus={true}
            exclude={state.languages}
            onSelected={(language) => {
              hideReplacementPopup();
              onLanguagesUpdated([language]);
              replaceButtonRef.current?.focus();
            }}
          />
        </Popup>
      )}
      {editPopupShown && (
        <Popup type={PopupType.Popover}
               label={localization.mainPopup.label}
               anchor={controlRef}
               anchorKey={languagesAdded + languagesEdited}
               onDismissed={hideEditPopup}
        >
          <section aria-labelledby={popupAddLabelId} className="languagesOfInterest_popupAdd">
            <div id={popupAddLabelId} className="languagesOfInterest_popupExplanation">
              {countLeft === 0 ? popupAddText.noneLeft
                               : (countLeft === 1 ? popupAddText.singleLeft
                                                  : embedText(popupAddText.multipleLeft, countLeft.toString()))}
            </div>
            <SelectLanguage
              autoFocus={true}
              preventScrollOnFocus={true}
              autoFocusKey={languagesAdded.toString()}
              placeholder={LOCALIZATION.languageInputs.placeholders.new}
              label={LOCALIZATION.languageInputs.labels.new}
              expandParent={popupState !== PopupState.Loading}
              disabled={countLeft === 0}
              exclude={state.languages}
              onSelected={(language) => {
                onLanguagesUpdated([...state.languages, language]);
                setLanguagesAdded((languagesAdded) => languagesAdded + 1);
              }}
              onFilledStateUpdated={onFilledStateUpdated}
              onLoadingStateChanged={onLoadingStateChanged}
              onActiveStateChanged={onActiveStateChanged}
            />
          </section>
          <section aria-labelledby={popupOrderLabelId} className={`languagesOfInterest_popupList` +
            `${popupState === PopupState.Selecting && popupSelectActive
                 ? ' languagesOfInterest_popupList-hidden' : ''}`}
          >
            <div id={popupOrderLabelId} className="languagesOfInterest_popupExplanation">
              {LOCALIZATION.languagesOfInterest.mainPopup.list}
            </div>
            <ListEditor autoFocus={countLeft === 0}
                        preventScrollOnFocus={true}
                        items={state.languages.map((language) => ({
                          title: getLanguageName(language, true),
                          key: language.code,
                          data: language
                        }))}
                        labelledBy={popupOrderLabelId}
                        onChanged={(languages) => {
                          onLanguagesUpdated(languages);
                          setLanguagesEdited((languagesEdited) => languagesEdited + 1);
                        }}
                        inactive={popupState === PopupState.Loading && popupSelectActive}
            />
          </section>
          <AriaAlert onlyUpdated={true} message={embedText(
            localization.mainPopup.ariaUpdate.template,
            state.languages.map(
              (language) => getLanguageName(language, true)
            ).join(localization.mainPopup.ariaUpdate.connector)
          )} />
        </Popup>
      )}
    </section>
  );
}