import {useEffect} from 'react';
import {urlToTitle} from '../helpers/urlToTitle';
import {getRandomKey} from '../helpers/randomHelper';
import {getCurrentHash} from '../helpers/hashHelper';

const popStateEventName = 'popstate';
const pushStateEventName = '__custom_pushstate';
const hashChangeEventName = 'hashchange';

let stateKey: string = parseKey(window.history.state, isAfterReload()) ?? getRandomKey();
updateKey();

function isAfterReload(): boolean {
  if (window.performance?.getEntriesByType) {
    const navEntries = window.performance.getEntriesByType('navigation');
    if (navEntries.length > 0) {
      return (navEntries[0] as PerformanceNavigationTiming).type !== 'back_forward';
    }
  }
  // noinspection JSDeprecatedSymbols
  if (window.performance?.navigation?.type !== undefined) {
    // noinspection JSDeprecatedSymbols
    return window.performance.navigation.type !== window.performance.navigation.TYPE_BACK_FORWARD;
  }
  return false;
}

function updateKey(key: string = stateKey, shouldSurviveReload: boolean = false): void {
  stateKey = key;
  window.history.replaceState({key: stateKey, shouldSurviveReload}, '');
}

function parseKey(state: any, afterReload: boolean = false): string | null {
  if (state !== null && typeof state === 'object' && typeof state.key === 'string') {
    return !afterReload || state.shouldSurviveReload ? state.key : null;
  } else {
    return null;
  }
}

function getCurrentUrlWithoutHash(): string {
  return window.location.pathname + window.location.search;
}

function getCurrentUrl(): string {
  return getCurrentUrlWithoutHash() + getCurrentHash();
}

function updateTitle(): void {
  document.title = urlToTitle(window.location.pathname, window.location.search);
}

function triggerHashChange(): void {
  window.dispatchEvent(new HashChangeEvent(hashChangeEventName)); // This is needed for useHash to work properly
}

function setUrl(url: string): void {
  if (url === getCurrentUrl()) {
    return; // We need setting url to be idempotent in order to conform to React's strict mode
  }
  const lastUrlWithoutHash = getCurrentUrlWithoutHash();
  window.history.pushState(null, '', url);
  updateKey(getCurrentUrlWithoutHash() === lastUrlWithoutHash ? stateKey : getRandomKey());
  window.dispatchEvent(new CustomEvent(pushStateEventName));
  triggerHashChange();
}

function resetHash(hash: string): void {
  window.history.replaceState(null, '', `#${hash}`);
  updateKey();
  triggerHashChange();
}

export function useUrlController(): [(url: string) => void, (hash: string) => void] {
  useEffect(() => {
    updateTitle();
    let currentUrlWithoutHash = getCurrentUrlWithoutHash();
    const onStatePopped = ({state}: PopStateEvent) => {
      updateKey(parseKey(state) ?? stateKey); // state === null is a manual hash change
      const newUrlWithoutHash = getCurrentUrlWithoutHash();
      if (newUrlWithoutHash !== currentUrlWithoutHash) {
        updateKey(stateKey, true);
        window.location.reload();
      } else {
        updateTitle();
      }
      currentUrlWithoutHash = newUrlWithoutHash;
    };
    const onStatePushed = () => {
      currentUrlWithoutHash = getCurrentUrlWithoutHash();
      updateTitle();
    };
    window.addEventListener(popStateEventName, onStatePopped);
    window.addEventListener(pushStateEventName, onStatePushed);
    return () => {
      window.removeEventListener(pushStateEventName, onStatePushed);
      window.removeEventListener(popStateEventName, onStatePopped);
    };
  }, []);
  return [setUrl, resetHash];
}

export function useUrlControllerData(): {url: string, key: string} {
  return {
    url: getCurrentUrl(),
    key: stateKey
  };
}