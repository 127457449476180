import {Word} from './Word';
import {Box, Dimensions, unsetDimensions} from './Geometry';

export type WordMeasurements = {
  size: Dimensions,
  labelBoxes: Box[],
  center: Dimensions
};

export type WordPosition = {
  position: Dimensions
};

export const unsetWordMeasurements: WordMeasurements = {
  size: unsetDimensions,
  labelBoxes: [],
  center: unsetDimensions
};

export type WordWithMeasurements = Word & WordMeasurements;
export type WordWithMeasurementsAndPosition = Word & WordMeasurements & WordPosition;