export type Dimensions = {
  x: number,
  y: number
};

export const unsetDimensions: Dimensions = {
  x: 0,
  y: 0
};

export type Segment = {
  a: number,
  b: number
};

export type Box = {
  min: Dimensions,
  max: Dimensions
};

export type RoundedBox = {
  box: Box,
  borderRadius: number
};

export enum Horizontal {
  Left = 'left',
  Right = 'right'
}

export enum Zoom {
  In = 'in',
  Out = 'out'
}

export type BezierCurve = {
  a: Dimensions,
  ca: Dimensions,
  cb: Dimensions,
  b: Dimensions
};