import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DROPDOWN} from '../../constants/constants';
import {pixel} from '../../helpers/domHelper';
import './SelectOptions.scss';

export type Option = {
  content: (active: boolean) => React.ReactNode,
  data: any,
  key: string
};

type Props = {
  listboxId: string,
  selectedOptionId: string,
  options: Option[],
  fader: React.ReactNode,
  displayedCount: number, // The actual count can be either up to one more, or
                          // displayedCount exactly + slightly faded out option + hidden options available for scrolling
  keydownSubscribe: (handler: (event: React.KeyboardEvent) => void) => void,
  keydownUnsubscribe: (handler: (event: React.KeyboardEvent) => void) => void,
  onSelected: (data: any) => void
};

export function SelectOptions({
  listboxId, selectedOptionId, options, fader, displayedCount, keydownSubscribe, keydownUnsubscribe, onSelected
}: Props) {
  const count = options.length;
  const [active, setActive] = useState(0);
  const optionContainerRef = useRef<HTMLUListElement>(null);
  const optionRefs = useRef<(HTMLLIElement | null)[]>(options.map(() => null));
  const topFaderRef = useRef<HTMLDivElement>(null);
  const bottomFaderRef = useRef<HTMLDivElement>(null);
  const [multiPageHeight, setMultiPageHeight] = useState<number | null>(null);
  const shouldBeMulti = options.length > displayedCount + 1;

  const ensureInView = useCallback((index: number) => {
    const container = optionContainerRef.current;
    const option = optionRefs.current[index];
    const firstOption = optionRefs.current[0];
    const lastOption = optionRefs.current[optionRefs.current.length - 1];
    const buffer = 1;
    const scrollTo = (container: HTMLElement, position: number) => {
      container.scrollTo({left: container.scrollLeft, top: position, behavior: 'smooth'});
    };
    if (container !== null && option !== null && firstOption !== null && lastOption !== null) {
      const topFaderHeight = firstOption.offsetHeight;
      const bottomFaderHeight = lastOption.offsetHeight;
      const minScrollTop = option.offsetTop + option.offsetHeight - container.offsetHeight + bottomFaderHeight;
      const maxScrollTop = option.offsetTop - topFaderHeight;
      if (container.scrollTop < minScrollTop - buffer) {
        scrollTo(container, maxScrollTop);
      } else if (container.scrollTop > maxScrollTop + buffer) {
        scrollTo(container, minScrollTop);
      }
    }
  }, []);

  useEffect(() => {
    const handler = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Enter': {
          onSelected(options[active].data);
          event.preventDefault();
          break;
        }
        case 'ArrowUp':
        case 'ArrowDown': {
          const index = event.key === 'ArrowUp' ? (active - 1 + count) % count : (active + 1) % count;
          setActive(index);
          ensureInView(index);
          event.preventDefault();
          break;
        }
        default:
          break;
      }
    };
    keydownSubscribe(handler);
    return () => keydownUnsubscribe(handler);
  }, [count, active, ensureInView, options, onSelected, keydownSubscribe, keydownUnsubscribe]);

  useEffect(() => {
    if (multiPageHeight === null) {
      if (shouldBeMulti && optionContainerRef.current !== null) {
        setMultiPageHeight(optionContainerRef.current.offsetHeight);
      }
    } else {
      const firstOption = optionRefs.current[0];
      const lastOption = optionRefs.current[optionRefs.current.length - 1];
      const topFader = topFaderRef.current;
      const bottomFader = bottomFaderRef.current;
      if (firstOption !== null && lastOption !== null && topFader !== null && bottomFader !== null) {
        const observer = new IntersectionObserver((entries) => {
          for (const entry of entries) {
            const percent = Math.round(entry.intersectionRatio * 100);
            if (entry.target === firstOption) {
              topFader.style.transform = `translateY(-${percent}%)`;
            } else {
              bottomFader.style.transform = `translateY(${percent}%)`;
            }
          }
        }, {
          root: optionContainerRef.current,
          threshold: new Array(DROPDOWN.fadeUpdates + 1).fill(0).map(
            (_, index) => index / DROPDOWN.fadeUpdates
          )
        });
        observer.observe(firstOption);
        observer.observe(lastOption);
        return () => observer.disconnect();
      }
    }
  }, [multiPageHeight, shouldBeMulti]);

  return (
    <div className={`selectOptions ${
      multiPageHeight !== null ? 'selectOptions-multi' : (shouldBeMulti ? 'selectOptions-willBeMulti' : '')
    }`}>
      <ul
        className="selectOptions_optionContainer"
        style={multiPageHeight === null ? {} : {height: pixel(multiPageHeight)}}
        ref={optionContainerRef}
        tabIndex={-1} // So that Firefox doesn't focus on the div
        role="listbox"
        id={listboxId}
      >
        {options.map(({key, content, data}, index) => (
          <li
            className={`selectOptions_option ${index >= displayedCount + 1 ? 'selectOptions_option-multi' : ''}`}
            key={key}
            onClick={() => onSelected(data)}
            onMouseMove={() => {      // onMouseEnter gives a false positive when the cursor happens to be at the
              if (active !== index) { // spot where a new option is created; or during scrolling
                setActive(index);
              }
            }}
            onTouchStart={() => setActive(index)}
            ref={(element) => optionRefs.current[index] = element}
            role="option"
            aria-selected={index === active}
            id={index === active ? selectedOptionId : undefined}
          >
            {content(index === active)}
          </li>
        ))}
      </ul>
      {
        ['top', 'bottom'].map((type) => (
          <div
            key={type}
            className={`selectOptions_fader selectOptions_fader-${type}`}
            ref={type === 'top' ? topFaderRef : bottomFaderRef}
            aria-hidden={true}
          >
            <div className="selectOptions_faderWrapper">
              {fader}
            </div>
          </div>
        ))
      }
    </div>
  );
}