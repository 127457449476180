import {ExtendedLocation, Location} from '../types/Location';
import {Section} from '../types/Section';
import {encode, encodeLanguages} from './urlEncoder';

type UrlParts = {
  path: string,
  query: string
};

function locationToUrlParts(location: Location): UrlParts {
  switch (location.section) {
    case Section.Etymology:
      return {
        path: `/${Section.Etymology}${location.query === null ? '' :
          `/${encode(location.query.word.language.code)}/${encode(location.query.word.word)}`
        }`,
        query: ''
      };
    case Section.Relation:
      return {
        path: `/${Section.Relation}${location.query === null ? '' :
          `/${encode(location.query.wordA.language.code)}/${encode(location.query.wordA.word)}`
          + `/${encode(location.query.wordB.language.code)}/${encode(location.query.wordB.word)}`
        }`,
        query: ''
      };
    case Section.Cognates:
      return {
        path: `/${Section.Cognates}${location.query === null ? '' :
          `/${encode(location.query.word.language.code)}/${encode(location.query.word.word)}`
        }`,
        query: location.query === null ? '' :
          `?targetLanguages=${encodeLanguages(location.query.targetLanguages)}`
      };
    case Section.About:
      return {
        path: `/${Section.About}`,
        query: ''
      };
  }
}

export function locationToUrl(location: Location, subrequest: string = ''): string {
  const {path, query} = locationToUrlParts(location);
  return path + query + (subrequest ? '#' + subrequest : '');
}

export function extendedLocationsEqual(a: ExtendedLocation, b: ExtendedLocation): boolean {
  return locationToUrl(a.location, a.subrequest ?? '') === locationToUrl(b.location, b.subrequest ?? '');
}

export function urlAtLocation(path: string, query: string, location: Location): boolean {
  const parts = locationToUrlParts(location);
  return path === parts.path && query === parts.query;
}