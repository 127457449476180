export {};

// The following is to prevent the background and content from being displaced when the keyboard is active on Android.
initialize();

function getViewport(): {width: number, height: number, scale: number} | undefined {
  // @ts-ignore
  return window.visualViewport;
}

function initialize() {
  if (!getViewport()) {
    return;
  }
  const minDocWidth = parseInt(process.env.REACT_APP_MIN_DOC_WIDTH ?? '') || 1;
  const meta = document.querySelector('meta[name=viewport]');
  if (!meta) {
    return;
  }
  const setHeight = (height: number | null) =>
    meta.setAttribute('content', `width=${minDocWidth}${height === null ? '' : `, height=${height}`}, initial-scale=1`);
  let maxSeenHeight = 0;
  const reset = () => {
    if (maxSeenHeight > 0) {
      maxSeenHeight = 0;
      setHeight(null);
    }
  };
  const update = () => {
    const viewport = getViewport()!;
    if (viewport.width * viewport.scale < minDocWidth - 0.5) {
      // Narrow screen; height should not be set, otherwise it is treated as max-height rather than min-height
      reset();
    } else if (document.documentElement.scrollHeight <= document.documentElement.clientHeight) {
    // The condition above is to exclude touches while at the About page, which then cause overflow for the main content
      const height = Math.round(viewport.height * viewport.scale);
      if (height > maxSeenHeight) {
        maxSeenHeight = height;
        setHeight(height);
      }
    }
  };
  window.addEventListener('orientationchange', () => reset());
  document.addEventListener('touchstart', () => update());
  document.addEventListener('focusin', () => update());
  // Just focusin (no touchstart) would not be enough for the scenario when the user enters the page while the device
  // is in the horizontal orientation (no keyboard appears on page load), turns the device, taps on the focused input.
  // Just touchstart (no focusin) would not be enough for the scenario when the user enters through the About page
  // (which doesn't update viewport, see above) and then switches to one of the pages with an autofocused input.

  // The above logic seems like the best balance between the behavior on Android and iOS bugs/"features" (Chrome and
  // Firefox on iOS firing orientationchange after resize as well as stuff like
  // https://stackoverflow.com/questions/60192505/ios-mobile-safari-navigation-bar-toolbar-issue-on-orientation-change).
}