export type Replacement<T> = T | ((text: string, name: string | undefined) => T);

export function replace<T>(replacement: Replacement<T>, text: string, name: string | undefined): T {
  return replacement instanceof Function ? replacement(text, name) : replacement;
}

function parseSegment(segment: string): [string, string | undefined] {
  const match = segment.match(/^<([^>]+)>(.*)$/);
  return match ? [match[2], match[1]] : [segment, undefined];
}

export function embed<T>(
  text: string, callback: (index: number, text: string, name: string | undefined) => T
): (string | T)[] {
  return text.split(/(\[[^[]+?])/).map(
    (part, index) => index % 2 === 0 ? part : callback((index - 1) / 2, ...parseSegment(part.slice(1, -1)))
  );
}