import React from 'react';
import {Word} from '../../types/Word';

type Props = {
  className?: string,
  word: Word,
  customContent?: React.ReactNode
};

export function SimpleWordContainer({className, word, customContent}: Props) {
  return (
    <span className={className} lang={word.language.code} translate="no">{customContent ?? word.word}</span>
  );
}