import {LOCALIZATION} from '../constants/localization';
import {URL_SENSE_FORMAT} from '../constants/constants';
import {embedText} from './embedders/textEmbedder';

function formatUrlSense(sense: string): string {
  return sense.replace(/ /g, '_');
}

function formatSingle(template: string, sense: string): string {
  return template.replace('{a}', sense);
}

function formatDouble(template: string, a: string, b: string): string {
  return template.replace('{a}', a).replace('{b}', b);
}

function formatMissingIndex(index: number): string {
  return (index + 1).toString();
}

function formatMissing(template: string, index: number): string {
  return template.replace('{n}', formatMissingIndex(index));
}

export function formatSingleSubrequestLabel(sense: string): string {
  return embedText(LOCALIZATION.senses.single, sense);
}

export function formatSingleSubrequestUrl(sense: string): string {
  return formatSingle(URL_SENSE_FORMAT.single, formatUrlSense(sense));
}

export function formatDoubleSubrequestLabel(senseA: string, senseB: string): string {
  return embedText(LOCALIZATION.senses.double, (_, name) => {
    switch (name as 'a' | 'b') {
      case 'a':
        return senseA;
      case 'b':
        return senseB;
    }
  });
}

export function formatDoubleSubrequestUrl(senseA: string, senseB: string): string {
  return formatDouble(URL_SENSE_FORMAT.double, formatUrlSense(senseA), formatUrlSense(senseB));
}

export function formatMissingSubrequestLabel(index: number): string {
  return embedText(LOCALIZATION.senses.missing, formatMissingIndex(index));
}

export function formatMissingSubrequestUrl(index: number): string {
  return formatMissing(URL_SENSE_FORMAT.missing, index);
}