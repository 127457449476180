import React from 'react';
import './SoftAlert.scss';

type Props = {
  children: React.ReactNode,
  className?: string
};

export function SoftAlert({children, className}: Props) {
  return (
    <div className="softAlert">
      <div className={`softAlert_content ${className ?? ''}`}>
        {children}
      </div>
    </div>
  );
}