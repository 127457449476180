export class EventDispatcher {
  eventListeners: (() => void)[] = [];

  addEventListener(listener: () => void): void {
    this.removeEventListener(listener);
    this.eventListeners = [...this.eventListeners, listener];
  }

  removeEventListener(listener: () => void): void {
    this.eventListeners = this.eventListeners.filter((current) => current !== listener);
  }

  dispatch(): void {
    this.eventListeners.forEach((listener) => listener());
  }
}