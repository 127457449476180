import React, {useCallback, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {Language} from '../../types/Language';
import {ExtraLanguageState} from '../../context/StateContext';
import {embedNodes} from '../../helpers/embedders/nodeEmbedder';
import {embedText} from '../../helpers/embedders/textEmbedder';
import {getLanguageName} from '../../helpers/languageHelper';
import {DashedText} from '../DashedText/DashedText';
import {Popup, PopupType} from '../Popup/Popup';
import {SelectLanguage} from '../SelectLanguage/SelectLanguage';
import './CustomLanguages.scss';

export enum CustomLanguagesSelection {
  None,
  LanguagesOfInterest,
  ExtraLanguage
}

type Props = {
  state: ExtraLanguageState,
  selection: CustomLanguagesSelection,
  onLanguageChanged: (language: Language) => void,
  onSwitched: (customEnabled: boolean) => void
};

export function CustomLanguages({state, selection, onLanguageChanged, onSwitched}: Props) {
  const [popupShown, setPopupShown] = useState(false);
  const [extraLanguageEdited, setExtraLanguageEdited] = useState(false);
  const showPopup = useCallback(() => setPopupShown(true), []);
  const hidePopup = useCallback(() => setPopupShown(false), []);
  const languageButtonRef = useRef<HTMLButtonElement>(null);
  const languagesOfInterestSelected = selection === CustomLanguagesSelection.LanguagesOfInterest;
  const extraLanguageSelected = selection === CustomLanguagesSelection.ExtraLanguage;
  const getDashedText = (text: string) => (
    <span className="customLanguages_dashedTextWrapper">
      <DashedText outline={true} truncate={false}>{text}</DashedText>
    </span>
  );
  const getExtraControls = (text: string, label: string) => (
    <span className="customLanguages_extraButtonContainer">
      <button ref={languageButtonRef} className="customLanguages_extraButton"
              onClick={() => popupShown ? hidePopup() : showPopup()} aria-label={label} aria-haspopup="dialog">
        <span className="customLanguages_extraButtonTextContainer">
          {getDashedText(text)}
        </span>
      </button>
    </span>
  );
  const localization = LOCALIZATION.customLanguages;
  const extraLanguageName = state.language === null ? null : getLanguageName(state.language, true);
  const specificLanguageButtonText = extraLanguageName === null ? null : {
    content: embedNodes(
      localization.specificExtraLanguage,
      (text, name) => name === 'language' ? extraLanguageName : text
    ),
    label: embedText(localization.specificExtraLanguageLabel, extraLanguageName)
  };
  return (
    <section className="customLanguages" aria-label={localization.label}>
      <div className="customLanguages_buttonContainer customLanguages_buttonContainer-languagesOfInterest">
        <button
          className={`customLanguages_button ${languagesOfInterestSelected ? 'customLanguages_button-active' : ''}`}
          disabled={languagesOfInterestSelected}
          aria-pressed={languagesOfInterestSelected}
          aria-label={extraLanguageName === null
            ? localization.languagesOfInterestLabelNonSpecific
            : embedText(localization.languagesOfInterestLabelSpecific, extraLanguageName)}
          onClick={() => onSwitched(false)}
        >
          <span className="customLanguages_buttonTextContainer">
            <span className="customLanguages_buttonText customLanguages_buttonText-actual">
              {localization.languagesOfInterest}
            </span>
            {specificLanguageButtonText !== null && (
              <span className="customLanguages_buttonText customLanguages_buttonText-hidden">
                {specificLanguageButtonText.content}
              </span>
            )}
          </span>
        </button>
      </div>
      <div className="customLanguages_buttonContainer customLanguages_buttonContainer-extraLanguage">
        {/* Two buttons are separated in this container because HTML specification prohibits nested controls */}
        {specificLanguageButtonText !== null &&
          <button
            className={`customLanguages_button ${extraLanguageSelected ? 'customLanguages_button-active' : ''}`}
            disabled={extraLanguageSelected}
            aria-pressed={extraLanguageSelected}
            aria-label={specificLanguageButtonText.label}
            onClick={() => onSwitched(true)}
          >
            <span className="customLanguages_buttonTextContainer">
              <span className="customLanguages_buttonText customLanguages_buttonText-hidden">
                {localization.languagesOfInterest}
              </span>
              <span className="customLanguages_buttonText customLanguages_buttonText-hidden">
                {specificLanguageButtonText.content}
              </span>
            </span>
          </button>
        }
        <div className="customLanguages_extraText">
          {extraLanguageName === null
            ? embedNodes(localization.customExtraLanguage, (text, name) => name === 'connector'
              ? <span aria-hidden={true}>{text}</span>
              : getExtraControls(text, localization.customExtraLanguageChangeLabel))
            : embedNodes(localization.specificExtraLanguage, (text, name) => name === 'language'
              ? getExtraControls(extraLanguageName, embedText(extraLanguageEdited
                  ? localization.specificExtraLanguageChangeLabelEdited
                  : localization.specificExtraLanguageChangeLabel,
                  extraLanguageName))
              : <span aria-hidden={true}>{text}</span>)
          }
        </div>
      </div>
      {popupShown && (
        <Popup
          type={PopupType.Popover}
          label={LOCALIZATION.customLanguages.popupLabel}
          anchor={languageButtonRef}
          onDismissed={hidePopup}
        >
          <SelectLanguage
            placeholder={LOCALIZATION.languageInputs.placeholders.main}
            label={LOCALIZATION.languageInputs.labels.main}
            expandParent={true}
            autoFocus={true}
            preventScrollOnFocus={true}
            exclude={state.language === null ? [] : [state.language]}
            onSelected={(language) => {
              setExtraLanguageEdited(true);
              hidePopup();
              onLanguageChanged(language);
              languageButtonRef.current?.focus();
            }}
          />
        </Popup>
      )}
    </section>
  );
}