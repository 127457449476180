import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Horizontal} from '../../types/Geometry';
import {isFocusVisible, isNewTabClick} from '../../helpers/domHelper';
import {ReactComponent as ArrowSvg} from '../../images/arrow.svg';
import './HorizontalScroller.scss';

type OptionData = {
  text: string,
  href: string
};

type Props = {
  label: string,
  options: OptionData[],
  selectedIndex: number,
  onSwitched: (index: number) => void,
  className?: string
};

export function HorizontalScroller({className, label, options, selectedIndex, onSwitched}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const leftControlRef = useRef<HTMLDivElement>(null);
  const rightControlRef = useRef<HTMLDivElement>(null);
  const leftMarkerRef = useRef<HTMLDivElement>(null);
  const rightMarkerRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLAnchorElement>(null);
  const scrollTo = (scroller: HTMLDivElement, position: number) => {
    scroller.scrollTo({left: position, top: 0, behavior: 'smooth'});
  };
  const scrollInDirection = (direction: Horizontal) => {
    const scroller = scrollerRef.current;
    const leftControl = leftControlRef.current;
    const rightControl = rightControlRef.current;
    if (scroller === null || leftControl === null || rightControl === null) {
      return;
    }
    const viewportWidth = scroller.offsetWidth - leftControl.offsetWidth - rightControl.offsetWidth;
    scrollTo(scroller, scroller.scrollLeft + (direction === Horizontal.Left ? -1 : 1) * viewportWidth);
  };
  const centerElement = useCallback((element: HTMLAnchorElement) => {
    setTimeout(async () => { // Timeout is necessary because a scrollbar can dis/appear and mess with the dimensions
      await document.fonts.ready;
      const scroller = scrollerRef.current;
      const leftControl = leftControlRef.current;
      if (scroller === null || leftControl === null ||
          !element.isConnected || !scroller.isConnected || !leftControl.isConnected) {
        return;
      }
      scrollTo(scroller, element.offsetLeft - Math.max(leftControl.offsetWidth, Math.round(
        // Rounding is applied because Chrome can't deal with fractional scroll (see
        // https://bugs.chromium.org/p/chromium/issues/detail?id=890345), and a fractional value creates issues
        // when, e.g., scrolling back to 0.
        (scroller.offsetWidth - element.offsetWidth) / 2
      )));
    }, 0);
  }, []);
  const [leftControlVisible, setLeftControlVisible] = useState(false);
  const [rightControlVisible, setRightControlVisible] = useState(false);
  useEffect(() => {
    const container = containerRef.current;
    const leftMarker = leftMarkerRef.current;
    const rightMarker = rightMarkerRef.current;
    if (container === null || leftMarker === null || rightMarker === null) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const controlShouldBeVisible = entry.intersectionRatio === 0; // Sometimes intersectionRatio doesn't reach 1
        switch (entry.target) {
          case leftMarker:
            setLeftControlVisible(controlShouldBeVisible);
            break;
          case rightMarker:
            setRightControlVisible(controlShouldBeVisible);
            break;
          default:
            break;
        }
      }
    }, {
      root: container,
      threshold: [0, 1]
    });
    observer.observe(leftMarker);
    observer.observe(rightMarker);
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (selectedRef.current !== null) {
      centerElement(selectedRef.current);
    }
  }, [centerElement, selectedIndex]);
  const getMarker = (type: Horizontal, ref: React.RefObject<HTMLDivElement>) => (
    <div className={`horizontalScroller_marker horizontalScroller_marker-${type}`} ref={ref} />
  );
  const getControl = (type: Horizontal, visible: boolean, content: React.ReactElement) => (
    <div key={type}
         onClick={() => scrollInDirection(type)}
         onMouseDown={(event) => event.preventDefault()} // To prevent text selection
         ref={type === Horizontal.Left ? leftControlRef : rightControlRef}
         className={`horizontalScroller_control
                     horizontalScroller_control-${type}
                     horizontalScroller_control-${visible ? 'visible' : 'hidden'}`}
         aria-hidden={true}
    >
      {content}
    </div>
  );
  return (
    <div className={`horizontalScroller ${className ?? ''}`} ref={containerRef}>
      <div className="horizontalScroller_options" ref={scrollerRef}>
        {getMarker(Horizontal.Left, leftMarkerRef)}
        <div className="horizontalScroller_marker horizontalScroller_marker-left" ref={leftMarkerRef} />
        <nav className="horizontalScroller_optionsWrapper" aria-label={label}>
          <ul className="horizontalScroller_optionList">
            {options.map((option, index) => (
              <li key={option.href} className="horizontalScroller_optionListItem">
                <a href={option.href} onFocus={(event) => {
                  if (isFocusVisible(event.currentTarget, false)) {
                    centerElement(event.currentTarget);
                  }
                }} onClick={(event) => {
                  if (isNewTabClick(event)) {
                    return;
                  }
                  event.preventDefault();
                  onSwitched(index);
                }} className={`a-colored a-noOutline horizontalScroller_option horizontalScroller_option-${
                    index === selectedIndex ? 'selected' : 'nonselected'
                }`} ref={index === selectedIndex ? selectedRef : null} aria-current={index === selectedIndex}>
                  {option.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        {getMarker(Horizontal.Right, rightMarkerRef)}
      </div>
      {getControl(Horizontal.Left, leftControlVisible,
        <ArrowSvg className="horizontalScroller_arrow horizontalScroller_arrow-left" />)}
      {getControl(Horizontal.Right, rightControlVisible,
        <ArrowSvg className="horizontalScroller_arrow horizontalScroller_arrow-right" />)}
    </div>
  );
}