import {openDB, DBSchema, IDBPDatabase} from 'idb';

const expirationSeconds = 3 * 86400; // 3 days

const dbName = 'RequestCache';
const tableName = 'requests';
const urlField = 'url';
const timeField = 'time';
const dataField = 'data';
const timeIndex = 'by-time';

interface Cache extends DBSchema {
  [tableName]: {
    value: {
      [urlField]: string,
      [timeField]: number,
      [dataField]: any
    },
    key: string,
    indexes: {
      [timeIndex]: number
    }
  };
}

const dbPromise: Promise<IDBPDatabase<Cache> | null> = new Promise(async (resolve) => {
  try {
    resolve(await openDB<Cache>(dbName, 1, {
      upgrade: (db) => {
        const requests = db.createObjectStore(tableName, {
          keyPath: urlField
        });
        requests.createIndex(timeIndex, timeField);
      }
    }));
  } catch { // Firefox disables IDB in the private mode
    resolve(null);
  }
});

removeExpired().then(/* Nothing to do */);

function timeSeconds(): number {
  return Math.floor(new Date().getTime() / 1000);
}

function thresholdTime(): number {
  return timeSeconds() - expirationSeconds;
}

async function removeExpired(): Promise<void> {
  const db = await dbPromise;
  if (db === null) {
    return;
  }
  const records = await db.getAllFromIndex(tableName, timeIndex, IDBKeyRange.upperBound(thresholdTime()));
  await Promise.all(records.map(({url}) => db.delete(tableName, url)));
}

export async function cache(url: string, data: any): Promise<void> {
  const db = await dbPromise;
  if (db === null) {
    return;
  }
  await db.put(tableName, {
    url,
    time: timeSeconds(),
    data
  });
}

export async function retrieveCached(url: string): Promise<any | null> {
  const db = await dbPromise;
  if (db === null) {
    return null;
  }
  const data = await db.get(tableName, url);
  return data && data.time > thresholdTime()
    ? data.data
    : null;
}