import React from 'react';
import ReactDOM from 'react-dom';
import {
  APIEtymology, APIEtymologyWord, APIEtymologySubtree, APIRelation, APIRelationSubtree, APICognates, APICognateWord,
  APIResponse, APIResponseData, APIResponseStatus
} from '../../types/APIResponse';
import {Word} from '../../types/Word';
import {unsetWordMeasurements, WordMeasurements, WordWithMeasurements} from '../../types/WordGeometry';
import {APIRequestType} from '../../types/APIRequest';
import {safeReject} from '../promiseHelper';
import {domRectToBox} from '../domHelper';
import {shiftBox} from '../geometryHelper';
import {WordBox} from '../../components/WordBox/WordBox';
import './wordMeasurer.scss';

const container = document.getElementById('word-preloader-root')!;

async function measure(words: APIEtymologyWord[]): Promise<WordMeasurements[]> {
  const listContainer = document.createElement('div');
  listContainer.className = 'wordMeasurer_container';
  const wordClass = 'wordMeasurer_wordContainer';
  const labelClass = 'wordMeasurer_labelContainer';
  container.append(listContainer);
  const wordContainers: (HTMLDivElement | null)[] = new Array(words.length).fill(null);
  await new Promise((resolve) => {
    ReactDOM.render(
      <React.StrictMode>
        {words.map((word, index) => (
          <div key={index} className="wordMeasurer_word" ref={(element) => wordContainers[index] = element}>
            <WordBox word={word} showRanges={false} currentLocation={null}
                     wordClassList={[wordClass]} labelClassList={[labelClass]} moveEventDispatcher={null} />
          </div>
        ))}
      </React.StrictMode>,
      listContainer,
      () => resolve()
    );
  });
  const getLabels = (wordContainer: HTMLDivElement | null): HTMLElement[] =>
    wordContainer === null ? [] : [...wordContainer.querySelectorAll(`.${labelClass}`)] as HTMLElement[];
  await document.fonts.ready;
  // Upon the first load, Safari resolves document.fonts.ready before the fonts are actually loaded (see
  // https://bugs.webkit.org/show_bug.cgi?id=217047), so we also need the following:
  await Promise.all(wordContainers.flatMap(
    (wordContainer) => getLabels(wordContainer).map(
      (label): Promise<any> => {
        const font = window.getComputedStyle(label).font;
        return font === '' // Firefox returns an empty string for the property; but it handles fonts.ready correctly
          ? document.fonts.ready
          : safeReject(document.fonts.load(font, label.textContent ?? ''));
      }
    )
  ));

  const measurements: WordMeasurements[] = wordContainers.map((wordContainer) => {
    if (wordContainer === null) {
      return unsetWordMeasurements;
    }
    const word = wordContainer.querySelector(`.${wordClass}`);
    if (word === null) {
      return unsetWordMeasurements;
    }
    const boxBoundingRect = wordContainer.getBoundingClientRect();
    const wordBoundingRect = word.getBoundingClientRect();
    return {
      size: {
        x: boxBoundingRect.width,
        y: boxBoundingRect.height
      },
      labelBoxes: getLabels(wordContainer).map((label) => shiftBox(domRectToBox(label.getBoundingClientRect()), {
        x: -boxBoundingRect.left,
        y: -boxBoundingRect.top
      })),
      center: {
        x: wordBoundingRect.left - boxBoundingRect.left + wordBoundingRect.width / 2,
        y: wordBoundingRect.top - boxBoundingRect.top + wordBoundingRect.height / 2
      }
    };
  });
  ReactDOM.unmountComponentAtNode(listContainer);
  listContainer.remove();
  return measurements;
}

// Since TypeScript does not support higher-kinded types, so the following function is largely untyped
async function convert<T>(data: T,
                          iterator: (data: T, callback: (word: APIEtymologyWord) => any) => any): Promise<any> {
  const words: APIEtymologyWord[] = [];
  iterator(data, (word) => {
    words.push(word);
    return word;
  });
  const measurementsReversed: WordMeasurements[] = (await measure(words)).reverse();
  return iterator(data, (word) => {
    const measurements = measurementsReversed.pop() ?? unsetWordMeasurements;
    return {
      ...word,
      ...measurements
    };
  });
}

function iterateEtymology<T extends Word>(etymology: APIEtymology,
                                          callback: (word: APIEtymologyWord) => APIEtymologyWord<T>): APIEtymology<T> {
  const iterateTree = (tree: APIEtymologySubtree): APIEtymologySubtree<T> => {
    return {
      words: tree.words === null ? null : tree.words.map((word) => callback(word)),
      ...(tree.certain !== undefined ? {
        certain: tree.certain
      } : {}),
      ...(tree.sources ? {
        sources: tree.sources
      } : {}),
      ...(tree.parents ? {
        parents: tree.parents.map(iterateTree)
      } : {})
    };
  };
  return {
    ...etymology,
    data: etymology.data.map((item) => ({
      ...item,
      tree: iterateTree(item.tree)
    }))
  };
}

function iterateRelation<T extends Word>(relation: APIRelation,
                                         callback: (word: APIEtymologyWord) => APIEtymologyWord<T>): APIRelation<T> {
  const iterateTree = (tree: APIRelationSubtree): APIRelationSubtree<T> => {
    return {
      words: tree.words.map((word) => callback(word)),
      ...(tree.certain !== undefined ? {
        certain: tree.certain
      } : {}),
      ...(tree.sources ? {
        sources: tree.sources
      } : {}),
      ...(tree.parent ? {
        parent: iterateTree(tree.parent)
      } : {}),
      ...(tree.child ? {
        child: iterateTree(tree.child)
      } : {}),
      ...(tree.cognate ? {
        cognate: iterateTree(tree.cognate)
      } : {}),
    };
  };
  return {
    ...relation,
    data: relation.data.map((item) => ({
      ...item,
      tree: iterateTree(item.tree)
    }))
  };
}

function iterateCognates<T extends Word>(cognates: APICognates,
                                         callback: (word: APICognateWord) => APICognateWord<T>): APICognates<T> {
  return {
    ...cognates,
    data: cognates.data.map((item) => ({
      ...item,
      cognates: item.cognates.map(callback)
    }))
  };
}

async function convertData(data: APIResponseData): Promise<APIResponseData<WordWithMeasurements>> {
  switch (data.type) {
    case APIRequestType.Etymology:
      return {
        ...data,
        response: await convert(data.response, iterateEtymology)
      };
    case APIRequestType.Relation:
      return {
        ...data,
        response: await convert(data.response, iterateRelation)
      };
    case APIRequestType.Cognates:
      return {
        ...data,
        response: await convert(data.response, iterateCognates)
      };
    default:
      return data;
  }
}

export async function getWordMeasurements(response: APIResponse): Promise<APIResponse<WordWithMeasurements>> {
  if (response.status !== APIResponseStatus.OK) {
    return response;
  }
  const data = await convertData(response.data);
  return{
    ...response,
    data
  };
}