import {Section} from '../types/Section';

export const DEFAULT_SECTION = Section.Etymology;

export const EXTRA_LANGUAGE = {
  key: 'extra_language',
  enabledKey: 'extra_language_enabled'
};

export const QUERY_PARAMETER_SEPARATOR = ',';

export const URL_SENSE_FORMAT = {
  single: '{a}',
  double: '{a}/{b}',
  missing: '{n}'
};

export const SLOW_LOADING_THRESHOLD = 2500;

export const EXTRA_DRAG_SPACE = 5; // In pixels

export const LANGUAGES_OF_INTEREST = {
  maxCount: 5,
  key: 'target_languages',
  default: [{
    code: 'en',
    name: 'English'
  }],
  helpDelay: 250, // To prevent tooltip from showing when the cursor is just moving through or during quick tabbing
  clickDelay: 500 // To avoid immediate closing of the tooltip when the user thinks it needs clicking to show
};

export const INPUT_QUICK_EDIT_KEY = {
  code: 'Slash',
  display: '/'
};

export const DROPDOWN = {
  wordsDisplayed: Math.max(5, LANGUAGES_OF_INTEREST.maxCount),
  languagesDisplayed: 5,
  fadeUpdates: 10
};

export const SCROLL_CONTAINER = {
  state: {
    key: '[key]_scroll_container_[url]',
    keyPlaceholder: '[key]',
    urlPlaceholder: '[url]'
  },
  minScale: 1 / 16,
  maxScale: 16,
  defaultZoomMultiplier: 2.0,
  touchTimeout: 500, // In milliseconds
  wheelZoom: {
    maxStrength: 20,
    maxStrengthThrottle: 50,
    scaleMultiplier: 0.01
  }
};

export const ARROW = {
  controlDistanceRatio: 0.5,
  chevron: {
    angle: Math.PI / 2,
    length: 6
  },
  brace: {
    end: 4,
    endControl: 40,
    middle: 8,
    middleControl: 40,
    cuttingBoxHalfSize: 6
  },
  intersectionSearchGranularity: 1, // In pixels
  backgroundHalfWidth: 12,
  popup: {
    margin: 8,
    autoShowDelay: 150 // In milliseconds
  },
  smoothing: {
    minSlope: 0.75,
    maxDistance: 120
  },
  precisionTolerance: 0.1
};

export const TREE = {
  spacing: {
    horizontal: {
      word: 16,
      branchExtra: 8 // This value is added to 2 * itemArrowMargin + ARROW.backgroundHalfWidth
    },
    vertical: 144
  },
  itemArrowMargin: 16,
  iconSize: 20,
  relationBaseMaxExtraSpacing: 100
};

export const COGNATES = {
  maxWordsDisplayed: 100,
  minSize: 720,
  wordPadding: {
    x: 20,
    y: 20
  },
  maxShift: {
    x: 40,
    y: 15
  },
  search: {
    directionCandidates: 8,
    granularity: 1,
    stepMultiplier: 2
  }
};

export const WORD_BOX_FRAGMENT_WIDTH_TOLERANCE = 1;