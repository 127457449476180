import React, {useCallback, useEffect, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {EXTRA_DRAG_SPACE} from '../../constants/constants';
import {isFocusVisible, pixel} from '../../helpers/domHelper';
import {embedText} from '../../helpers/embedders/textEmbedder';
import {sum} from '../../helpers/arrayHelper';
import {ReactComponent as DragSvg} from '../../images/drag.svg';
import {ReactComponent as UpSvg} from '../../images/up.svg';
import {ReactComponent as RemoveSvg} from '../../images/remove.svg';
import './ListEditorItem.scss';

type Props = {
  title: string,
  callbackKey: string,
  justAdded: boolean,
  position: number,
  focusCapturer: React.RefObject<HTMLElement>,
  shift: number,
  animateShift: boolean,
  canStartAction: boolean,
  inactive: boolean,
  lastReset: number,
  onAnimationStarted: (key: string) => void,
  onAnimationEnded: (key: string) => void,
  onShiftRequested: (key: string, shift: number) => void,
  onTemporaryShiftRequested: (key: string, shift: number) => void,
  onStartDrag: (key: string) => {maxUp: number, maxDown: number},
  onEndDrag: (key: string) => void,
  onRemoved: (key: string) => void
};

type DragInitData = {
  initialY: number,
  initialShift: number,
  maxUp: number,
  maxDown: number
};

enum Button {
  Up = 'up',
  Remove = 'remove'
}

enum FocusState {
  Unfocused = 'unfocused',
  Focused = 'focused',
  VisiblyFocused = 'visibly_focused'
}

type State = {
  containerHeight: number,
  removed: boolean,
  aboveDrag: number,
  aboveTitle: number,
  dragInitData: DragInitData | null,
  dragY: number,
  dragFinalizing: boolean,
  lastWrapperPosition: number | null,
  focusedWithin: boolean,
  focusedButtons: {
    [Button.Up]: FocusState,
    [Button.Remove]: FocusState
  },
  shiftQueue: number[]
};

function isMouseEvent(event: MouseEvent | TouchEvent): event is MouseEvent {
  return ['mousedown', 'mousemove', 'mouseup'].includes(event.type);
}

function getCoordinates(event: MouseEvent | TouchEvent): [number, number] {
  return isMouseEvent(event) ? [event.pageX, event.pageY] : [event.touches[0].pageX, event.touches[0].pageY];
}

export function ListEditorItem({
  title, callbackKey, justAdded, position, focusCapturer, shift, animateShift, canStartAction, inactive, lastReset,
  onAnimationStarted, onAnimationEnded, onShiftRequested, onTemporaryShiftRequested, onStartDrag, onEndDrag, onRemoved
}: Props) {
  const containerRef = useRef<HTMLLIElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>({
    containerHeight: 0,
    removed: false,
    aboveDrag: 0,
    aboveTitle: 0,
    dragInitData: null,
    dragY: 0,
    dragFinalizing: false,
    lastWrapperPosition: 0,
    focusedWithin: false,
    focusedButtons: {
      [Button.Up]: FocusState.Unfocused,
      [Button.Remove]: FocusState.Unfocused
    },
    shiftQueue: []
  });
  const buttonFocusStates = Object.values(state.focusedButtons);
  const focused = state.focusedWithin && buttonFocusStates.every((focusState) => focusState === FocusState.Unfocused);
  useEffect(() => {
    if (!canStartAction || state.shiftQueue.length === 0) {
      return;
    }
    const extraShift = state.shiftQueue[0];
    setState((state) => ({
      ...state,
      shiftQueue: state.shiftQueue.filter((element, index) => index > 0)
    }));
    onShiftRequested(callbackKey, extraShift);
  }, [canStartAction, state.shiftQueue, callbackKey, onShiftRequested]);
  useEffect(() => {
    if (wrapperRef.current !== null) {
      const wrapper = wrapperRef.current;
      setState((state) => ({
        ...state,
        containerHeight: Math.round(wrapper.offsetHeight)
        // Wrapper is used here because container's height is being changed during initial animation
      }));
    }
  }, []);

  const dragModeOn = state.dragInitData !== null || state.dragFinalizing;
  const wrapperPosition = dragModeOn ? state.dragY : shift * state.containerHeight;

  const completeAnimation = useCallback((wrapperPosition: number) => {
    setState((state) => ({
      ...state,
      lastWrapperPosition: wrapperPosition,
      dragFinalizing: false
    }));
    onAnimationEnded(callbackKey);
  }, [callbackKey, onAnimationEnded]);

  const onAnimationCompleted = useCallback((event: React.AnimationEvent | React.TransitionEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    completeAnimation(wrapperPosition);
  }, [completeAnimation, wrapperPosition]);

  useEffect(() => {
    if (dragModeOn) {
      return;
    }
    if (animateShift && state.lastWrapperPosition !== null && wrapperPosition !== state.lastWrapperPosition) {
      setState((state) => ({
        ...state,
        lastWrapperPosition: null
      }));
      onAnimationStarted(callbackKey);
    } else if (!animateShift && state.lastWrapperPosition !== wrapperPosition) {
      setState((state) => ({
        ...state,
        lastWrapperPosition: wrapperPosition
      }));
    }
  }, [animateShift, callbackKey, dragModeOn, wrapperPosition, state.lastWrapperPosition, onAnimationStarted]);

  const dragYRef = useRef(0);

  const enterDragMode = useCallback((initialEvent: React.MouseEvent | React.TouchEvent) => {
    if (!canStartAction || containerRef.current === null) {
      return;
    }
    if (isMouseEvent(initialEvent.nativeEvent)) { // Otherwise it's a passive touch event
      if (initialEvent.nativeEvent.button !== 0) { // Right-click, etc.
        return;
      }
      initialEvent.preventDefault();
    }
    containerRef.current.focus();
    const {maxUp, maxDown} = onStartDrag(callbackKey);
    const [, initialY] = getCoordinates(initialEvent.nativeEvent);
    const initialShift = shift;
    dragYRef.current = initialShift * state.containerHeight;
    setState((state) => ({
      ...state,
      dragY: dragYRef.current,
      dragInitData: {
        initialY,
        initialShift,
        maxUp,
        maxDown
      }
    }));
  }, [callbackKey, shift, canStartAction, onStartDrag, state.containerHeight]);

  useEffect(() => {
    const dragInitData = state.dragInitData;
    if (dragInitData === null) {
      return;
    }
    const leaveDragMode = (event: MouseEvent | TouchEvent) => {
      event.preventDefault(); // So that mouse events don't fire along with touch events
      deinitialize(); // We need to deinitialize explicitly instead of relying on the effect's cleaning up because React
                      // does not necessarily executes the steps in order
      const finalDragY = shift * state.containerHeight;
      const finalize = dragYRef.current === finalDragY;
      dragYRef.current = finalDragY;
      setState((state) => ({
        ...state,
        dragFinalizing: true,
        dragY: dragYRef.current,
        dragInitData: null
      }));
      onEndDrag(callbackKey);
      if (finalize) {
        completeAnimation(dragYRef.current);
      }
    };
    const onKeyDown = (event: KeyboardEvent) => event.preventDefault(); // Prevents tabbing out while dragging
    const drag = (event: MouseEvent | TouchEvent) => {
      const [, currentY] = getCoordinates(event);
      const shiftY = Math.round(Math.max(-(dragInitData.maxUp * state.containerHeight + EXTRA_DRAG_SPACE),
                                Math.min(dragInitData.maxDown * state.containerHeight + EXTRA_DRAG_SPACE,
                                         currentY - dragInitData.initialY)));
      dragYRef.current = dragInitData.initialShift * state.containerHeight + shiftY;
      setState((state) => ({
        ...state,
        dragY: dragYRef.current
      }));
      const currentShift = Math.sign(shiftY) * Math.floor(Math.abs(shiftY) / state.containerHeight + 0.5);
      onTemporaryShiftRequested(callbackKey, currentShift);
    };
    const bodyClassName = 'cursor-move';
    let initialized = false;
    const initialize = () => {
      document.body.classList.add(bodyClassName);
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('mouseup', leaveDragMode);
      document.addEventListener('touchend', leaveDragMode);
      document.addEventListener('mousemove', drag);
      document.addEventListener('touchmove', drag);
      initialized = true;
    };
    const deinitialize = () => {
      if (!initialized) {
        return;
      }
      initialized = false;
      document.removeEventListener('touchmove', drag);
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('touchend', leaveDragMode);
      document.removeEventListener('mouseup', leaveDragMode);
      document.removeEventListener('keydown', onKeyDown);
      document.body.classList.remove(bodyClassName);
    };
    initialize();
    return deinitialize;
  }, [callbackKey, shift, state.containerHeight, state.dragInitData,
      completeAnimation, onEndDrag, onTemporaryShiftRequested]);

  const onMouseMovedAbove = (property: 'aboveDrag' | 'aboveTitle') => {
    if (!dragModeOn && state[property] < lastReset) {
      setState((state) => ({
        ...state,
        [property]: new Date().getTime()
      }));
    }
  };

  const getButton = (button: Button, enabled: boolean, tabbable: boolean, localization: {title: string, label: string},
                     Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>, callback: () => void,
                     shown: boolean = true) => {
    return (
      <button className={`listEditorItem_button` +
              ` listEditorItem_button-${button}` +
              ` listEditorItem_button-${shown ? 'shown' : 'hidden'}`}
              title={enabled ? embedText(localization.title, title) : ''}
              aria-label={embedText(localization.label, title)}
              {...(shown ? {} : {'aria-hidden': true})}
              disabled={!enabled || !shown}
              tabIndex={tabbable ? 0 : -1}
              onClick={() => callback()}
              onFocus={(event) => {
                const focusState = isFocusVisible(event.currentTarget, false)
                  ? FocusState.VisiblyFocused
                  : FocusState.Focused;
                setState((state) => ({
                  ...state,
                  focusedButtons: {
                    ...state.focusedButtons,
                    [button]: focusState
                  }
                }));
              }}
              onBlur={() => setState((state) => ({
                ...state,
                focusedButtons: {
                  ...state.focusedButtons,
                  [button]: FocusState.Unfocused
                }
              }))}
      >
        <div className="listEditorItem_buttonIconContainer">
          <Icon className="listEditorItem_buttonIcon" />
        </div>
      </button>
    );
  };

  return (
    <li
      ref={containerRef}
      className={`listEditorItem` +
      `${(animateShift && state.dragInitData === null) || state.dragFinalizing ? ' listEditorItem-transition' : ''}` +
      `${focused ? ' listEditorItem-focused' : ''}` + // :focus is lost on reordering (and then automatically restored
                                                      // by React, which, however, causes some transitions to rerun)
      `${buttonFocusStates.includes(FocusState.VisiblyFocused) ? ' listEditorItem-focusVisibleWithin' : ''}` +
      `${justAdded ? ' listEditorItem-justAdded' : ''}` +
      `${state.removed ? ' listEditorItem-removed' : ''}` +
      `${state.aboveDrag >= lastReset || state.aboveTitle >= lastReset || dragModeOn ? ' listEditorItem-drag' : ''}` +
      `${canStartAction || focused ? '' : ' listEditorItem-disabled'}` +
      `${inactive ? ' listEditorItem-inactive' : ''}`}
      onAnimationEnd={onAnimationCompleted}
      tabIndex={canStartAction || focused ? 0 : undefined}
      onKeyDown={(event) => {
        if (event.target !== event.currentTarget || dragModeOn) {
          return;
        }
        let shift: number | null = null;
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();
            shift = -1;
            break;
          case 'ArrowDown':
            event.preventDefault();
            shift = 1;
            break;
          default:
            break;
        }
        if (shift !== null) {
          setState((state) => ({
            ...state,
            shiftQueue: [...state.shiftQueue, shift as number]
          }));
        }
      }}
      onFocus={() => setState((state) => ({
        ...state,
        focusedWithin: true
      }))}
      onBlur={() => setState((state) => ({
        ...state,
        focusedWithin: false
      }))}
    >
      <div className="listEditorItem_wrapper"
           style={{transform: `translateY(${pixel(wrapperPosition)})`}}
           onTransitionEnd={onAnimationCompleted}
           ref={wrapperRef}
      >
        <div className="listEditorItem_content">
          <div className="listEditorItem_dragHandle"
               onMouseMove={() => onMouseMovedAbove('aboveDrag')}
               onMouseLeave={() => setState((state) => ({
                 ...state,
                 aboveDrag: 0
               }))}
               onMouseDown={enterDragMode}
               onTouchStart={enterDragMode}
               aria-hidden={true}
          >
            <DragSvg className="listEditorItem_dragHandleIcon" />
          </div>
          <div className="listEditorItem_title"
               onMouseMove={() => onMouseMovedAbove('aboveTitle')}
               onMouseLeave={() => setState((state) => ({
                 ...state,
                 aboveTitle: 0
               }))}
               onMouseDown={enterDragMode}
               onTouchStart={enterDragMode}
          >
            {title}
          </div>
          <div className="listEditorItem_buttonsContainer">
            {getButton(Button.Up, true, // Preventing focus from being displaced while user interacts with the button
              canStartAction, // Still, tabbing should be disabled (e.g., to prevent the user from tabbing onto the
                              // button during the initial expanding animation)
              LOCALIZATION.listEditor.up, UpSvg, () => {
              if (position + sum(state.shiftQueue) === 1 && !(
                // Safari on mouse click + iOS: we want to preserve this behavior so it is consistent across the items
                containerRef.current !== null && document.activeElement === containerRef.current
              )) {
                focusCapturer.current?.focus();
              }
              setState((state) => ({
                ...state,
                shiftQueue: [...state.shiftQueue, -1]
              }));
            }, position > 0)}
            {getButton(Button.Remove, canStartAction, canStartAction, LOCALIZATION.listEditor.remove, RemoveSvg, () => {
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
                // So that Safari and Firefox on Mac don't highlight either the button or the whole item
              }
              onRemoved(callbackKey);
              setState((state) => ({
                ...state,
                removed: true
              }));
            })}
          </div>
        </div>
      </div>
    </li>
  );
}