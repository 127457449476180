import {APIRequestType} from './APIRequest';
import {Word} from './Word';
import {Language} from './Language';

export enum APIResponseStatus {
  Loading,
  Error,
  Quota,
  OK
}

export type APIRange = [number, number];

export type APISense = {
  text: string | null,
  id: string | null
};

export type APISourceItem = {
  parents: Word[] | null, // null denotes onomatopoeia
  child: Word,
  symmetric: boolean,
  sources: {
    name: string,
    preposition: string,
    entries: {
      name: string,
      url: string
    }[]
  }[]
};

export type APISources = APISourceItem[];

export type APIEtymologyWord<T extends Word = Word> = T & {
  sense: {
    short: string,
    full: string
  } | null,
  canonicalSense: APISense,
  ranges: APIRange[]
};

export type APICognateWord<T extends Word = Word> = APIEtymologyWord<T> & {
  similarity: {
    form: number,
    sense: number
  }
};

export type APIEtymologySubtree<T extends Word = Word> = {
  words: APIEtymologyWord<T>[] | null, // null denotes onomatopoeia
  certain?: boolean,
  sources?: APISources,
  parents?: APIEtymologySubtree<T>[]
};

export interface APISingleSense {
  sense: APISense
}

export interface APIDoubleSense {
  senses: [APISense, APISense]
}

export type APISenseEtymology<T extends Word = Word> = {
  sense: APISense,
  tree: APIEtymologySubtree<T>
};

export type APIRelationSubtree<T extends Word = Word> = {
  words: APIEtymologyWord<T>[],
  certain?: boolean,
  sources?: APISources,
  parent?: APIRelationSubtree<T>,
  child?: APIRelationSubtree<T>,
  cognate?: APIRelationSubtree<T>
}

export type APISenseRelation<T extends Word = Word> = {
  senses: [APISense, APISense],
  tree: APIRelationSubtree<T>
};

export type APISenseCognates<T extends Word = Word> = {
  sense: APISense,
  cognates: APICognateWord<T>[]
};

export type APIUserLanguages = Language[];
export type APILanguages = Language[];
export type APIWords = Word[];
export type APIEtymology<T extends Word = Word> = {
  word: Word,
  data: APISenseEtymology<T>[]
};
export type APIRelation<T extends Word = Word> = {
  words: [Word, Word],
  data: APISenseRelation<T>[]
};
export type APICognates<T extends Word = Word> = {
  word: Word,
  languages: Language[],
  data: APISenseCognates<T>[]
};

export type APIResponseData<T extends Word = Word> = {
  type: APIRequestType.UserLanguages,
  response: APIUserLanguages
} | {
  type: APIRequestType.Languages,
  response: APILanguages
} | {
  type: APIRequestType.Words,
  response: APIWords
} | {
  type: APIRequestType.Etymology,
  response: APIEtymology<T>
} | {
  type: APIRequestType.Relation,
  response: APIRelation<T>
} | {
  type: APIRequestType.Cognates,
  response: APICognates<T>
};

export type APIResponse<T extends Word = Word> = {
  status: APIResponseStatus.OK,
  data: APIResponseData<T>
} | {
  status: APIResponseStatus.Loading,
  slow: boolean
} | {
  status: Exclude<Exclude<APIResponseStatus, APIResponseStatus.Loading>, APIResponseStatus.OK>
}