import React from 'react';
import ReactDOM from 'react-dom';
import {useHasUpdated} from '../../hooks/useHasUpdated';
import './AriaAlert.scss';

type Props = {
  message: React.ReactNode,
  onlyUpdated?: boolean
};

export function AriaAlert({message, onlyUpdated = false}: Props) {
  const updated = useHasUpdated(message);
  return ReactDOM.createPortal((
    <div className="ariaAlert" aria-atomic={true}>
      {!onlyUpdated || updated ? message : ''}
    </div>
  ), document.getElementById('aria-alert-root')!);
}