import React, {useMemo} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {TREE} from '../../constants/constants';
import {APIRelationSubtree, APISources} from '../../types/APIResponse';
import {Word} from '../../types/Word';
import {WordWithMeasurements} from '../../types/WordGeometry';
import {ExtendedLocation} from '../../types/Location';
import {Dimensions, Horizontal} from '../../types/Geometry';
import {wordsIdentical} from '../../helpers/wordHelper';
import {getWeight} from '../../helpers/geometryHelper';
import {getLast, rangeDown, rangeUp} from '../../helpers/arrayHelper';
import {
  getBranchSpacing,
  getGraphic,
  getWordDistance,
  GraphicImage,
  shiftItem,
  shiftItems,
  W,
  WP
} from '../../helpers/treeHelper/treeHelper';
import {ArrowSide, PopupPositionPreference} from '../MultiArrow/MultiArrow';
import {Diagram, fromArrow, fromItem, Item, ItemOrArrow} from '../Diagram/Diagram';

type R = APIRelationSubtree<WordWithMeasurements>;

type Props = {
  words: [Word, Word],
  relation: R,
  currentLocation: ExtendedLocation
};

enum RelationType {
  First = 'first',
  Parent = 'parent',
  Child = 'child',
  Cognate = 'cognate'
}

type Connection = {
  certain: boolean,
  sources: APISources
};

type ChainItem = {
  words: W[],
  connection: Connection,
  type: RelationType
};

type Chain = ChainItem[];

type WordsWithConnection<T> = {
  words: T[],
  connection: Connection
};

type TreeBase<T> = {
  left: WordsWithConnection<T> | null,
  right: WordsWithConnection<T> | null
};

type TreeChain<T> = {
  left: WordsWithConnection<T>[],
  right: WordsWithConnection<T>[]
};

type TreeTop<T> = WordsWithConnection<T>;

type Tree<T> = {
  base: TreeBase<T>,
  chain: TreeChain<T>,
  top: TreeTop<T>
};

function buildChain(relation: R): Chain {
  const getters: [RelationType, (relation: R) => R | undefined][] = [
    [RelationType.Parent, (relation) => relation.parent],
    [RelationType.Child, (relation) => relation.child],
    [RelationType.Cognate, (relation) => relation.cognate]
  ];
  const buildRest = (relation: R): Chain => {
    for (const [type, getter] of getters) {
      const subtree = getter(relation);
      if (subtree) {
        return doBuildChain(subtree, type);
      }
    }
    return [];
  };
  const doBuildChain = (relation: R, firstType: RelationType): Chain => {
      return [{
        words: relation.words,
        connection: {
          certain: !!relation.certain,
          sources: relation.sources ?? []
        },
        type: firstType
      }, ...buildRest(relation)];
  };
  return doBuildChain(relation, RelationType.First);
}

function specifyChainToWords(chain: Chain, [leftWord, rightWord]: [Word, Word]): Chain {
  const find = (words: W[], word: Word) => words.find((currentWord) => wordsIdentical(currentWord, word)) ?? words[0];
  const specifyItem = (item: ChainItem, word: Word) => ({...item, words: [find(item.words, word)]});
  return [
    ...(chain.length > 0 ? [specifyItem(chain[0], leftWord)] : []),
    ...chain.slice(1, -1),
    ...(chain.length > 1 ? [specifyItem(chain[chain.length - 1], rightWord)] : [])
  ];
}

function getLeftSubchainLastIndex(chain: Chain): number {
  let index = 0;
  while (index + 1 < chain.length && chain[index + 1].type === RelationType.Parent) {
    index++;
  }
  return index;
}

function getRightSubchainFirstIndex(chain: Chain): number {
  let index = chain.length - 1;
  while (index >= 0 && chain[index].type === RelationType.Child) {
    index--;
  }
  return index;
}

function buildTree(relation: R, words: [Word, Word]): Tree<W> {
  const chain = specifyChainToWords(buildChain(relation), words);
  const maxIndex = chain.length - 1;
  const leftSubchainLastIndex = getLeftSubchainLastIndex(chain);
  const rightSubchainFirstIndex = getRightSubchainFirstIndex(chain);
  const sharedIndex = leftSubchainLastIndex === rightSubchainFirstIndex ? leftSubchainLastIndex : null;
  const leftSubchainLastOwnIndex = sharedIndex === null ? leftSubchainLastIndex : leftSubchainLastIndex - 1;
  const rightSubchainFirstOwnIndex = sharedIndex === null ? rightSubchainFirstIndex : rightSubchainFirstIndex + 1;
  const leftWordIsParent = rightSubchainFirstIndex === 0;
  const rightWordIsParent = leftSubchainLastIndex === maxIndex;
  const get = (index: number, position: Horizontal): WordsWithConnection<W> => ({
    words: chain[index].words,
    connection: chain[position === Horizontal.Left ? index + 1 : index].connection
  });
  const collectConnections = (fromIndex: number, toIndex: number) => {
    const range = rangeUp(Math.max(1, fromIndex), Math.min(maxIndex, toIndex));
    return {
      certain: range.every((index) => chain[index].connection.certain),
      sources: range.flatMap((index) => chain[index].connection.sources)
    };
  };
  return {
    base: {
      left: !leftWordIsParent ? get(0, Horizontal.Left) : null,
      right: !rightWordIsParent ? get(maxIndex, Horizontal.Right) : null
    },
    chain: {
      left: rangeUp(1, leftSubchainLastOwnIndex).map((index) => get(index, Horizontal.Left)),
      right: rangeDown(maxIndex - 1, rightSubchainFirstOwnIndex).map((index) => get(index, Horizontal.Right))
    },
    top: sharedIndex !== null ? {
      words: chain[sharedIndex].words,
      connection: collectConnections(sharedIndex, sharedIndex + 1)
    } : {
      words: [],
      connection: collectConnections(leftSubchainLastIndex + 1, rightSubchainFirstIndex)
    }
  };
}

function getWidth(items: WordsWithConnection<W> | null, backupItems: WordsWithConnection<W> | null = null): number {
  if (items === null) {
    return backupItems === null ? 0 : getWidth(backupItems);
  }
  const wordsWidth = items.words.reduce((width, word) => width + word.size.x, 0);
  const spacing = Math.max(0, items.words.length - 1) * TREE.spacing.horizontal.word;
  return wordsWidth + spacing;
}

function setPositionToEach(wordsWithConnection: WordsWithConnection<W>,
                           callback: (word: W, index: number) => Dimensions): WordsWithConnection<WP> {
  return {
    ...wordsWithConnection,
    words: wordsWithConnection.words.map((word, index) => ({
      ...word,
      position: callback(word, index)
    }))
  };
}

function setPositionToAll(wordsWithConnection: WordsWithConnection<W>,
                          center: number, y: number): WordsWithConnection<WP> {
  let current = center - getWidth(wordsWithConnection) / 2;
  return setPositionToEach(wordsWithConnection, (word) => {
    const position = {x: current + word.center.x, y};
    current += word.size.x + TREE.spacing.horizontal.word;
    return position;
  });
}

function getY(levelFromTop: number): number {
  return levelFromTop * TREE.spacing.vertical;
}

function getBaseModelWidth(base: TreeBase<W>): number {
  return (getWidth(base.left, base.right) + getWidth(base.right, base.left)) / 2;
}

function positionTreeBase(base: TreeBase<W>, levelFromTop: number, shouldCenter: boolean,
                          chainShift: number): [TreeBase<WP>, number] {
  const adjustShift = (shift: number) =>
    chainShift > shift && chainShift <= shift + TREE.relationBaseMaxExtraSpacing / 2 ? chainShift : shift;
  const shift = shouldCenter ? 0 : adjustShift((getBaseModelWidth(base) + getBranchSpacing()) / 2);
  const y = getY(levelFromTop);
  return [{
    left: base.left === null ? null : setPositionToAll(base.left, -shift, y),
    right: base.right === null ? null : setPositionToAll(base.right, shift, y)
  }, base.left === null && base.right === null ? 0 : 1];
}

function positionTreeChain(chain: TreeChain<W>, levelFromTop: number,
                           minModelWidth: number | null): [TreeChain<WP>, number, number] {
  const maxWidth = [...chain.left, ...chain.right].reduce((max, item) => Math.max(max, getWidth(item)), 0);
  const shift = minModelWidth === null ? 0 : (getBranchSpacing() + Math.max(maxWidth, minModelWidth)) / 2;
  const maxLevels = Math.max(chain.left.length, chain.right.length);
  const getIntermediateLevel =
    (index: number, total: number) => getWeight(index, -1, levelFromTop + maxLevels, total, levelFromTop - 1);
  const position = (side: WordsWithConnection<W>[], sign: number) =>
    side.map((item, index) => setPositionToAll(item, sign * shift, getY(getIntermediateLevel(index, side.length))));
  return [{
    left: position(chain.left, -1),
    right: position(chain.right, 1)
  }, maxLevels, shift];
}

function positionTreeTop(top: TreeTop<W>): [TreeTop<WP>, number] {
  return [setPositionToAll(top, 0, 0), 1];
}

function positionTree(tree: Tree<W>): Tree<WP> {
  const singleChain = tree.base.left === null || tree.base.right === null;
  const [positionedTop, topLevels] = positionTreeTop(tree.top);
  const [positionedChain, chainLevels, chainShift] =
    positionTreeChain(tree.chain, topLevels, singleChain ? null : getBaseModelWidth(tree.base));
  const [positionedBase] = positionTreeBase(tree.base, topLevels + chainLevels, singleChain, chainShift);
  return {
    base: positionedBase,
    chain: positionedChain,
    top: positionedTop
  };
}

function getLeftChain(tree: Tree<WP>): WordsWithConnection<WP>[] {
  return [
    ...(tree.base.left === null ? [] : [tree.base.left]),
    ...tree.chain.left
  ];
}

function getRightChain(tree: Tree<WP>): WordsWithConnection<WP>[] {
  return [
    ...(tree.base.right === null ? [] : [tree.base.right]),
    ...tree.chain.right
  ];
}

function isSingleWord(tree: Tree<WP>): boolean {
  return getLeftChain(tree).length === 0 && getRightChain(tree).length === 0 && tree.top.words.length === 1;
}

function collectItemsAndArrows(tree: Tree<WP>): ItemOrArrow[] {
  if (isSingleWord(tree)) {
    const graphic = getGraphic(GraphicImage.Equal, {x: 0, y: getY(0)}, LOCALIZATION.connections.identicalWordsLabel);
    const word = tree.top.words[0];
    const halfDistance = Math.max(
      getWordDistance(word, word, false),
      getWordDistance(word, word, true) + graphic.size.x
    ) / 2;
    return [
      fromItem(shiftItem(word, {x: -halfDistance, y: 0})),
      fromItem(graphic),
      fromItem(shiftItem(word, {x: halfDistance, y: 0}))
    ];
  }
  const withCognateLink = tree.top.words.length === 0;
  const topItems: Item[] = withCognateLink
    ? [getGraphic(GraphicImage.Link, {x: 0, y: getY(0)})]
    : tree.top.words;
  const leftChain = getLeftChain(tree);
  const rightChain = getRightChain(tree);
  const getTopOfChain = (chain: WordsWithConnection<WP>[]) => chain.length > 0 ? getLast(chain)!.words : undefined;
  const [topLeft, topRight] = [getTopOfChain(leftChain), getTopOfChain(rightChain)];
  const baseWordsShift = tree.base.left === null || tree.base.right === null
    ? 0
    : tree.base.right.words[0].position.x - tree.base.left.words[0].position.x;
  const shiftedLeft = leftChain.length > 1
    ? shiftItems(leftChain[1].words, {x: baseWordsShift, y: 0})
    : undefined;
  const shiftedRight = rightChain.length > 1
    ? shiftItems(rightChain[1].words, {x: -baseWordsShift, y: 0})
    : undefined;
  return [
    ...leftChain.flatMap((item, index, chain) => {
      const last = index === chain.length - 1;
      return [...item.words.map((word) => fromItem(word)), ...(last && withCognateLink ? [] : [fromArrow({
        input: last ? topItems : chain[index + 1].words,
        inputAsSecondary: last,
        outputs: [{
          output: item.words,
          symmetricOutput: last ? topRight : undefined,
          symmetricInput: index === 0 ? shiftedRight : undefined
        }],
        straightEnd: last ? ArrowSide.Output : ArrowSide.Input,
        certain: item.connection.certain,
        sources: [item.connection.sources]
      })])];
    }), fromArrow({
      popupPositionPreference: withCognateLink ? PopupPositionPreference.Top : PopupPositionPreference.Regular,
      input: topItems,
      outputs: [
        ...(topLeft ? [{
          output: topLeft,
          symmetricOutput: topRight,
          symmetricInput: leftChain.length === 1 ? shiftedRight : undefined
        }] : []),
        ...(topRight ? [{
          output: topRight,
          symmetricOutput: topLeft,
          symmetricInput: rightChain.length === 1 ? shiftedLeft : undefined
        }] : [])
      ],
      outputsAsSecondary: !withCognateLink,
      straightEnd: ArrowSide.Output,
      certain: tree.top.connection.certain,
      sources: [tree.top.connection.sources],
      commonOrigin: true,
      autoShowPopup: withCognateLink && leftChain.length <= 1 && rightChain.length <= 1
    }),
    ...topItems.map((item) => fromItem(item)),
    ...rightChain.reverse().flatMap((item, index, chain) => {
      const first = index === 0;
      return [...(first && withCognateLink ? [] : [fromArrow({
        input: first ? topItems : chain[index - 1].words,
        inputAsSecondary: first,
        outputs: [{
          output: item.words,
          symmetricOutput: first ? topLeft : undefined,
          symmetricInput: index === chain.length - 1 ? shiftedLeft : undefined
        }],
        straightEnd: first ? ArrowSide.Output : ArrowSide.Input,
        certain: item.connection.certain,
        sources: [item.connection.sources],
        inverseLabel: true
      })]), ...item.words.map((word) => fromItem(word))];
    })
  ];
}

export function Relation({words, relation, currentLocation}: Props) {
  const itemsAndArrows = useMemo(
    () => collectItemsAndArrows(positionTree(buildTree(relation, words))),
    [words, relation]
  );
  return <Diagram itemsAndArrows={itemsAndArrows} currentLocation={currentLocation} />;
}