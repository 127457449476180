import React from 'react';
import {embed, replace, Replacement} from './embedder';

export function embedNodes(text: string, replacement: Replacement<React.ReactNode>): React.ReactNode {
  return embed(text, (index, text, name) => (
    <React.Fragment key={index}>{replace(replacement, text, name)}</React.Fragment>
  ));
}

export function joinNodes(nodes: React.ReactNode[], connector: string): React.ReactNode {
  return nodes.map((node, index) => (
    <React.Fragment key={index}>
      {index > 0 && connector}
      {node}
    </React.Fragment>
  ));
}