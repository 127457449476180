import React from 'react';
import {Box, Dimensions} from '../types/Geometry';
import {shiftBox} from './geometryHelper';

export function isButtonClickKey(event: React.KeyboardEvent): boolean {
  return ['Enter', ' '].includes(event.key);
}

export function isNewTabClick(event: React.MouseEvent): boolean {
  return event.metaKey || event.ctrlKey;
}

export function anyCombiningKeyPressed(event: KeyboardEvent, includeShift: boolean = true): boolean {
  return (includeShift && event.shiftKey) || event.ctrlKey || event.altKey || event.metaKey;
}

export function getRelativeCoordinates({clientX, clientY}: {clientX: number, clientY: number},
                                       element: HTMLElement): Dimensions {
  const boundingRect = element.getBoundingClientRect();
  return {
    x: clientX - boundingRect.left,
    y: clientY - boundingRect.top
  };
}

export function isInsideTag(element: HTMLElement | SVGElement, tags: string[], lookUntil: HTMLElement): boolean {
  const preparedTagList = tags.map((tag) => tag.toUpperCase());
  let current: HTMLElement | SVGElement | null = element;
  while (current !== lookUntil && current !== null) {
    if (preparedTagList.includes(current.tagName.toUpperCase())) { // SVG elements have lowercase tag names
      return true;
    }
    current = current.parentElement;
  }
  return false;
}

export function isEditable(element: Element): boolean {
  return element instanceof HTMLInputElement && element.type.toLowerCase() === 'text';
}

export function getDescendantsRecursively(container: Element): Element[] {
  return [...container.querySelectorAll('*')];
}

export function focusFirstOf(elements: Element[], onlyTabbable: boolean): boolean {
  for (const element of elements) {
    if ((element instanceof HTMLElement || element instanceof SVGElement) && (!onlyTabbable || element.tabIndex >= 0)) {
      element.focus();
      if (document.activeElement === element) {
        return true;
      }
    }
  }
  return false;
}

export function focusFirstOrLastChild(focusFirst: boolean, element: Element, onlyTabbable: boolean): boolean {
  const descendants = getDescendantsRecursively(element);
  return focusFirstOf(focusFirst ? descendants : descendants.reverse(), onlyTabbable);
}

export function isFocusVisible(element: Element, ifNotSupported: boolean): boolean {
  try {
    return element.matches(':focus-visible');
  } catch (e) {
    return ifNotSupported;
  }
}

export function domRectToBox(rect: DOMRect): Box {
  return {
    min: {
      x: rect.left,
      y: rect.top
    },
    max: {
      x: rect.right,
      y: rect.bottom
    }
  };
}

export function viewportToDocument(box: Box): Box {
  return shiftBox(box, {
    x: window.scrollX,
    y: window.scrollY
  });
}

export function getDocumentCoordinates(element: Element): Box {
  return viewportToDocument(domRectToBox(element.getBoundingClientRect()));
}

export function pixel(value: number): string {
  return value + 'px';
}