import {useEffect, useRef, useState} from 'react';

export function useHasUpdated(item: any): boolean {
  const [initial] = useState(item);
  const hasUpdated = useRef(false);
  const updated = hasUpdated.current || item !== initial;
  useEffect(() => {
    // We do this here instead of directly assigning the new value in order for renders not to have side effects
    hasUpdated.current = updated;
  });
  return updated;
}