import React, {useMemo} from 'react';
import {TREE} from '../../constants/constants';
import {APIEtymologySubtree, APISources} from '../../types/APIResponse';
import {Word} from '../../types/Word';
import {WordWithMeasurements} from '../../types/WordGeometry';
import {ExtendedLocation} from '../../types/Location';
import {Dimensions} from '../../types/Geometry';
import {getFirst, getLast, getSymmetricEnd} from '../../helpers/arrayHelper';
import {IncrementalPosition} from '../../helpers/incrementalPosition';
import {wordsIdentical} from '../../helpers/wordHelper';
import {
  getBranchSpacing,
  getGraphic,
  getPositioned,
  getWordDistance,
  GraphicImage,
  shiftItem
} from '../../helpers/treeHelper/treeHelper';
import {Diagram, fromArrow, fromItem, Item, ItemOrArrow} from '../Diagram/Diagram';

type E = APIEtymologySubtree<WordWithMeasurements>;
type EP = {
  items: Item[],
  onomatopoeic: boolean,
  certain: boolean,
  sources: APISources,
  parents: EP[]
};

type Props = {
  word: Word,
  etymology: E,
  currentLocation: ExtendedLocation
};

function getParents(branch: EP | null): EP[] | null {
  return branch === null || !branch.parents ? null : branch.parents;
}

function getLeftEdge(level: EP | null): number | null {
  return level === null || level.items.length === 0 ? null : getPositioned(getFirst(level.items)!).min.x;
}

function getRightEdge(level: EP | null): number | null {
  return level === null || level.items.length === 0 ? null : getPositioned(getLast(level.items)!).max.x;
}

function getHorizontalSpacing(leftBranchRightEdge: number | null, rightBranchLeftEdge: number | null): number {
  return leftBranchRightEdge === null || rightBranchLeftEdge === null
    ? 0
    : leftBranchRightEdge - rightBranchLeftEdge + getBranchSpacing();
}

function getHorizontalDistanceBetweenBranches(left: EP, right: EP): number {
  let [currentLeft, currentRight]: [EP | null, EP | null] = [left, right];
  let [leftBranchRightEdge, rightBranchLeftEdge]: [number | null, number | null] = [null, null];
  const withNull = (callback: (a: number, b: number) => number) => (a: number | null, b: number | null) =>
    a === null ? b : (b === null ? a : callback(a, b));
  const maxWithNull = withNull(Math.max.bind(Math));
  const minWithNull = withNull(Math.min.bind(Math));
  let minDistance = 0;
  while (currentLeft !== null || currentRight !== null) {
    [leftBranchRightEdge, rightBranchLeftEdge] = [
      maxWithNull(leftBranchRightEdge, getRightEdge(currentLeft)),
      minWithNull(rightBranchLeftEdge, getLeftEdge(currentRight))
    ];
    minDistance = Math.max(minDistance, getHorizontalSpacing(leftBranchRightEdge, rightBranchLeftEdge));
    [currentLeft, currentRight] = [
      getLast(getParents(currentLeft)),
      getFirst(getParents(currentRight))
    ];
  }
  return minDistance;
}

function shiftBranch(branch: EP, shift: Dimensions): EP {
  return {
    ...branch,
    items: branch.items.map((item) => shiftItem(item, shift)),
    parents: branch.parents.map((parent) => shiftBranch(parent, shift))
  };
}

function getFirstLevelWidth(branch: EP): number {
  return branch.items.length === 0
    ? 0
    : getPositioned(getLast(branch.items)!).max.x - getPositioned(getFirst(branch.items)!).min.x;
}

function positionBranch(branch: E): EP {
  const parents = (branch.parents ?? []).map((parent) => positionBranch(parent));
  const certain = !!branch.certain;
  const sources = branch.sources ?? [];
  if (branch.words === null) {
    return {
      items: [getGraphic(GraphicImage.Note, {x: 0, y: 0})],
      onomatopoeic: true,
      certain,
      sources,
      parents: []
    };
  } else {
    let parentPositions = new IncrementalPosition(branch.words.length > 1 && parents.length > 1
      ? (getFirstLevelWidth(getFirst(parents)!) - getFirstLevelWidth(getLast(parents)!)) / 4
      : 0);
    for (let i = 1; i < parents.length; i++) {
      parentPositions.addDistance(getHorizontalDistanceBetweenBranches(parents[i - 1], parents[i]));
    }
    let wordPositions = new IncrementalPosition(branch.words.length > 1
      ? (getFirst(branch.words)!.size.x - getLast(branch.words)!.size.x) / 4
      : 0);
    for (let i = 1; i < branch.words.length; i++) {
      wordPositions.addDistance(getWordDistance(branch.words[i - 1], branch.words[i]));
    }
    return {
      items: branch.words.map((word) => ({
        ...word,
        position: {
          x: wordPositions.getPosition(),
          y: 0
        }
      })),
      onomatopoeic: false,
      certain,
      sources,
      parents: parents.map((parent) => shiftBranch(parent, {
        x: parentPositions.getPosition(),
        y: -TREE.spacing.vertical
      }))
    };
  }
}

function specifyToWord(branch: E, word: Word): E {
  const matchingWord = branch.words === null
    ? null
    : branch.words.find((currentWord) => wordsIdentical(currentWord, word));
  return {
    ...branch,
    words: matchingWord ? [matchingWord] : branch.words
  };
}

function collectItemsAndArrows(branch: EP): ItemOrArrow[] {
  const parents = branch.parents;
  return [...branch.items.map((item) => fromItem(item)), ...(branch.items.length > 1
    ? [fromArrow({
      input: parents.flatMap(({items}, index, list) => items.map((item) => ({
        ...item,
        requiresBracePointer: index > 0 && index < list.length - 1
      }))),
      outputs: [{output: branch.items}],
      certain: parents.every(({certain}) => certain),
      onomatopoeic: parents.every(({onomatopoeic}) => onomatopoeic),
      sources: parents.map(({sources}) => sources)
    }), ...parents.flatMap((parent) => collectItemsAndArrows(parent))]
    : parents.flatMap((parent, index) => [fromArrow({
      input: parent.items.map((item) => ({
        ...item,
        requiresBracePointer: false
      })),
      outputs: [{
        output: branch.items,
        symmetricInput: getSymmetricEnd(parents, index)?.items,
      }],
      certain: parent.certain,
      onomatopoeic: parent.onomatopoeic,
      sources: [parent.sources]
    }), ...collectItemsAndArrows(parent)])
  )];
}

export function Etymology({word, etymology, currentLocation}: Props) {
  const itemsAndArrows = useMemo(
    () => collectItemsAndArrows(positionBranch(specifyToWord(etymology, word))),
    [word, etymology]
  );
  return <Diagram itemsAndArrows={itemsAndArrows} currentLocation={currentLocation} />;
}