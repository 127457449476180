import React, {useLayoutEffect, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {WORD_BOX_FRAGMENT_WIDTH_TOLERANCE} from '../../constants/constants';
import {APIEtymologyWord, APIRange, APISense} from '../../types/APIResponse';
import {Word} from '../../types/Word';
import {Section} from '../../types/Section';
import {ExtendedLocation, Location} from '../../types/Location';
import {EventDispatcher} from '../../helpers/eventDispatcher';
import {extendedLocationsEqual} from '../../helpers/locationToUrl';
import {formatDoubleSubrequestUrl, formatSingleSubrequestUrl} from '../../helpers/subrequestFormatter';
import {getWordPrefix} from '../../helpers/wordHelper';
import {getLanguageName} from '../../helpers/languageHelper';
import {pixel} from '../../helpers/domHelper';
import {embedNodes} from '../../helpers/embedders/nodeEmbedder';
import {getSenseUrlText} from '../../helpers/senseHelper';
import {Link} from '../Link/Link';
import {Popup, PopupPosition, PopupType} from '../Popup/Popup';
import {SimpleWordContainer} from '../SimpleWordContainer/SimpleWordContainer';
import {ReactComponent as EllipsisSvg} from '../../images/ellipsis.svg';
import './WordBox.scss';

const variableNames = {
  left: '--wordBox-highlighted-left',
  width: '--wordBox-highlighted-width'
};

type Props = {
  word: APIEtymologyWord,
  relatedWord?: {
    word: Word,
    sense: APISense
  },
  showRanges?: boolean,
  // The below is to determine whether the word should link to its page (null means there should necessarily be a link):
  currentLocation: ExtendedLocation | null,
  wordClassList?: string[],
  labelClassList?: string[],
  moveEventDispatcher: EventDispatcher | null
};

function getRangeIndex(ranges: APIRange[], length: number): boolean[] {
  const index: boolean[] = new Array(length).fill(false);
  for (const [left, right] of ranges) {
    const min = Math.max(left, 0);
    const max = Math.min(right, length);
    for (let i = min; i < max; i++) {
      index[i] = true;
    }
  }
  return index;
}

function getChars(word: string): string[] {
  return [...word]; // Treating surrogate pairs as single characters
}

function getRange(word: string, [from, to]: APIRange): string {
  return getChars(word).slice(from, to).join('');
}

function insertAtRanges(word: string, ranges: APIRange[],
                        callback: (fragment: string) => React.ReactNode): React.ReactNode {
  const chars = getChars(word);
  const rangeIndex = [false, ...getRangeIndex(ranges, chars.length), false];
  const parts = ['', ...chars, ''];
  const fragments: string[] = parts.reduce((array, part, index) => {
    return index === 0 || rangeIndex[index] !== rangeIndex[index - 1]
      ? [...array, part]
      : [...array.slice(0, -1), array[array.length - 1] + part];
  }, [] as string[]);
  return (
    <>
      {fragments.map((fragment, index) => (
        <React.Fragment key={index}>
          {index % 2 === 0 ? <>{fragment}</> : callback(fragment)}
        </React.Fragment>
      ))}
    </>
  );
}

export function WordBox({
  word, relatedWord, showRanges = true, currentLocation, wordClassList = [], labelClassList = [], moveEventDispatcher
}: Props) {
  const sense = word.sense ?? (word.canonicalSense.text !== null ? {
    short: word.canonicalSense.text,
    full: word.canonicalSense.text
  } : null);

  const senseButtonRef = useRef<HTMLButtonElement>(null);
  const [sensePopupShown, setSensePopupShown] = useState(false);

  const location: Location = relatedWord ? {
    section: Section.Relation,
    query: {
      wordA: relatedWord.word,
      wordB: word
    }
  } : {
    section: Section.Etymology,
    query: {
      word
    }
  };

  const constructSubrequest = (): string | null => {
    const wordSense = getSenseUrlText(word.canonicalSense);
    if (relatedWord) {
      const relatedSense = getSenseUrlText(relatedWord.sense);
      return relatedSense && wordSense && formatDoubleSubrequestUrl(relatedSense, wordSense);
    } else {
      return wordSense && formatSingleSubrequestUrl(wordSense);
    }
  };

  const subrequest = constructSubrequest();
  const prefix = getWordPrefix(word);
  const [fragments, setFragments] = useState<[number, number][]>([]); // We use these instead of pure CSS because any
  // intervening markup in fragments' spans (including absolutely positioned pseudo-elements) breaks, e.g., Arabic
  const fragmentClassName = 'wordBox_fragment';
  const foregroundRef = useRef<HTMLDivElement>(null);
  const fragmentContainerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!showRanges || fragmentContainerRef.current === null || foregroundRef.current === null) {
      return;
    }
    const {left: parentLeft, width: parentWidth} = fragmentContainerRef.current.getBoundingClientRect();
    const widthDiff = Math.abs(parentWidth - foregroundRef.current.getBoundingClientRect().width);
    // See https://bugs.webkit.org/show_bug.cgi?id=6148; the same is relevant for combining diacritics
    if (widthDiff <= WORD_BOX_FRAGMENT_WIDTH_TOLERANCE) {
      setFragments(([...fragmentContainerRef.current.getElementsByClassName(fragmentClassName)] as HTMLElement[]).map(
        (fragment) => {
          const boundingRect = fragment.getBoundingClientRect(); // offsetLeft/Width's results are too poor
          return [boundingRect.left - parentLeft, boundingRect.width];
        })
      );
    }
  }, [showRanges]);
  const content = (
    <span className="wordBox_wordForeground" ref={foregroundRef}>
      {showRanges && (
        <>
          <span className="wordBox_fragmentContainerWrapper" aria-hidden={true}>
            <span className="wordBox_fragmentContainer" ref={fragmentContainerRef}>
              {prefix}
              <SimpleWordContainer
                word={word}
                customContent={insertAtRanges(word.word, word.ranges, (fragment) => (
                  <span className={fragmentClassName}>{fragment}</span>
                ))}
                // Chrome may render text in a different font if the lang attribute is not specified
              />
            </span>
          </span>
          <span className="wordBox_wordBackground" aria-hidden={true}>
            {fragments.map(([left, width], index) => (
              <span key={index} className="wordBox_highlighted" style={{
                [variableNames.left]: pixel(left),
                [variableNames.width]: pixel(width)
              }} />
            ))}
          </span>
        </>
      )}
      {prefix !== '' &&
        <span className="wordBox_reconstructed" title={LOCALIZATION.words.reconstructed}>{prefix}</span>
      }
      <SimpleWordContainer className="wordBox_wordMain" word={word} />
    </span>
  );
  return (
    <article className="wordBox">
      <span className={`wordBox_language ${(labelClassList).join(' ')}`}>{getLanguageName(word.language, true)}</span>
      <span className={`wordBox_wordContainer ${(wordClassList).join(' ')} ${(labelClassList).join(' ')}`}>
        <span className="wordBox_wordContainerBefore" />
        {currentLocation !== null && extendedLocationsEqual(
          subrequest !== null ? currentLocation : {location: currentLocation.location, subrequest: null},
          {location, subrequest}
        ) ? (
          <span className="wordBox_word wordBox_word-text">
            {content}
          </span>
        ) : (
          <Link to={location} subrequest={subrequest} className="wordBox_word wordBox_word-link a-colored a-noVisited">
            {content}
          </Link>
        )}
        {showRanges && word.ranges.length > 0 && (
          <span className="wordBox_ariaRanges">
            {embedNodes(LOCALIZATION.words.rangesLabel.template, <SimpleWordContainer word={{
              language: word.language,
              word: word.ranges.map( // Ranges are joined in a single container to make the navigation faster
                (range) => getRange(word.word, range)
              ).join(LOCALIZATION.words.rangesLabel.connector)
            }} />)}
          </span>
        )}
        <span className="wordBox_wordContainerAfter" />
      </span>
      {sense && (
        <span className={`wordBox_sense ${(labelClassList).join(' ')}`}>
          {sense.short}
          {sense.full !== sense.short && (
            <>
              <button
                ref={senseButtonRef}
                className={
                  `wordBox_senseExpanderButton wordBox_senseExpanderButton-${sensePopupShown ? 'active' : 'inactive'}`
                }
                onClick={() => setSensePopupShown(!sensePopupShown)}
                aria-label={LOCALIZATION.words.fullDefinition}
                aria-haspopup="dialog"
                aria-pressed={sensePopupShown}
              >
                <span className="wordBox_senseExpanderIconContainer">
                  <EllipsisSvg className="wordBox_senseExpanderIcon" />
                </span>
              </button>
              {sensePopupShown && (
                <Popup
                  type={PopupType.Tooltip}
                  label={LOCALIZATION.words.fullDefinition}
                  shrinkToContent={true}
                  anchor={senseButtonRef}
                  positionPreference={[PopupPosition.Bottom, PopupPosition.Right]}
                  moveEventDispatcher={moveEventDispatcher ?? undefined}
                  onDismissed={() => setSensePopupShown(false)}
                >
                  {sense.full}
                </Popup>
              )}
            </>
          )}
        </span>
      )}
    </article>
  );
}