import {Language} from './Language';
import {Word} from './Word';

export enum APIRequestType {
  UserLanguages,
  Languages,
  Words,
  Etymology,
  Relation,
  Cognates
}

export type APIRequest = {
  type: APIRequestType.UserLanguages
} | {
  type: APIRequestType.Languages,
  fragment: string,
  excludedLanguages: Language[]
} | {
  type: APIRequestType.Words,
  fragment: string,
  targetLanguages: Language[]
} | {
  type: APIRequestType.Etymology,
  word: Word
} | {
  type: APIRequestType.Relation,
  wordA: Word,
  wordB: Word
} | {
  type: APIRequestType.Cognates,
  word: Word,
  targetLanguages: Language[]
};