import React, {useState} from 'react';
import {ReactComponent as LogoSvg} from '../../images/logo.svg';
import max from '../../images/background/max.webp';
import fallback from '../../images/background/fallback.webp';
import _360p_1x from '../../images/background/360p-1x.webp';
import _360p_2x from '../../images/background/360p-2x.webp';
import _360p_3x from '../../images/background/360p-3x.webp';
import _480p_1x from '../../images/background/480p-1x.webp';
import _480p_2x from '../../images/background/480p-2x.webp';
import _480p_3x from '../../images/background/480p-3x.webp';
import _600p_1x from '../../images/background/600p-1x.webp';
import _600p_2x from '../../images/background/600p-2x.webp';
import _600p_3x from '../../images/background/600p-3x.webp';
import _720p_1x from '../../images/background/720p-1x.webp';
import _720p_2x from '../../images/background/720p-2x.webp';
import _720p_3x from '../../images/background/720p-3x.webp';
import _840p_1x from '../../images/background/840p-1x.webp';
import _840p_2x from '../../images/background/840p-2x.webp';
import _840p_3x from '../../images/background/840p-3x.webp';
import _960p_1x from '../../images/background/960p-1x.webp';
import _960p_2x from '../../images/background/960p-2x.webp';
import _960p_3x from '../../images/background/960p-3x.webp';
import _1080p_1x from '../../images/background/1080p-1x.webp';
import _1080p_2x from '../../images/background/1080p-2x.webp';
import _1080p_3x from '../../images/background/1080p-3x.webp';
import _1200p_1x from '../../images/background/1200p-1x.webp';
import _1200p_2x from '../../images/background/1200p-2x.webp';
import _1320p_1x from '../../images/background/1320p-1x.webp';
import _1320p_2x from '../../images/background/1320p-2x.webp';
import _1440p_1x from '../../images/background/1440p-1x.webp';
import _1440p_2x from '../../images/background/1440p-2x.webp';
import _1560p_1x from '../../images/background/1560p-1x.webp';
import _1560p_2x from '../../images/background/1560p-2x.webp';
import _1680p_1x from '../../images/background/1680p-1x.webp';
import _1680p_2x from '../../images/background/1680p-2x.webp';
import _1800p_1x from '../../images/background/1800p-1x.webp';
import _1920p_1x from '../../images/background/1920p-1x.webp';
import _2160p_1x from '../../images/background/2160p-1x.webp';
import _2400p_1x from '../../images/background/2400p-1x.webp';
import _2640p_1x from '../../images/background/2640p-1x.webp';
import _2880p_1x from '../../images/background/2880p-1x.webp';
import _3120p_1x from '../../images/background/3120p-1x.webp';
import _3360p_1x from '../../images/background/3360p-1x.webp';
import './Blank.scss';

export function Blank() {
  const [bgLoaded, setBgLoaded] = useState(false);
  return (
    <div className={`blank blank-${bgLoaded ? 'loaded' : 'unloaded'}`} aria-hidden={true}>
      <LogoSvg className="blank_logo" />
      <div className="blank_imageContainer">
        <picture>
          <source media="(max-width: 360px) and (max-height: 360px)"
                  srcSet={`${_360p_1x} 1x, ${_360p_2x} 2x, ${_360p_3x} 3x`} />
          <source media="(max-width: 480px) and (max-height: 480px)"
                  srcSet={`${_480p_1x} 1x, ${_480p_2x} 2x, ${_480p_3x} 3x`} />
          <source media="(max-width: 600px) and (max-height: 600px)"
                  srcSet={`${_600p_1x} 1x, ${_600p_2x} 2x, ${_600p_3x} 3x`} />
          <source media="(max-width: 720px) and (max-height: 720px)"
                  srcSet={`${_720p_1x} 1x, ${_720p_2x} 2x, ${_720p_3x} 3x`} />
          <source media="(max-width: 840px) and (max-height: 840px)"
                  srcSet={`${_840p_1x} 1x, ${_840p_2x} 2x, ${_840p_3x} 3x`} />
          <source media="(max-width: 960px) and (max-height: 960px)"
                  srcSet={`${_960p_1x} 1x, ${_960p_2x} 2x, ${_960p_3x} 3x`} />
          <source media="(max-width: 1080px) and (max-height: 1080px)"
                  srcSet={`${_1080p_1x} 1x, ${_1080p_2x} 2x, ${_1080p_3x} 3x`} />
          <source media="(max-width: 1200px) and (max-height: 1200px)"
                  srcSet={`${_1200p_1x} 1x, ${_1200p_2x} 2x, ${max} 3x`} />
          <source media="(max-width: 1320px) and (max-height: 1320px)"
                  srcSet={`${_1320p_1x} 1x, ${_1320p_2x} 2x, ${max} 3x`} />
          <source media="(max-width: 1440px) and (max-height: 1440px)"
                  srcSet={`${_1440p_1x} 1x, ${_1440p_2x} 2x, ${max} 3x`} />
          <source media="(max-width: 1560px) and (max-height: 1560px)"
                  srcSet={`${_1560p_1x} 1x, ${_1560p_2x} 2x, ${max} 3x`} />
          <source media="(max-width: 1680px) and (max-height: 1680px)"
                  srcSet={`${_1680p_1x} 1x, ${_1680p_2x} 2x, ${max} 3x`} />
          <source media="(max-width: 1800px) and (max-height: 1800px)"
                  srcSet={`${_1800p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 1920px) and (max-height: 1920px)"
                  srcSet={`${_1920p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 2160px) and (max-height: 2160px)"
                  srcSet={`${_2160p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 2400px) and (max-height: 2400px)"
                  srcSet={`${_2400p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 2640px) and (max-height: 2640px)"
                  srcSet={`${_2640p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 2880px) and (max-height: 2880px)"
                  srcSet={`${_2880p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 3120px) and (max-height: 3120px)"
                  srcSet={`${_3120p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source media="(max-width: 3360px) and (max-height: 3360px)"
                  srcSet={`${_3360p_1x} 1x, ${max} 2x, ${max} 3x`} />
          <source srcSet={max} />
          {/* Fallback is used below instead of Max because FF and Safari always download the referenced image */}
          <img src={fallback} alt="" className="blank_image" onLoad={(event) => {
            if (bgLoaded) {
              return;
            }
            // Since the image is large, it can take some time to decode it (which can be seen upon page loading in
            // Firefox), so we "pre-decode" it:
            event.currentTarget.decode().then(() => setBgLoaded(true));
          }} />
        </picture>
      </div>
    </div>
  );
}