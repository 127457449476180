import './index-viewport';
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './components/App/App';
import 'normalize.css/normalize.css'
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app-root')
);