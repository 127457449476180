import {APIRequest, APIRequestType} from '../types/APIRequest';
import {encodeStrict, encodeLanguagesStrict} from './urlEncoder';

export function requestToApiUrl(request: APIRequest): string {
  const getRelativeUrl = (request: APIRequest) => {
    switch (request.type) {
      case APIRequestType.UserLanguages:
        return process.env.REACT_APP_API_USER_LANGUAGES;
      case APIRequestType.Languages:
        return `${process.env.REACT_APP_API_LANGUAGES}/${encodeStrict(request.fragment)}` +
          (request.excludedLanguages.length > 0 ? `?exclude=${encodeLanguagesStrict(request.excludedLanguages)}` : '');
      case APIRequestType.Words:
        return `${process.env.REACT_APP_API_WORDS}/${encodeStrict(request.fragment)}` +
          `?languages=${encodeLanguagesStrict(request.targetLanguages)}`;
      case APIRequestType.Etymology:
        return `${process.env.REACT_APP_API_ETYMOLOGY}` +
          `/${encodeStrict(request.word.language.code)}/${encodeStrict(request.word.word)}`;
      case APIRequestType.Relation:
        return `${process.env.REACT_APP_API_RELATION}` +
          `/${encodeStrict(request.wordA.language.code)}/${encodeStrict(request.wordA.word)}` +
          `/${encodeStrict(request.wordB.language.code)}/${encodeStrict(request.wordB.word)}`;
      case APIRequestType.Cognates:
        return `${process.env.REACT_APP_API_COGNATES}` +
          `/${encodeStrict(request.word.language.code)}/${encodeStrict(request.word.word)}` +
          `?languages=${encodeLanguagesStrict(request.targetLanguages)}`;
    }
  };
  return `${process.env.REACT_APP_API_ROOT}${getRelativeUrl(request)}`;
}