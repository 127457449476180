import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {INPUT_QUICK_EDIT_KEY} from '../../constants/constants';
import {APIRequest} from '../../types/APIRequest';
import {APIResponse, APIResponseStatus} from '../../types/APIResponse';
import {anyCombiningKeyPressed, isButtonClickKey, isEditable} from '../../helpers/domHelper';
import {embedNodes} from '../../helpers/embedders/nodeEmbedder';
import {embedText} from '../../helpers/embedders/textEmbedder';
import {useApi} from '../../hooks/useApi';
import {useUniqueId} from '../../hooks/useUniqueId';
import {SelectOptions} from '../SelectOptions/SelectOptions';
import {DashedText} from '../DashedText/DashedText';
import {Popup, PopupPosition, popupsOpen, PopupType} from '../Popup/Popup';
import {AriaAlert} from '../AriaAlert/AriaAlert';
import {ReactComponent as RemoveSvg} from '../../images/remove.svg';
import {ReactComponent as GoSvg} from '../../images/go.svg';
import {ReactComponent as UndoSvg} from '../../images/undo.svg';
import './Select.scss';

export enum SelectBorderSize {
  Small = 'small',
  Large = 'large'
}

export enum SelectTextSize {
  Smaller = 'smaller',
  Normal = 'normal',
  Larger = 'larger'
}

export enum SelectTextStyle {
  Normal = 'normal',
  Illustration = 'illustration',
  Caption = 'caption'
}

type OptionComposition = {
  size: SelectTextSize,
  style: SelectTextStyle
}[];

type Option = {
  labels: React.ReactNode[],
  key: string,
  data: any
};

type Message = React.ReactElement;

export type SelectContent = Option[] | Message | null;

type QuickEditLabels = {
  label: string,
  text: string
};

type Props = {
  appearance: {
    placeholder: string,
    label: string,
    focusedBorderSize: SelectBorderSize,
    mainTextSize: SelectTextSize,
    optionComposition: OptionComposition,
    maxOptionCount: number,
    hideRestoreText: boolean,
    expandParent: boolean
  },
  state: {
    text: string,
    restore: {
      formatted: React.ReactNode,
      text: string,
      data: any
    } | null,
    valid: boolean,
    button: {
      full: string,
      short: string,
      title: string,
      label: string
    } | null,
    shouldGetFocused: boolean,
    preventScrollOnFocus: boolean,
    disabled?: boolean
  },
  handlers: {
    onTextSet: (text: string) => void,
    onRestoreClicked: (data: any) => void,
    onOptionSelected: (data: any) => void,
    onMarkFocused?: () => void,
    onLoadingStateChanged?: (loading: boolean) => void,
    onActiveStateChanged?: (active: boolean) => void
  },
  converters: {
    queryToRequest: (query: string) => APIRequest | null,
    responseToContent: (response: APIResponse) => SelectContent
  },
  quickEditLabels?: QuickEditLabels
};

let popupHasBeenShown = false;

function isOptions(content: SelectContent): content is Option[] {
  return Array.isArray(content);
}

function formatOption(labels: React.ReactNode[], optionComposition: OptionComposition,
                      onSelected?: (() => void) | undefined): (active: boolean) => React.ReactElement {
  return (active) => (
    <div className={`select_option ${active ? 'select_option-active' : ''}`} onClick={onSelected}>
      {optionComposition.map(({size, style}, index) => (
        <span
          className={`select_optionText select_optionText-style-${style} select_optionText-size-${size}`}
          key={index}
        >
          {labels[index]}
        </span>
      ))}
      <div className="select_optionDecoration" aria-hidden={true}>
        <div className="select_optionFader" />
        <div className="select_optionArrowContainer">
          <GoSvg className="select_optionArrow" />
        </div>
      </div>
    </div>
  );
}

export function Select({
  appearance: {
    placeholder, label, focusedBorderSize, mainTextSize,
    optionComposition, maxOptionCount, hideRestoreText, expandParent
  },
  state: {text, restore, valid, button, shouldGetFocused, preventScrollOnFocus, disabled},
  handlers: {onTextSet, onRestoreClicked, onOptionSelected, onMarkFocused, onLoadingStateChanged, onActiveStateChanged},
  converters: {queryToRequest, responseToContent},
  quickEditLabels
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const focusInput = useCallback((preventScroll: boolean = false) => {
    if (inputRef.current !== null) {
      inputRef.current.focus({preventScroll});
      inputRef.current.select();
    }
  }, []);
  const blurInput = useCallback(() => {
    if (inputRef.current !== null) {
      inputRef.current.blur();
    }
  }, []);
  useLayoutEffect(() => {
    if (shouldGetFocused) {
      focusInput(preventScrollOnFocus);
      if (onMarkFocused) {
        onMarkFocused();
      }
    }
  }, [shouldGetFocused, preventScrollOnFocus, onMarkFocused, focusInput]);
  const [focusedInput, setFocusedInput] = useState(false);
  const [focusedContainer, setFocusedContainer] = useState(false);
  const [hoveredContainer, setHoveredContainer] = useState(false);
  const [activeContainer, setActiveContainer] = useState(false);
  const updateFocusedContainer = useCallback((focused: boolean) => {
    setFocusedContainer(focused);
    if (focused) {
      setActiveContainer(true);
    } else if (!hoveredContainer) {
      setActiveContainer(false);
    }
  }, [hoveredContainer]);
  const updateHoveredContainer = useCallback((hovered: boolean) => {
    setHoveredContainer(hovered);
    if (!hovered && !focusedContainer) {
      setActiveContainer(false);
    }
  }, [focusedContainer]);
  const [keydownHandlers, setKeydownHandlers] = useState<((event: React.KeyboardEvent) => void)[]>([]);
  const [keydownSubscribe, keydownUnsubscribe] = useMemo(() => [
    (handler: (event: React.KeyboardEvent) => void) =>
      setKeydownHandlers((handlers) => [...handlers, handler]),
    (handler: (event: React.KeyboardEvent) => void) =>
      setKeydownHandlers((handlers) => handlers.filter((h) => h !== handler))
  ], []);
  const optionSelect = useCallback((data: any) => {
    blurInput();
    setActiveContainer(false);
    onOptionSelected(data);
  }, [blurInput, onOptionSelected]);
  const request = useMemo(() => queryToRequest(text), [queryToRequest, text]);
  const response = useApi(request);
  const content: SelectContent | null = useMemo(
    () => response === null ? null : responseToContent(response),
    [response, responseToContent]
  );
  const [lastOptions, setLastOptions] = useState<Option[] | null>(null);
  const effectiveContent =
    !isOptions(content) && response?.status === APIResponseStatus.Loading && !response.slow && lastOptions !== null
      ? lastOptions
      : content;
  if (isOptions(content)) {
    if (lastOptions !== content) {
      setLastOptions(content);
    }
  } else if (response?.status !== APIResponseStatus.Loading || response.slow) {
    if (lastOptions !== null) {
      setLastOptions(null);
    }
  }
  const options = useMemo(() => isOptions(effectiveContent) ? effectiveContent.map(({labels, key, data}) => ({
    content: formatOption(labels, optionComposition, () => onOptionSelected(data)),
    key,
    data
  })) : [], [effectiveContent, onOptionSelected, optionComposition]);

  const optionsKeyAndSelectedId = useUniqueId(effectiveContent); // Active option needs to be reset on update;
  // without updating the selected option's id, NVDA re-announces the combobox on each new key press

  let displayedData: {formattedContent: React.ReactNode, hasOptions: boolean};
  const emptyOption = (
    <div className="select_emptyOption">
      {formatOption(optionComposition.map(() => '-'), optionComposition)(false)}
    </div>
  );
  const listboxId = useUniqueId();
  if (!activeContainer || effectiveContent === null) {
    displayedData = {formattedContent: null, hasOptions: false};
  } else if (isOptions(effectiveContent)) {
    displayedData = effectiveContent.length === 0
      ? {formattedContent: null, hasOptions: false}
      : {formattedContent : (
          <SelectOptions
            key={optionsKeyAndSelectedId}
            listboxId={listboxId}
            selectedOptionId={optionsKeyAndSelectedId}
            options={options}
            fader={<div className="select_optionsFader">{emptyOption}</div>}
            displayedCount={maxOptionCount}
            keydownSubscribe={keydownSubscribe}
            keydownUnsubscribe={keydownUnsubscribe}
            onSelected={optionSelect}
          />
        ), hasOptions: true};
  } else {
    displayedData = {formattedContent: (
      <div className="select_messageContainer">
        <div className="select_messageExpander" aria-hidden={true}>
          {emptyOption}
        </div>
        <div className="select_message">
          <div className="select_textAndLinkWrapper">
            {effectiveContent}
            <AriaAlert message={effectiveContent} />
          </div>
        </div>
      </div>
    ), hasOptions: false};
  }
  useEffect(() => {
    const handler = (event: Event) => {
      if ((event as KeyboardEvent).key === 'Escape' && displayedData.formattedContent !== null) {
        blurInput();
        setActiveContainer(false);
      }
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [displayedData.formattedContent, blurInput]);
  useEffect(() => {
    // This is needed for the following reasons:
    // - the field could gain focus programmatically, so onMouseEnter doesn't happen in time, and on mobile devices
    //   might not happen even when the user taps on an option
    // - on mobile devices onMouseLeave for some reason doesn't fire after tapping on the button
    //   (language name) and then tapping somewhere outside of the container
    const handler = (event: Event) => {
      const target = (event as TouchEvent).target;
      if (containerRef.current !== null && target instanceof Element) {
        updateHoveredContainer(containerRef.current.contains(target));
      }
    };
    document.addEventListener('touchstart', handler);
    return () => document.removeEventListener('touchstart', handler);
  }, [updateHoveredContainer]);
  const [loading, setLoading] = useState(false);
  const actualLoading = response?.status === APIResponseStatus.Loading;
  useLayoutEffect(() => {
    if (loading !== actualLoading) {
      setLoading(actualLoading);
      if (onLoadingStateChanged) {
        onLoadingStateChanged(actualLoading);
      }
    }
  }, [actualLoading, loading, onLoadingStateChanged]);
  const [reportedActive, setReportedActive] = useState(false);
  useLayoutEffect(() => {
    if (reportedActive !== activeContainer) {
      setReportedActive(activeContainer);
      if (onActiveStateChanged) {
        onActiveStateChanged(activeContainer);
      }
    }
  }, [activeContainer, reportedActive, onActiveStateChanged]);
  const [popupShown, setPopupShown] = useState<QuickEditLabels | null>(null);
  const showPopup = useCallback((labels) => setPopupShown(labels), []);
  const hidePopup = useCallback(() => setPopupShown(null), []);
  const popupPositionPreference = useMemo(() => [PopupPosition.Top, PopupPosition.Bottom], []);
  useEffect(() => {
    if (!quickEditLabels) {
      return;
    }
    const onKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement !== null && isEditable(document.activeElement)) {
        return;
      }
      if (event.code === INPUT_QUICK_EDIT_KEY.code && !anyCombiningKeyPressed(event)) {
        event.preventDefault();
        focusInput();
      } else if (!popupsOpen() /* We don't want them to overlap */ && !popupHasBeenShown // Showing the popup just once
        && !anyCombiningKeyPressed(event, false)
        && ((event.key.length === 1 && event.code !== 'Space') || ['Backspace', 'Delete'].includes(event.code))) {
        popupHasBeenShown = true;
        showPopup(quickEditLabels);
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [quickEditLabels, focusInput, showPopup]);
  const [lastKey, setLastKey] = useState<string | null>(null);
  const restoreRestoreLabelId = useUniqueId();
  const restoreTextLabelId = useUniqueId();
  type CancelVariableAttributes = {
    title: string,
    'aria-label': string,
    onClick: () => void,
    onKeyDown: (event: React.KeyboardEvent) => void
  };
  const getCancelVariableAttributes = (type: 'close' | 'clear'): CancelVariableAttributes => {
    const localization = LOCALIZATION.genericInputs.cancel[type];
    const action = {
      close: () => {
        blurInput();
        setActiveContainer(false);
      },
      clear: () => {
        onTextSet('');
        focusInput();
      }
    }[type];
    return {
      title: localization.title,
      'aria-label': localization.label,
      onClick: () => action(),
      onKeyDown: (event: React.KeyboardEvent) => isButtonClickKey(event) && action()
    };
  };
  return (
    <div
      className={`select select-size-${mainTextSize} select-focusedBorderSize-${focusedBorderSize}` +
        `${disabled ? ' select-disabled' : ''}` +
        `${valid || text.trim() === '' || activeContainer ? '' : ' select-invalid'}` +
        `${focusedInput ? ' select-focusedInput' : ''}` +
        `${hideRestoreText ? ' select-hideRestoreText' : ''}` +
        ` select-vertical-${expandParent ? 'expanding' : 'fixed'}`}
       onFocus={() => updateFocusedContainer(true)}
       onBlur={(event) => {
         if (!(event.relatedTarget instanceof Element) || !event.currentTarget.contains(event.relatedTarget)) {
           // If one omits checking the condition, links inside the container would disappear before getting focus,
           // and the user would be unable to select a message's contents with a mouse
           updateFocusedContainer(false);
         }
         if ((event.relatedTarget instanceof Element) && !event.currentTarget.contains(event.relatedTarget)) {
           setActiveContainer(false); // So that two controls cannot be active at the same time
         }
       }}
       onMouseEnter={() => updateHoveredContainer(true)}
       onMouseLeave={() => updateHoveredContainer(false)}
       ref={containerRef}
    >
      <div className="select_container">
        <div className="select_inputArea">
          <input
            type="text"
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            spellCheck="false"
            className="select_input"
            value={text}
            placeholder={placeholder}
            ref={inputRef}
            onChange={(event) => onTextSet(event.target.value)}
            onKeyDown={(event) => {
              keydownHandlers.forEach((handler) => handler(event));
              setLastKey(event.key);
            }}
            onFocus={() => setFocusedInput(true)}
            onBlur={() => setFocusedInput(false)}
            disabled={disabled ?? false}
            role="combobox"
            aria-label={label}
            aria-autocomplete="list"
            aria-expanded={displayedData.hasOptions}
            aria-controls={displayedData.hasOptions ? listboxId : undefined}
            aria-activedescendant={
              displayedData.hasOptions && !['ArrowLeft', 'ArrowRight'].includes(lastKey ?? '')
                ? optionsKeyAndSelectedId
                : undefined
            }
            lang=""
            translate="no"
          />
          {
            text !== '' && (activeContainer || button === null) &&
            <div
              className="select_cancel"
              role="button"
              {...getCancelVariableAttributes(activeContainer && text.trim() !== '' ? 'close' : 'clear')}
            >
              <div className="select_cancelButton">
                <RemoveSvg className="select_cancelIcon" />
              </div>
            </div>
          }
          {
            text !== '' && !(activeContainer || button === null) &&
            <div
              className="select_button"
              onFocus={() => focusInput()}
              onClick={() => focusInput()}
              onKeyDown={(event) => {
                if (isButtonClickKey(event)) {
                  focusInput();
                }
              }}
              role="button"
              aria-label={button.label}
              title={button.title}
            >
              <DashedText className="select_buttonText select_buttonText-full" outline={true} truncate={true}>
                {button.full}
              </DashedText>
              <DashedText className="select_buttonText select_buttonText-short" outline={true} truncate={true}>
                {button.short}
              </DashedText>
            </div>
          }
          {
            text === '' && restore !== null &&
            <button
              className="select_restore"
              onClick={() => {
                setActiveContainer(false);
                onRestoreClicked(restore.data);
              }}
              title={embedText(LOCALIZATION.genericInputs.restore.title, restore.text)}
              aria-labelledby={embedText(LOCALIZATION.genericInputs.restore.label, (_, name) => (
                {
                  restore: restoreRestoreLabelId,
                  text: restoreTextLabelId
                }[name as 'restore' | 'text']
              ))}
            >
              <span id={restoreRestoreLabelId} hidden>
                {embedText(LOCALIZATION.genericInputs.restore.label, (text, name) => name === 'restore' ? text : '')}
              </span>
              <UndoSvg className="select_restoreIcon" />
              <DashedText className="select_restoreText" outline={true} truncate={true}>
                <span id={restoreTextLabelId}>{restore.formatted}</span>
              </DashedText>
            </button>
          }
        </div>
        {displayedData.formattedContent && (
          <div className="select_contentArea">
            {displayedData.formattedContent}
          </div>
        )}
      </div>
      {popupShown && (
        <Popup
          type={PopupType.Tooltip}
          label={popupShown.label}
          shrinkToContent={true}
          anchor={containerRef}
          shouldStayOnAnchorInteraction={false}
          positionPreference={popupPositionPreference}
          onDismissed={hidePopup}
        >
          <AriaAlert message={popupShown.label} />
          {embedNodes(popupShown.text, (
            <kbd className="select_hintKey">{INPUT_QUICK_EDIT_KEY.display}</kbd>
          ))}
        </Popup>
      )}
    </div>
  );
}