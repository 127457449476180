import React from 'react';
import {Word} from '../types/Word';
import {SimpleWordContainer} from '../components/SimpleWordContainer/SimpleWordContainer';

const reconstructedPrefix = '*';

export function getWordPrefix(word: Word): string {
  return word.reconstructed ? reconstructedPrefix : '';
}

export function getWordWithPrefix(word: Word): React.ReactNode {
  return (
    <>
      {getWordPrefix(word)}
      <SimpleWordContainer word={word} />
    </>
  );
}

export function serializeWord(word: Word): string {
  return [word.language.code, getWordPrefix(word) + word.word].join(':');
}

export function wordsIdentical(a: Word, b: Word): boolean {
  return a.word === b.word && !!a.reconstructed === !!b.reconstructed && a.language.code === b.language.code;
}