import React, {useContext, useEffect} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {Section} from '../../types/Section';
import {Language} from '../../types/Language';
import {APIRequestType} from '../../types/APIRequest';
import {Location} from '../../types/Location';
import {State, StateContext} from '../../context/StateContext';
import {UpdateContext} from '../../context/UpdateContext';
import {locationToUrl} from '../../helpers/locationToUrl';
import {encodeLanguagesStrict} from '../../helpers/urlEncoder';
import {useActiveApiLocation} from '../../hooks/useActiveApiLocation';
import {LanguagesOfInterest} from '../LanguagesOfInterest/LanguagesOfInterest';
import {SelectWord} from '../SelectWord/SelectWord';
import {CustomLanguages, CustomLanguagesSelection} from '../CustomLanguages/CustomLanguages';
import './MainHeader.scss';

const sectionClassNames: {
  [key: string]: string
} = {
  [Section.Etymology]: 'etymology',
  [Section.Relation]: 'relation',
  [Section.Cognates]: 'cognates'
};

function GetCustomLanguagesSelectionFromRequest(
  languages: Language[], languagesOfInterest: Language[], extraLanguage: Language | null, extraLanguageEnabled: boolean
): CustomLanguagesSelection {
  const equal = (a: Language[], b: Language[]) => encodeLanguagesStrict(a) === encodeLanguagesStrict(b);
  if (extraLanguageEnabled) {
    return equal(languages, extraLanguage === null ? [] : [extraLanguage])
      ? CustomLanguagesSelection.ExtraLanguage
      : CustomLanguagesSelection.None;
  } else {
    return equal(languages, languagesOfInterest)
      ? CustomLanguagesSelection.LanguagesOfInterest
      : CustomLanguagesSelection.None;
  }
}

function GetCustomLanguagesSelection(state: State): CustomLanguagesSelection {
  const api = state.api ?? state.pendingApi; // Including pendingApi is required to avoid flashing buttons on page load
  if (state.touched || api?.request.type !== APIRequestType.Cognates) {
    return state.extraLanguage.enabled
      ? CustomLanguagesSelection.ExtraLanguage
      : CustomLanguagesSelection.LanguagesOfInterest;
  } else {
    return GetCustomLanguagesSelectionFromRequest(
      api.request.targetLanguages,
      state.languagesOfInterest.languages,
      state.extraLanguage.language,
      state.extraLanguage.enabled
    );
  }
}

function constructLocation(state: State): Location | null {
  if (!state.touched) {
    return null;
  }
  switch (state.section) {
    case Section.Etymology:
      return state.inputA.word === null ? null : {
        section: Section.Etymology,
        query: {
          word: state.inputA.word
        }
      };
    case Section.Relation:
      return state.inputA.word === null || state.inputB.word === null ? null : {
        section: Section.Relation,
        query: {
          wordA: state.inputA.word,
          wordB: state.inputB.word
        }
      };
    case Section.Cognates:
      return state.inputA.word === null ? null : {
        section: Section.Cognates,
        query: {
          word: state.inputA.word,
          targetLanguages: state.extraLanguage.language !== null && state.extraLanguage.enabled
            ? [state.extraLanguage.language]
            : state.languagesOfInterest.languages
        }
      };
    default:
      return null;
  }
}

export function MainHeader() {
  const state = useContext(StateContext)!;
  const update = useContext(UpdateContext);
  const activeLocation = useActiveApiLocation(state);
  const location = constructLocation(state);
  useEffect(() => {
    if (location !== null && (activeLocation === null || locationToUrl(location) !== locationToUrl(activeLocation))) {
      update.updateToLocation(location);
    }
  }, [location, activeLocation, update]);
  const showSecondSelect = state.section === Section.Relation;
  return (
    <div className={`mainHeader mainHeader-${sectionClassNames[state.section] ?? 'none'}`}>
      <div className="mainHeader_languagesOfInterest">
        <LanguagesOfInterest state={state.languagesOfInterest}
                             onLanguagesUpdated={update.setLanguagesOfInterest} />
      </div>
      <div className="mainHeader_word mainHeader_word-first">
        <SelectWord state={state.inputA}
                    placeholder={LOCALIZATION.wordInputs.placeholders.first}
                    label={LOCALIZATION.wordInputs.labels[showSecondSelect ? 'first' : 'only']}
                    languagesOfInterest={state.languagesOfInterest.languages}
                    onSelected={update.setInputAWord}
                    onTextSet={update.setInputAText}
                    onMarkFocused={update.markInputAFocused}
                    catchQuickEditKey={true}
        />
      </div>
      <div className="mainHeader_word mainHeader_word-second" hidden={!showSecondSelect}>
        <SelectWord state={state.inputB}
                    placeholder={LOCALIZATION.wordInputs.placeholders.second}
                    label={LOCALIZATION.wordInputs.labels.second}
                    languagesOfInterest={state.languagesOfInterest.languages}
                    onSelected={update.setInputBWord}
                    onTextSet={update.setInputBText}
                    onMarkFocused={update.markInputBFocused}
        />
      </div>
      {state.section === Section.Cognates ? (
        <div className="mainHeader_customLanguages">
          <CustomLanguages state={state.extraLanguage}
                           selection={GetCustomLanguagesSelection(state)}
                           onLanguageChanged={update.setExtraLanguage}
                           onSwitched={update.setExtraLanguageEnabled} />
        </div>
      ): null}
    </div>
  );
}