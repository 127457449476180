import React, {useContext} from 'react';
import {Location} from '../../types/Location';
import {UpdateContext} from '../../context/UpdateContext';
import {locationToUrl} from '../../helpers/locationToUrl';
import {isNewTabClick} from '../../helpers/domHelper';

type Props = {
  to: Location,
  subrequest?: string | null,
  focusInput?: boolean,
  shouldUpdateUrl?: () => boolean,
  children: React.ReactNode,
  className?: string,
  title?: string,
  'aria-label'?: string
};

export function Link({
  to, subrequest, focusInput, shouldUpdateUrl, children, className, title, 'aria-label': label
}: Props) {
  const update = useContext(UpdateContext);
  return (
    <a href={locationToUrl(to, subrequest ?? '')} className={className ?? ''} onClick={(event) => {
      if (isNewTabClick(event)) {
        return;
      }
      event.preventDefault();
      update.updateToLocation(to, subrequest ?? '', focusInput ?? false, shouldUpdateUrl ? shouldUpdateUrl() : true);
    }} title={title} aria-label={label}>
      {children}
    </a>
  );
}