import {Location} from '../types/Location';
import {APIRequest, APIRequestType} from '../types/APIRequest';
import {Section} from '../types/Section';

export function locationToRequest(location: Location): APIRequest | null {
  switch (location.section) {
    case Section.Etymology: {
      const word = location.query?.word;
      return word ? {
        type: APIRequestType.Etymology,
        word
      } : null;
    }
    case Section.Relation: {
      const wordA = location.query?.wordA;
      const wordB = location.query?.wordB;
      return wordA && wordB ? {
        type: APIRequestType.Relation,
        wordA,
        wordB
      } : null;
    }
    case Section.Cognates: {
      const word = location.query?.word;
      const targetLanguages = location.query?.targetLanguages;
      return word && targetLanguages ? {
        type: APIRequestType.Cognates,
        word,
        targetLanguages
      } : null;
    }
    default:
      return null;
  }
}