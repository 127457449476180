import React from 'react';
import {Word} from '../types/Word';
import {WordWithMeasurements} from '../types/WordGeometry';
import {Language} from '../types/Language';
import {setExtraLanguage, setExtraLanguageEnabled, setLanguagesOfInterest} from '../helpers/languagesOfInterest';
import {InputState, State} from './StateContext';
import {APIResponse} from '../types/APIResponse';
import {Location} from '../types/Location';
import {Section} from '../types/Section';
import {locationToUrl} from '../helpers/locationToUrl';
import {locationToRequest} from '../helpers/locationToRequest';
import {extractAFromLocation, extractBFromLocation} from '../helpers/wordExtractor';

export type Update = {
  setUrl: (url: string) => void,
  resetHash: (hash: string) => void,
  pickPendingApi: (subrequest: string | null, response: APIResponse<WordWithMeasurements>) => void,
  setInputAWord: (word: Word) => void,
  setInputAText: (text: string) => void,
  setInputAFocused: () => void,
  markInputAFocused: () => void,
  setExtraLanguage: (language: Language) => void,
  setExtraLanguageEnabled: (enabled: boolean) => void,
  setInputBWord: (word: Word) => void,
  setInputBText: (text: string) => void,
  setInputBFocused: () => void,
  markInputBFocused: () => void,
  setLanguagesOfInterest: (languages: Language[]) => void,
  updateInputALanguage: (language: Language) => void,
  updateInputBLanguage: (language: Language) => void,
  updateResponse: (response: APIResponse<WordWithMeasurements>) => void,
  updateSubrequest: (subrequest: string | null) => void,
  updateToLocation: (location: Location, subrequest?: string, focusInput?: boolean, updateUrl?: boolean) => void,
  setIncompleteLanguage: (text: string | null) => void,
  setTopCollapsed: (collapsed: boolean) => void
};

export const UpdateContext = React.createContext<Update>(null!);

export function getUpdater(setUrl: (url: string) => void, resetHash: (hash: string) => void,
                           setState: (callback: (state: State) => State) => void): Update {
  const setInputWord = (first: boolean, word: Word) => {
    setState((state) => ({
      ...state,
      [first ? 'inputA' : 'inputB']: {
        text: word.word,
        word,
        lastWord: null,
        shouldGetFocused: false
      },
      ...(first && state.section === Section.Relation && state.inputB.word === null ? {
        inputB: {
          ...state.inputB,
          shouldGetFocused: true
        }
      } : {}),
      touched: true
    }));
  };
  const setInputText = (first: boolean, text: string) => {
    setState((state) => ({
      ...state,
      [first ? 'inputA' : 'inputB']: {
        text,
        word: null,
        lastWord: null,
        shouldGetFocused: false
      },
      touched: true
    }));
  };
  const setInputFocused = (first: boolean, shouldGetFocused: boolean) => {
    setState((state) => ({
      ...state,
      [first ? 'inputA' : 'inputB']: {
        ...state[first ? 'inputA' : 'inputB'],
        shouldGetFocused
      },
      topCollapsed: shouldGetFocused ? false : state.topCollapsed
    }));
  };
  const updateInputLanguage = (first: boolean, language: Language) => {
    setState((state) => ({
      ...state,
      [first ? 'inputA' : 'inputB']: {
        ...state[first ? 'inputA' : 'inputB'],
        word: state[first ? 'inputA' : 'inputB'].word === null ? null : {
          ...state[first ? 'inputA' : 'inputB'].word,
          language
        }
      }
    }));
  };

  return {
    setUrl: (url) => {
      setUrl(url);
    },
    resetHash: (hash) => {
      resetHash(hash);
    },
    pickPendingApi: (subrequest, response) => {
      setState((state) => ({
        ...state,
        pendingApi: null,
        api: state.pendingApi === null ? null : {
          ...state.pendingApi,
          subrequest,
          response
        }
      }));
    },
    setExtraLanguage: (language) => {
      setExtraLanguage(language);
      setExtraLanguageEnabled(true);
      setState((state) => ({
        ...state,
        extraLanguage: {
          language,
          enabled: true
        },
        touched: true
      }));
    },
    setExtraLanguageEnabled: (enabled) => {
      setExtraLanguageEnabled(enabled);
      setState((state) => ({
        ...state,
        extraLanguage: {
          language: state.extraLanguage.language,
          enabled: enabled && state.extraLanguage.language !== null
        },
        touched: true
      }));
    },
    setInputAWord: (word) => setInputWord(true, word),
    setInputAText: (text) => setInputText(true, text),
    setInputAFocused: () => setInputFocused(true, true),
    markInputAFocused: () => setInputFocused(true, false),
    setInputBWord: (word) => setInputWord(false, word),
    setInputBText: (text) => setInputText(false, text),
    setInputBFocused: () => setInputFocused(false, true),
    markInputBFocused: () => setInputFocused(false, false),
    setLanguagesOfInterest: (languages) => {
      setLanguagesOfInterest(languages);
      setState((state) => ({
        ...state,
        languagesOfInterest: {
          languages,
          initialized: true
        }
      }));
    },
    updateInputALanguage: (language) => updateInputLanguage(true, language),
    updateInputBLanguage: (language) => updateInputLanguage(false, language),
    updateResponse: (response) => {
      setState((state) => ({
        ...state,
        api: state.api === null ? null : {
          ...state.api,
          response
        }
      }));
    },
    updateSubrequest: (subrequest) => {
      setState((state) => ({
        ...state,
        api: state.api === null ? null : {
          ...state.api,
          subrequest
        }
      }));
    },
    updateToLocation: (location, subrequest: string = '', focusInput: boolean = false, updateUrl: boolean = true) => {
      const updateInputState = (word: Word | null, inputState: InputState, shouldGetFocused: boolean) => {
        return word !== null ? {
          text: word.word,
          word,
          lastWord: null,
          shouldGetFocused
        } : {
          text: '',
          word: null,
          lastWord: inputState.word ?? inputState.lastWord,
          shouldGetFocused
        };
      };
      setState((state) => ({
        ...state,
        section: location.section,
        api: location.section === Section.About ? null : state.api,
        inputA: updateInputState(extractAFromLocation(location), state.inputA, focusInput),
        inputB: updateInputState(extractBFromLocation(location), state.inputB, false),
        touched: false,
        pendingApi: ((pendingRequest) => pendingRequest === null ? state.pendingApi : {
          request: pendingRequest,
          location
        })(locationToRequest(location)),
        topCollapsed: false
      }));
      if (updateUrl) {
        setUrl(locationToUrl(location, subrequest));
      }
    },
    setIncompleteLanguage: (text) => setState((state) => ({
      ...state,
      incompleteLanguage: text
    })),
    setTopCollapsed: (collapsed) => setState((state) => ({
      ...state,
      topCollapsed: collapsed
    }))
  };
}