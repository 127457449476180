// This helper normalizes pinch-to-zoom 'wheel' + ctrlKey browser events.

import {Dimensions, Zoom} from '../types/Geometry';
import {getRelativeCoordinates} from './domHelper';

const inputEventName = 'wheel';
const outputEventName = '__custom_wheelZoom';

const deltaModeMultipliers = [1, 15, 450]; // Pixels, lines, pages respectively (the values are taken from Google Maps)

export type Options = {
  maxStrength: number,
  maxStrengthThrottle: number // in milliseconds
};

export type Details = {
  direction: Zoom,
  strength: number,
  anchor: Dimensions
};

type EventInfo = {
  direction: Zoom,
  strength: number,
  timestamp: number
};

const optionsByElement = new Map<HTMLElement, Options>();
const historyByElement = new Map<HTMLElement, EventInfo>();

function getCurrentTime(): number {
  return new Date().getTime();
}

function getEventInfo(event: WheelEvent, timestamp: number, options: Options): EventInfo {
  const deltaY = event.deltaY * (deltaModeMultipliers[event.deltaMode] ?? 1);
  return {
    direction: deltaY < 0 ? Zoom.In : Zoom.Out,
    strength: Math.min(Math.abs(deltaY), options.maxStrength),
    timestamp: timestamp
  };
}

function shouldFire(eventInfo: EventInfo, lastEventInfo: EventInfo | null, options: Options): boolean {
  return lastEventInfo === null
      || eventInfo.direction !== lastEventInfo.direction
      || Math.min(eventInfo.strength, lastEventInfo.strength) < options.maxStrength
      || lastEventInfo.timestamp < getCurrentTime() - options.maxStrengthThrottle;
}

function onWheel(event: WheelEvent): void {
  if (!event.ctrlKey) {
    return;
  }
  event.preventDefault();
  const element = event.currentTarget;
  if (!(element instanceof HTMLElement)) {
    return;
  }
  const options = optionsByElement.get(element);
  if (!options) { // Shouldn't happen
    return;
  }
  const eventInfo = getEventInfo(event, getCurrentTime(), options);
  const lastEventInfo = historyByElement.get(element) ?? null;
  historyByElement.set(element, eventInfo);
  if (shouldFire(eventInfo, lastEventInfo, options)) {
    element.dispatchEvent(new CustomEvent<Details>(outputEventName, {detail: {
      direction: eventInfo.direction,
      strength: eventInfo.strength,
      anchor: getRelativeCoordinates(event, element)
    }}));
  }
}

export function attachWheelZoom(element: HTMLElement, options: Options): string {
  optionsByElement.set(element, options);
  element.addEventListener(inputEventName, onWheel);
  return outputEventName;
}

export function detachWheelZoom(element: HTMLElement): void {
  element.removeEventListener(inputEventName, onWheel);
  optionsByElement.delete(element);
  if (historyByElement.has(element)) {
    historyByElement.delete(element);
  }
}