import {useEffect} from 'react';
import {getCurrentHashDecoded} from '../helpers/hashHelper';
import {useForceUpdate} from './useForceUpdate';

const eventName = 'hashchange';

export function useHash(): string | null {
  const hash = getCurrentHashDecoded();
  const forceUpdate = useForceUpdate();
  const onHashChanged = () => forceUpdate();
  useEffect(() => {
    window.addEventListener(eventName, onHashChanged);
    return () => {
      window.removeEventListener(eventName, onHashChanged);
    };
  });
  return hash;
}