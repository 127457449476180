export enum Font {
  Cursive = 'cursive'
}

export enum FontCoverage {
  None = 'none',
  WhiteSpaceOnly = 'white_space_only',
  Partial = 'partial',
  Full = 'full'
}

const presets = {
  [Font.Cursive]: [
    // latin
    [0, 0], [13, 13], [32, 126], [160, 255], [305, 305], [338, 339], [700, 700], [710, 710], [730, 730], [732, 732],
    [8211, 8212], [8216, 8218], [8220, 8222], [8226, 8226], [8230, 8230], [8249, 8250], [8260, 8260], [8308, 8308],
    [8364, 8364], [8482, 8482], [8722, 8722], [8725, 8725],

    // latin-ext
    [256, 304], [308, 337], [340, 382], [399, 399], [402, 402], [416, 417], [431, 432], [452, 460], [486, 487],
    [490, 491], [506, 539], [554, 557], [560, 563], [567, 567], [601, 601], [7808, 7813], [7838, 7838], [7922, 7929],
    [8224, 8224], [8353, 8353], [8355, 8356], [8358, 8359], [8361, 8361], [8363, 8363], [8365, 8366], [8369, 8370],
    [8372, 8373], [8376, 8378], [8380, 8381],

    // cyrillic
    [1024, 1119], [1168, 1169], [1200, 1201], [8470, 8470],

    // cyrillic-ext
    [1122, 1123], [1130, 1131], [1138, 1141], [1162, 1167], [1170, 1189], [1192, 1199], [1202, 1279], [1296, 1299],
    [1306, 1309], [1316, 1321], [1326, 1327], [8372, 8372],

    // vietnamese
    [258, 259], [272, 273], [296, 297], [360, 361], [416, 417], [431, 432], [7840, 7929], [8363, 8363]
  ]
};

function isInFont(character: string, font: Font): boolean {
  const code = character.charCodeAt(0);
  const ranges = presets.hasOwnProperty(font) ? presets[font] : [];
  return ranges.some(([min, max]) => code >= min && code <= max);
}

export function getFontCoverage(text: string, font: Font): FontCoverage {
  let foundCharsIn = false;
  let foundSpaceIn = false;
  let foundOut = false;
  for (const character of text.split('')) {
    if (isInFont(character, font)) {
      if (character.trim() === '') {
        foundSpaceIn = true;
      } else {
        foundCharsIn = true;
      }
    } else {
      foundOut = true;
    }
    if (foundCharsIn && foundOut) {
      return FontCoverage.Partial;
    }
  }
  if (foundOut) {
    return foundSpaceIn ? FontCoverage.WhiteSpaceOnly : FontCoverage.None;
  } else {
    return FontCoverage.Full;
  }
}