import React from 'react';
import {ReactComponent as LogoSvg} from '../../images/logo.svg';
import './AboutHeader.scss';

export function AboutHeader() {
  return (
    <div className="aboutHeader">
      <LogoSvg className="aboutHeader_logo" aria-hidden={true} />
    </div>
  );
}