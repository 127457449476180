import React, {useLayoutEffect, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {SCROLL_CONTAINER} from '../../constants/constants';
import {Dimensions, unsetDimensions, Zoom} from '../../types/Geometry';
import {attachWheelZoom, detachWheelZoom, Details as WheelZoomDetails} from '../../helpers/wheelZoom';
import {clamp, dimensionsEqual, getDistance, getMiddle} from '../../helpers/geometryHelper';
import {getRelativeCoordinates, isInsideTag, pixel} from '../../helpers/domHelper';
import {useUrlControllerData} from '../../hooks/useUrlController';
import {ReactComponent as ZoomInSvg} from '../../images/zoom-in.svg';
import {ReactComponent as ZoomOutSvg} from '../../images/zoom-out.svg';
import './ScrollContainer.scss';

const cursorOptions = {
  doubleClickScaleMultiplier: SCROLL_CONTAINER.defaultZoomMultiplier,
  cannotDragCursor: 'default',
  canDragCursor: 'grab',
  interactiveCursor: 'pointer',
  selectableTextCursor: 'text',
  draggingCursor: 'grabbing',
  textTags: ['text', 'span'],
  interactiveTags: ['a', 'path']
};

const buttonZoomMultiplier = SCROLL_CONTAINER.defaultZoomMultiplier;

const containerClassName = `scrollContainer_scroller`;
const containerPinchZoomedClassName = `${containerClassName}-pinchZoomed`;

const scaleEventName = '__custom_scale';

const statePartsSeparator = ',';

type ExternalEventListener = () => void;

type Props = {
  children: React.ReactNode,
  initialScrollRatio: Dimensions,
  initialScale?: number,
  onScroll?: ExternalEventListener,
  onScale?: ExternalEventListener
};

export type ScrollContainerProps = Props;

type ScaleSetter = (scale: React.MutableRefObject<number>, scaleMultiplier: number, anchor?: Dimensions,
                    scrollRatio?: Dimensions) => void;
type ScrollRatioSetter = (ratio: Dimensions) => void;
type Cleaner = () => void;

enum TargetType {
  General = 'general',
  Interactive = 'interactive',
  SelectableText = 'selectable_text'
}

function collectCleaners(...cleaners: Cleaner[]) {
  return () => cleaners.reverse().forEach((cleaner) => cleaner());
}

function setScrollRatio(element: HTMLElement, ratio: Dimensions): void {
  const ratioToOffset = (clientSize: number, scrollSize: number, ratio: number) =>
    Math.max(0, scrollSize - clientSize) * ratio;
  element.scrollTo({
    left: ratioToOffset(element.clientWidth, element.scrollWidth, ratio.x),
    top: ratioToOffset(element.clientHeight, element.scrollHeight, ratio.y)
  });
}

function getScrollRatio(element: HTMLElement, defaultRatio: Dimensions): Dimensions {
  const getScrollRatioAlongDimension =
    (clientSize: number, scrollSize: number, scrollPosition: number, defaultValue: number) =>
      scrollSize <= clientSize ? defaultValue : scrollPosition / (scrollSize - clientSize);
  return {
    x: getScrollRatioAlongDimension(element.clientWidth, element.scrollWidth, element.scrollLeft, defaultRatio.x),
    y: getScrollRatioAlongDimension(element.clientHeight, element.scrollHeight, element.scrollTop, defaultRatio.y)
  };
}

function getContentPosition(container: HTMLElement, content: HTMLElement): Dimensions {
  const getPositionAlongDimension = (contentSize: number, containerScrollSize: number, containerScrollOffset: number) =>
    (containerScrollSize - contentSize) / 2 - containerScrollOffset;
  return {
    x: getPositionAlongDimension(content.offsetWidth, container.scrollWidth, container.scrollLeft),
    y: getPositionAlongDimension(content.offsetHeight, container.scrollHeight, container.scrollTop),
  };
}

function anchorToScrollRatio(container: HTMLElement, content: HTMLElement, anchor: Dimensions): Dimensions {
  const contentPosition = getContentPosition(container, content);
  const getRatioAlongDimension = (anchor: number, position: number, size: number) => (anchor - position) / size;
  return {
    x: getRatioAlongDimension(anchor.x, contentPosition.x, content.offsetWidth),
    y: getRatioAlongDimension(anchor.y, contentPosition.y, content.offsetHeight)
  };
}

function scrollRatioToPoint(content: HTMLElement, ratio: Dimensions): Dimensions {
  return {
    x: content.offsetWidth * ratio.x,
    y: content.offsetHeight * ratio.y
  };
}

function scrollRatioAndAnchorToScrollPosition(container: HTMLElement, content: HTMLElement,
                                              ratio: Dimensions, anchor: Dimensions): Dimensions {
  const pointAtRatio = scrollRatioToPoint(content, ratio);
  const getPositionAlongDirection = (point: number, anchor: number, contentSize: number, containerScrollSize: number) =>
    point - anchor + (containerScrollSize - contentSize) / 2;
  return {
    x: getPositionAlongDirection(pointAtRatio.x, anchor.x, content.offsetWidth, container.scrollWidth),
    y: getPositionAlongDirection(pointAtRatio.y, anchor.y, content.offsetHeight, container.scrollHeight)
  };
}

function getContainerMiddle(container: HTMLElement): Dimensions {
  return {
    x: container.offsetWidth / 2,
    y: container.offsetHeight / 2
  };
}

function setScale(container: HTMLElement, content: HTMLElement, transform: HTMLElement, original: HTMLElement,
                  scale: React.MutableRefObject<number>, scaleMultiplier: number, anchor?: Dimensions,
                  scrollRatio?: Dimensions): void {
  const calculatedAnchor = anchor ?? getContainerMiddle(container);
  const calculatedScrollRatio = scrollRatio ?? anchorToScrollRatio(container, content, calculatedAnchor);
  const newScale = clamp(scale.current * scaleMultiplier, SCROLL_CONTAINER.minScale, SCROLL_CONTAINER.maxScale);
  const getScaled = (dimension: number) => pixel(dimension * newScale);
  content.style.width = getScaled(original.offsetWidth);
  content.style.height = getScaled(original.offsetHeight);
  transform.style.transform = `scale(${newScale})`;
  scale.current = newScale;
  const scrollPosition = scrollRatioAndAnchorToScrollPosition(
    container, content, calculatedScrollRatio, calculatedAnchor
  );
  container.scrollTo({
    left: scrollPosition.x,
    top: scrollPosition.y
  });
  container.dispatchEvent(new CustomEvent(scaleEventName));
}

function initialize(original: HTMLElement, initialScale: number, initialScrollRatio: Dimensions,
                    scrollRatioSetter: ScrollRatioSetter,
                    scale: React.MutableRefObject<number>, scaleSetter: ScaleSetter): Cleaner {
  const scaleToInitialScale = () => scaleSetter(scale, initialScale);
  const scrollToInitialPosition = () => scrollRatioSetter(initialScrollRatio);
  scaleToInitialScale();
  scrollToInitialPosition();
  const resizeObserver = new ResizeObserver(scrollToInitialPosition);
  resizeObserver.observe(original);
  return () => {
    resizeObserver.disconnect();
  };
}

function setUpPinchZoomDesktop(container: HTMLElement, content: HTMLElement, scale: React.MutableRefObject<number>,
                               isTouched: React.MutableRefObject<boolean>, scaleSetter: ScaleSetter): Cleaner {
  // The below is used so that the zooming is not suffering from drifting due to scroll rounding error:
  let wheelScrollLastOffsets = unsetDimensions;
  let wheelScrollRatio: Dimensions | null = null;

  const zoom = (multiplier: number, anchor: Dimensions) => {
    const currentRatio = anchorToScrollRatio(container, content, anchor);
    if (wheelScrollRatio === null ||
        !dimensionsEqual(wheelScrollLastOffsets, {x: container.scrollLeft, y: container.scrollTop})) {
      wheelScrollRatio = currentRatio;
    }
    const chooseRelevantRatio = (stored: number, current: number, scrollSize: number, viewportSize: number) =>
      scrollSize > viewportSize ? stored : current;
    wheelScrollRatio = {
      x: chooseRelevantRatio(wheelScrollRatio.x, currentRatio.x, container.scrollWidth, container.clientWidth),
      y: chooseRelevantRatio(wheelScrollRatio.y, currentRatio.y, container.scrollHeight, container.clientHeight)
    };
    scaleSetter(scale, multiplier, anchor, wheelScrollRatio);
    wheelScrollLastOffsets = {
      x: container.scrollLeft,
      y: container.scrollTop
    };
  };

  const setUpDesktopBrowsersExceptSafari = () => {
    const onWheelEvent = ((event: CustomEvent<WheelZoomDetails>) => {
      const direction = event.detail.direction === Zoom.In ? 1 : -1;
      zoom(1 + direction * event.detail.strength * SCROLL_CONTAINER.wheelZoom.scaleMultiplier, event.detail.anchor);
    }) as EventListener;
    const onMouseMoveEvent = () => wheelScrollRatio = null;
    const mouseMoveEvent = 'mousemove';
    const wheelEvent = attachWheelZoom(container, SCROLL_CONTAINER.wheelZoom);
    container.addEventListener(wheelEvent, onWheelEvent, {passive: false});
    container.addEventListener(mouseMoveEvent, onMouseMoveEvent);
    return () => {
      container.removeEventListener(mouseMoveEvent, onMouseMoveEvent);
      container.removeEventListener(wheelEvent, onWheelEvent);
      detachWheelZoom(container);
    };
  };

  const setUpSafari = () => {
    let initialGestureScale = 1;
    const onGestureStartEvent = (event: Event) => {
      event.preventDefault();
      initialGestureScale = scale.current;
    };
    const onGestureChangeEvent = (event: Event) => {
      event.preventDefault();
      if (!isTouched.current) {
        zoom(
          (event as any).scale * initialGestureScale / scale.current,
          getRelativeCoordinates(event as any, container)
        );
      }
    };
    const gestureStartEvent = 'gesturestart';
    const gestureChangeEvent = 'gesturechange';
    container.addEventListener(gestureStartEvent, onGestureStartEvent);
    container.addEventListener(gestureChangeEvent, onGestureChangeEvent);
    return () => {
      container.removeEventListener(gestureChangeEvent, onGestureChangeEvent);
      container.removeEventListener(gestureStartEvent, onGestureStartEvent);
    };
  };

  return collectCleaners(
    setUpDesktopBrowsersExceptSafari(),
    setUpSafari()
  );
}

function setUpPinchZoomMobile(container: HTMLElement, isTouched: React.MutableRefObject<boolean>,
                              scale: React.MutableRefObject<number>, scaleSetter: ScaleSetter): Cleaner {
  const touchStartEvent = 'touchstart';
  const touchMoveEvent = 'touchmove';
  const touchEndEvent = 'touchend';
  const touchCancelEvent = 'touchcancel';
  const touchInitialPositions = new Map<number, Dimensions>();
  const updateIsTouchedAndPositions = (event: TouchEvent) => {
    isTouched.current = event.touches.length > 0;
    const activeIdentifiers = [...event.touches].map((touch) => touch.identifier);
    [...touchInitialPositions.keys()].forEach((identifier) => {
      if (!activeIdentifiers.includes(identifier)) {
        touchInitialPositions.delete(identifier)
      }
    });
  };
  let initialScale = 1;
  // The following is needed because in iOS and in Chrome/Android, a first touch - move - second touch - move sequence
  // sometimes results in a (non-preventable) scroll which interferes with the scaling
  let touchSpoilingInfo: {
    lastScrollPosition: Dimensions,
    spoiled: boolean
  } = {
    lastScrollPosition: unsetDimensions,
    spoiled: false
  };
  const getCurrentScrollPosition = () => ({
    x: container.scrollLeft,
    y: container.scrollTop
  }) as Dimensions;
  const onTouchStartEvent = (event: TouchEvent) => {
    updateIsTouchedAndPositions(event);
    [...event.touches].forEach( // All touches' positions should be updated, not just the started ones
      (touch) => touchInitialPositions.set(touch.identifier, getRelativeCoordinates(touch, container))
    );
    initialScale = scale.current;
    const isOverscrolled = (position: number, scrollSize: number, viewportSize: number) =>
      position < 0 || position + viewportSize > Math.max(scrollSize, viewportSize) + 1;
      // +1 is to avoid potential rounding issues
    touchSpoilingInfo = {
      lastScrollPosition: getCurrentScrollPosition(),
      spoiled: isOverscrolled(container.scrollLeft, container.scrollWidth, container.clientWidth)
            || isOverscrolled(container.scrollTop, container.scrollHeight, container.clientHeight)
    };
  };
  const onTouchMoveEvent = (event: TouchEvent) => {
    const touches = [...event.touches];
    if (touches.length !== 2 || touchSpoilingInfo.spoiled) {
      return;
    }
    if (!dimensionsEqual(getCurrentScrollPosition(), touchSpoilingInfo.lastScrollPosition)) {
      touchSpoilingInfo = {
        lastScrollPosition: unsetDimensions,
        spoiled: true
      };
      return;
    }
    const initial = touches.map((touch) => touchInitialPositions.get(touch.identifier) ?? unsetDimensions);
    const current = touches.map((touch) => getRelativeCoordinates(touch, container));
    const initialDistance = getDistance(initial[0], initial[1]);
    const currentDistance = getDistance(current[0], current[1]);
    const scaleMultiplier = currentDistance / Math.max(0.01, initialDistance) * initialScale / scale.current;
    if (!container.classList.contains(containerPinchZoomedClassName)) {
      container.classList.add(containerPinchZoomedClassName);
    }
    scaleSetter(scale, scaleMultiplier, getMiddle(current[0], current[1]));
    touchSpoilingInfo = {
      lastScrollPosition: getCurrentScrollPosition(),
      spoiled: false
    };
  };
  const endOrCancel = (event: TouchEvent) => {
    updateIsTouchedAndPositions(event);
    container.classList.remove(containerPinchZoomedClassName);
  };
  const onTouchEndEvent = (event: TouchEvent) => endOrCancel(event);
  const onTouchCancelEvent = (event: TouchEvent) => endOrCancel(event);
  container.addEventListener(touchStartEvent, onTouchStartEvent);
  container.addEventListener(touchMoveEvent, onTouchMoveEvent);
  container.addEventListener(touchEndEvent, onTouchEndEvent);
  container.addEventListener(touchCancelEvent, onTouchCancelEvent);
  return () => {
    container.removeEventListener(touchCancelEvent, onTouchCancelEvent);
    container.removeEventListener(touchEndEvent, onTouchEndEvent);
    container.removeEventListener(touchMoveEvent, onTouchMoveEvent);
    container.removeEventListener(touchStartEvent, onTouchStartEvent);
  };
}

function setUpResetZoomListener(scale: React.MutableRefObject<number>, scaleSetter: ScaleSetter): Cleaner {
  const keydownEvent = 'keydown';
  const onKeyDownEvent = (event: KeyboardEvent) => {
    if (event.key === '0' && (event.ctrlKey || event.metaKey)) {
      scaleSetter(scale, 1 / scale.current);
    }
  };
  document.addEventListener(keydownEvent, onKeyDownEvent);
  return () => {
    document.removeEventListener(keydownEvent, onKeyDownEvent);
  };
}

function getTargetType(target: EventTarget | null, container: HTMLElement): TargetType {
  if (!(target instanceof HTMLElement || target instanceof SVGElement)) {
    return TargetType.General;
  }
  if (isInsideTag(target, cursorOptions.interactiveTags, container)) {
    return TargetType.Interactive;
  }
  if (isInsideTag(target, cursorOptions.textTags, container)) {
    return TargetType.SelectableText;
  }
  return TargetType.General;
}

function isDraggable(container: HTMLElement): boolean {
  return container.scrollWidth > container.offsetWidth || container.scrollHeight > container.offsetHeight;
}

function setUpCursorRelatedListeners(container: HTMLElement, scale: React.MutableRefObject<number>,
                                     scaleSetter: ScaleSetter): Cleaner {
  const touchStartEvent = 'touchstart';
  const mouseDownEvent = 'mousedown';
  const mouseMoveEvent = 'mousemove';
  const mouseUpEvent = 'mouseup';
  const mouseOverEvent = 'mouseover';
  const doubleClickEvent = 'dblclick';
  let dragging: {
    initialCursor: Dimensions,
    initialScroll: Dimensions
  } | null = null;
  let lastTouch: Date | null = null;
  const onTouchStartEvent = () => lastTouch = new Date();
  const isTouch = (eventTime: Date) =>
    lastTouch !== null && eventTime.getTime() - lastTouch.getTime() <= SCROLL_CONTAINER.touchTimeout;
  const onMouseDownEvent = (event: MouseEvent) => {
    const targetType = getTargetType(event.target, container);
    const touch = isTouch(new Date());
    if (event.detail > 0 /* screen readers can set 0 */ && event.detail % 2 === 0 /* double click */
        && targetType !== TargetType.Interactive
        && (touch || targetType !== TargetType.SelectableText)) {
      // With dblclick, it's impossible to prevent text selection, hence mousedown is used
      event.preventDefault();
      scaleSetter(scale, cursorOptions.doubleClickScaleMultiplier, getRelativeCoordinates(event, container));
    }
    if (!touch && event.detail === 1 && event.button === 0
        && targetType === TargetType.General && isDraggable(container)) {
      event.preventDefault();
      // Closing the word input dropdown if it is open or deselecting an arrow selected with the keyboard:
      if (document.activeElement instanceof HTMLElement || document.activeElement instanceof SVGElement) {
        document.activeElement.blur();
      }
      window.getSelection()?.removeAllRanges();
      dragging = {
        initialCursor: {x: event.clientX, y: event.clientY},
        initialScroll: {x: container.scrollLeft, y: container.scrollTop}
      };
      container.style.cursor = cursorOptions.draggingCursor;
    }
  };
  const onMouseMoveEvent = (event: MouseEvent) => {
    if (dragging === null) {
      return;
    }
    container.scrollLeft = dragging.initialScroll.x + dragging.initialCursor.x - event.clientX;
    container.scrollTop = dragging.initialScroll.y + dragging.initialCursor.y - event.clientY;
  };
  const lastNode = (nodes: NodeListOf<Element>) => nodes.length > 0 ? [...nodes].slice(-1)[0] : null;
  const updateNonDraggingCursor = (event?: MouseEvent) => {
    const getCursor = (targetType: TargetType) => {
      switch (targetType) {
        case TargetType.General:
          return isDraggable(container) ? cursorOptions.canDragCursor : cursorOptions.cannotDragCursor;
        case TargetType.Interactive:
          return cursorOptions.interactiveCursor;
        case TargetType.SelectableText:
          return cursorOptions.selectableTextCursor;
      }
    };
    const element = event ? event.target : lastNode(container.querySelectorAll(':hover'));
    container.style.cursor = getCursor(getTargetType(element, container));
  };
  const onMouseUpEvent = (event: MouseEvent) => {
    if (dragging === null) {
      return;
    }
    dragging = null;
    updateNonDraggingCursor(event);
  };
  const onMouseOverEvent = (event: MouseEvent) => {
    if (dragging === null) {
      updateNonDraggingCursor(event);
    }
  };
  const onScaleResizeEvent = () => {
    if (dragging === null) {
      updateNonDraggingCursor();
    }
  };
  const onDoubleClickEvent = () => {}; // Apparently, handling this event is needed for mouseDownEvent.detail to be
                                       // the actual value instead of always 1 on iOS with touch-action: manipulation
  document.addEventListener(doubleClickEvent, onDoubleClickEvent);
  document.addEventListener(touchStartEvent, onTouchStartEvent);
  container.addEventListener(mouseDownEvent, onMouseDownEvent);
  document.addEventListener(mouseMoveEvent, onMouseMoveEvent);
  document.addEventListener(mouseUpEvent, onMouseUpEvent);
  container.addEventListener(mouseOverEvent, onMouseOverEvent);
  container.addEventListener(scaleEventName, onScaleResizeEvent);
  const resizeObserver = new ResizeObserver(onScaleResizeEvent);
  resizeObserver.observe(container); // In particular, this is needed for when "Hide menu" is clicked
  return () => {
    resizeObserver.disconnect();
    container.removeEventListener(scaleEventName, onScaleResizeEvent);
    container.removeEventListener(mouseOverEvent, onMouseOverEvent);
    document.removeEventListener(mouseUpEvent, onMouseUpEvent);
    document.removeEventListener(mouseMoveEvent, onMouseMoveEvent);
    container.removeEventListener(mouseDownEvent, onMouseDownEvent);
    document.removeEventListener(touchStartEvent, onTouchStartEvent);
    document.removeEventListener(doubleClickEvent, onDoubleClickEvent);
  };
}

function setUpZoomButtons(zoomIn: HTMLButtonElement, zoomOut: HTMLButtonElement, container: HTMLElement,
                          scale: React.MutableRefObject<number>, scaleSetter: ScaleSetter): Cleaner {
  const clickEvent = 'click';
  const zoom = (direction: Zoom) => {
    scaleSetter(scale, direction === Zoom.In ? buttonZoomMultiplier : 1 / buttonZoomMultiplier);
  };
  const onZoomIn = () => zoom(Zoom.In);
  const onZoomOut = () => zoom(Zoom.Out);
  const updateState = () => {
    zoomIn.disabled = scale.current >= SCROLL_CONTAINER.maxScale;
    zoomOut.disabled = scale.current <= SCROLL_CONTAINER.minScale;
  };
  updateState();
  zoomIn.addEventListener(clickEvent, onZoomIn);
  zoomOut.addEventListener(clickEvent, onZoomOut);
  container.addEventListener(scaleEventName, updateState);
  return () => {
    container.removeEventListener(scaleEventName, updateState);
    zoomOut.removeEventListener(clickEvent, onZoomOut);
    zoomIn.removeEventListener(clickEvent, onZoomIn);
  };
}

function setUpExternalEventListeners(container: HTMLElement, scrollListener: ExternalEventListener | undefined,
                                     scaleListener: ExternalEventListener | undefined): Cleaner {
  const scrollEvent = 'scroll';
  const onScroll = () => scrollListener?.();
  const onScale = () => scaleListener?.();
  container.addEventListener(scrollEvent, onScroll);
  container.addEventListener(scaleEventName, onScale);
  return () => {
    container.removeEventListener(scaleEventName, onScale);
    container.removeEventListener(scrollEvent, onScroll);
  };
}

function serializeState(scale: number, scrollRatio: Dimensions): string {
  return [scale, scrollRatio.x, scrollRatio.y].join(statePartsSeparator);
}

function parseState(data: string, defaultValues: [number, Dimensions]): [number, Dimensions] {
  const parts = data.split(statePartsSeparator).map((part) => parseFloat(part));
  return parts.length === 3 && parts.every((part) => isFinite(part))
    ? [parts[0], {x: parts[1], y: parts[2]}]
    : defaultValues;
}

function getStateKey(url: string, key: string): string {
  return SCROLL_CONTAINER.state.key
    .replace(SCROLL_CONTAINER.state.urlPlaceholder, url)
    .replace(SCROLL_CONTAINER.state.keyPlaceholder, key);
}

function setUpStoringState(container: HTMLElement, scale: React.MutableRefObject<number>,
                           computedScrollRatio: Dimensions, providedInitialScrollRatio: Dimensions,
                           url: string, key: string): Cleaner {
  const scrollEvent = 'scroll';
  const stateKey = getStateKey(url, key);
  const set = (scale: number, scrollRatio: Dimensions) =>
    sessionStorage.setItem(stateKey, serializeState(scale, scrollRatio));
  const onScrollScale = () =>
    set(scale.current, getScrollRatio(container, providedInitialScrollRatio));
  set(scale.current, computedScrollRatio);
  container.addEventListener(scrollEvent, onScrollScale);
  container.addEventListener(scaleEventName, onScrollScale);
  const resizeObserver = new ResizeObserver(onScrollScale);
  resizeObserver.observe(container);
  return () => {
    resizeObserver.disconnect();
    container.removeEventListener(scaleEventName, onScrollScale);
    container.removeEventListener(scrollEvent, onScrollScale);
  };
}

export function ScrollContainer({children, initialScrollRatio, initialScale = 1, onScroll, onScale}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const transformRef = useRef<HTMLDivElement>(null);
  const originalRef = useRef<HTMLDivElement>(null);
  const zoomInRef = useRef<HTMLButtonElement>(null);
  const zoomOutRef = useRef<HTMLButtonElement>(null);
  const scale = useRef(1);
  const isTouched = useRef(false);
  const {url, key} = useUrlControllerData();
  const [[computedInitialScale, computedInitialScrollRatio]] = useState(() => {
    const defaultValues = [initialScale, initialScrollRatio] as [number, Dimensions];
    const data = sessionStorage.getItem(getStateKey(url, key));
    return data !== null ? parseState(data, defaultValues) : defaultValues;
  });
  const [providedInitialScrollRatio] = useState(initialScrollRatio);
  useLayoutEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    const transform = transformRef.current;
    const original = originalRef.current;
    const zoomIn = zoomInRef.current;
    const zoomOut = zoomOutRef.current;
    if (container === null || content === null || transform === null || original === null ||
        zoomIn === null || zoomOut === null) {
      return;
    }
    const scaleSetter = setScale.bind(null, container, content, transform, original);
    const scrollRatioSetter = setScrollRatio.bind(null, container);
    return collectCleaners(
      initialize(original, computedInitialScale, computedInitialScrollRatio, scrollRatioSetter, scale, scaleSetter),
      setUpPinchZoomDesktop(container, content, scale, isTouched, scaleSetter),
      setUpPinchZoomMobile(container, isTouched, scale, scaleSetter),
      setUpResetZoomListener(scale, scaleSetter),
      setUpCursorRelatedListeners(container, scale, scaleSetter),
      setUpZoomButtons(zoomIn, zoomOut, container, scale, scaleSetter),
      setUpExternalEventListeners(container, onScroll, onScale),
      setUpStoringState(container, scale, computedInitialScrollRatio, providedInitialScrollRatio, url, key)
    );
  }, [computedInitialScale, computedInitialScrollRatio, providedInitialScrollRatio, url, key, onScroll, onScale]);
  return (
    <div className="scrollContainer">
      <div className={containerClassName} ref={containerRef}>
        <div className="scrollContainer_padding">
          <div className="scrollContainer_content" ref={contentRef}>
            <div className="scrollContainer_transform" ref={transformRef}>
              <div className="scrollContainer_original" ref={originalRef}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scrollContainer_zoomControls">
        <div className="scrollContainer_zoomControlsContent">
          <div className="scrollContainer_zoomButtonsWrapper">
            <button
              className="scrollContainer_zoomButton scrollContainer_zoomButton-in"
              ref={zoomInRef}
              title={LOCALIZATION.scrollContainer.zoomIn.title}
              aria-label={LOCALIZATION.scrollContainer.zoomIn.label}
            >
              <ZoomInSvg className="scrollContainer_zoomButtonIcon" />
            </button>
            <button
              className="scrollContainer_zoomButton scrollContainer_zoomButton-out"
              ref={zoomOutRef}
              title={LOCALIZATION.scrollContainer.zoomOut.title}
              aria-label={LOCALIZATION.scrollContainer.zoomOut.label}
            >
              <ZoomOutSvg className="scrollContainer_zoomButtonIcon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}