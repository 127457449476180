import React from 'react';
import {StaticSubsection} from '../../types/Section';
import {useHash} from '../../hooks/useHash';
import {ReactComponent as MailSvg} from '../../images/mail.svg';
import wiktionary from '../../images/about/wiktionary.webp';
import authors from '../../images/about/authors/authors.webp';
import './About.scss';

export function About() {
  const hash = useHash();
  return (
    <div className="about">
      <section className="about_section" aria-labelledby="quota-and-feedback">
        <h2 className="about_header" id="quota-and-feedback" hidden>Quota and feedback</h2>
        <div className="about_parallel">
          <div className="about_left">
            <p>
              <span className={`about_quota ${hash === StaticSubsection.Quota ? 'about_quota-highlighted' : ''}`}>
                Etym.io is a non-commercial project offered without warranties. To prevent abuse, we limit the total
                number of interactions to 100 etymology/cognate queries and 2,500 word match queries during any
                contiguous <span className="nowrap">24-hour</span> period per IP address. If you require a quota
                increase, please contact us at <a href="mailto:info@etym.io">info@etym.io</a>.
              </span>
            </p>
          </div>
          <address className="about_right about_right-verticallyCentered">
            <a className="about_mail" href="mailto:info@etym.io">
              <MailSvg className="about_mailIcon" aria-hidden={true} />info@etym.io
            </a>
          </address>
        </div>
      </section>
      <section className="about_section" aria-labelledby="linguistic-sources">
        <h2 className="about_header" id="linguistic-sources">Linguistic sources</h2>
        <div className="about_parallel">
          <div className="about_left">
            <p>
              The majority of the data used by the website originates from the English edition
              of <a href="https://www.wiktionary.org/">Wiktionary</a>, a free multilingual dictionary and an invaluable
              resource made possible through the collaboration of thousands of enthusiastic volunteers.
            </p>
            <p>
              Please note that while Wiktionary is generally a reliable source, errors can occur. Not all data listed in
              Wiktionary is backed with a reference to a printed dictionary, so be aware that some of the etymologies we
              present might not be authentic or at least universally accepted.
            </p>
            <p>
              We also rely on other sources, most notably <a lang="uk" href={
                'https://uk.wikipedia.org/wiki/%D0%95%D1%82%D0%B8%D0%BC%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0' +
                '%B8%D0%B9_%D1%81%D0%BB%D0%BE%D0%B2%D0%BD%D0%B8%D0%BA_%D1%83%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D1%81%D1' +
                '%8C%D0%BA%D0%BE%D1%97_%D0%BC%D0%BE%D0%B2%D0%B8'
              }>Етимологічний словник української мови</a> (an etymology dictionary of the Ukrainian language).
            </p>
            <p>
              You can examine the source of an etymological relation by clicking on the arrow linking the words of
              interest.
            </p>
          </div>
          <aside className="about_right">
            <div className="about_wiktionaryContainer">
              <img className="about_wiktionary" src={wiktionary} alt="Wiktionary" />
            </div>
          </aside>
        </div>
      </section>
      <section className="about_section" aria-labelledby="authors">
        <h2 className="about_header" id="authors">Authors</h2>
        <div className="about_authors">
          <div className="about_leftAuthor">
            <p>
              <a href="https://github.com/tamila-krashtan">Tamila Krashtan</a>, a computational linguist
              and programmer, is responsible for collecting the data we use to display etymological trees
              and cognates. She is also the author of the <a
              href="https://github.com/tamila-krashtan/strbalance">Strbalance</a> Python library, a tool
              for correcting text with inconsistent opening and closing punctuation marks. This library was
              instrumental for data cleansing, a crucial stage in launching Etym.io.
            </p>
          </div>
          <img src={authors} alt="Authors" className="about_authorsPhoto" />
          <div className="about_rightAuthor">
            <p>
              <a href="https://github.com/danmysak">Danylo Mysak</a>, a web developer and linguistics
              enthusiast, manages the technical aspects of Etym.io, as well as some of the data
              processing algorithms running behind the scenes. While working on restoring the letter-to-letter
              word correspondences highlighted for you in every diagram, he created the <a
              href="https://github.com/danmysak/ipa-parser">IPA parser for Python</a>, capable of parsing almost
              any transcription that can be found on Wiktionary.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}