import {LOCALIZATION} from '../constants/localization';
import {Location} from '../types/Location';
import {Section} from '../types/Section';
import {Word} from '../types/Word';
import {urlToLocation} from './urlToLocation';
import {embedText} from './embedders/textEmbedder';

function constructTitle(template: string, noWords: string, withWords: string,
                        wordA: Word | null, wordB: Word | null = null): string {
  return embedText(template, wordA === null ? noWords : embedText(withWords, (_, name) => {
    // We use plain words instead of calling getWordWithPrefix since:
    // 1) at the time of query it might not be known whether the word is reconstructed (when the query is performed
    // based on the entered URL);
    // 2) it is arguably more natural to omit asterisks in the title (and within the quotes) in the first place.
    switch (name as 'a' | 'b') {
      case 'a':
        return wordA.word;
      case 'b':
        return wordB === null ? '' : wordB.word;
    }
  }));
}

export function locationToTitle(location: Location | null): string {
  const localization = LOCALIZATION.title;
  if (location === null) {
    return localization.notFound;
  }
  const template = localization.generalTemplate;
  switch (location.section) {
    case Section.Etymology:
      return constructTitle(template, localization.etymology, localization.etymologyOf, location.query?.word ?? null);
    case Section.Relation:
      return constructTitle(template, localization.relation, localization.relationOf,
                            location.query?.wordA ?? null, location.query?.wordB ?? null);
    case Section.Cognates:
      return constructTitle(template, localization.cognates, localization.cognatesOf, location.query?.word ?? null);
    case Section.About:
      return LOCALIZATION.title.about;
  }
}

export function urlToTitle(path: string, query: string): string {
  return locationToTitle(urlToLocation(path, query));
}