import React from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {APIResponseData} from '../../types/APIResponse';
import {APIRequestType} from '../../types/APIRequest';
import {Word} from '../../types/Word';
import {embedNodes} from '../../helpers/embedders/nodeEmbedder';
import {wordsIdentical} from '../../helpers/wordHelper';
import {getLanguageName} from '../../helpers/languageHelper';
import {SoftAlert} from '../SoftAlert/SoftAlert';
import {SimpleWordContainer} from '../SimpleWordContainer/SimpleWordContainer';
import './Missing.scss';

type Props = {
  data: APIResponseData
};

function getWrappedWord(word: Word): React.ReactNode {
  return embedNodes(LOCALIZATION.alerts.noData.wordTemplate, (_, name) => {
    switch (name as 'word' | 'language') {
      case 'word':
        return <SimpleWordContainer className="missing_word" word={word} />;
      case 'language':
        return getLanguageName(word.language, true);
    }
  });
}

function getText(data: APIResponseData): React.ReactNode {
  const alerts = LOCALIZATION.alerts.noData;
  switch (data.type) {
    case APIRequestType.Etymology: {
      return embedNodes(alerts.etymology, getWrappedWord(data.response.word));
    }
    case APIRequestType.Relation: {
      if (wordsIdentical(data.response.words[0], data.response.words[1])) {
        return embedNodes(alerts.relationSame, getWrappedWord(data.response.words[0]));
      } else {
        return embedNodes(alerts.relation, (_, name) => getWrappedWord({
          a: data.response.words[0],
          b: data.response.words[1]
        }[name as 'a' | 'b']));
      }
    }
    case APIRequestType.Cognates: {
      return embedNodes(alerts.cognates, (_, name) => {
        switch (name as 'word' | 'languages') {
          case 'word':
            return getWrappedWord(data.response.word);
          case 'languages':
            return data.response.languages.flatMap((language, index, languages) => [
              index === 0 ? '' : alerts.languageConnectors[
                index === languages.length - 1
                  ? (languages.length === 2 ? 'betweenTwo' : 'lastConnector')
                  : 'default'
              ],
              getLanguageName(language, true)
            ]).join('');
        }
      });
    }
    default:
      return null; // Shouldn't happen
  }
}

export function Missing({data}: Props) {
  return (
    <SoftAlert className="missing">
      {getText(data)}
    </SoftAlert>
  );
}