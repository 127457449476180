export function getSymmetricEnd<T>(list: T[], index: number): T | undefined {
  return (index === 0) !== (index === list.length - 1) ? list[list.length - 1 - index] : undefined;
}

export function getFirst<T>(list: T[] | null, shift: number = 0): T | null {
  return list === null || list.length <= shift ? null : list[shift];
}

export function getLast<T>(list: T[] | null, shift: number = 0): T | null {
  return list === null || list.length <= shift ? null : list[list.length - 1 - shift];
}

export function rangeUp(fromInclusive: number, toInclusive: number): number[] {
  const range: number[] = [];
  for (let i = fromInclusive; i <= toInclusive; i++) {
    range.push(i);
  }
  return range;
}

export function rangeDown(fromInclusive: number, toInclusive: number): number[] {
  const range: number[] = [];
  for (let i = fromInclusive; i >= toInclusive; i--) {
    range.push(i);
  }
  return range;
}

export function getAdjacentPairs<T>(list: T[]): [T, T, number, number, T[]][] {
  const pairsWithIndices: [T, T, number, number, T[]][] = [];
  for (let i = 1; i < list.length; i++) {
    const [a, b] = [i - 1, i];
    pairsWithIndices.push([list[a], list[b], a, b, list]);
  }
  return pairsWithIndices;
}

export function ifNotNull<T>(value: T | null): T[] {
  return value === null ? [] : [value];
}

export function nonNull<T>(values: (T | null)[]): T[] {
  return values.flatMap((value) => ifNotNull(value));
}

export function sum(values: number[]): number {
  return values.reduce((a, b) => a + b, 0);
}