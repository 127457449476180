import React, {ReactElement} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {Horizontal} from '../../types/Geometry';
import {Word} from '../../types/Word';
import {getWordWithPrefix} from '../../helpers/wordHelper';
import {embedNodes} from '../../helpers/embedders/nodeEmbedder';
import {useUniqueId} from '../../hooks/useUniqueId';
import {HorizontalScroller} from '../HorizontalScroller/HorizontalScroller';
import {DashedText} from '../DashedText/DashedText';
import {SimpleWordContainer} from '../SimpleWordContainer/SimpleWordContainer';
import {ReactComponent as EditSvg} from '../../images/edit.svg';
import {ReactComponent as HideSvg} from '../../images/hide.svg';
import './SubrequestOptions.scss';

type OptionData = {
  text: string,
  href: string
};

type WordData = {
  word: Word,
  onClicked: () => void
};

type Props = {
  options: OptionData[],
  selectedIndex: number,
  onSwitched: (index: number) => void,
  leftWord: WordData | null,
  rightWord: WordData | null,
  wordsAsButtons: boolean,
  topCollapsed: boolean,
  onTopSwitchClicked: () => void
};

function button(labels: React.ReactNode[], shownLabelIndex: number,
                Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
                title: string | null, ariaLabels: React.ReactNode[], labelId: string,
                isWord: boolean, onClicked: () => void, position: Horizontal, enabled: boolean,
                classNameModifiers: string[] = []): ReactElement {
  return (
    <div className={`subrequestOptions_buttonContainer subrequestOptions_buttonContainer-${position} ` +
                    `subrequestOptions_buttonContainer-${isWord ? 'word' : 'nonword'} ` +
                    classNameModifiers.map((modifier) => `subrequestOptions_buttonContainer-${modifier}`).join(' ')}>
      <span id={labelId} hidden>{ariaLabels[shownLabelIndex]}</span>
      <button
        className={`subrequestOptions_button subrequestOptions_button-${position}`}
        onClick={onClicked}
        disabled={!enabled}
        title={enabled && title !== null ? title : ''}
        aria-labelledby={labelId}
      >
        <div
          className="subrequestOptions_buttonLabels"
          aria-hidden={true} // This is to avoid screen readers reading duplicated content
        >
          {labels.map((label, index) => (
            <DashedText key={index} outline={true} truncate={true} className={
              `subrequestOptions_buttonLabel subrequestOptions_buttonLabel-${
                index === shownLabelIndex ? 'visible' : 'hidden'
              }`
            }>
              {label}
            </DashedText>
          ))}
        </div>
        <Icon className={`subrequestOptions_editIcon subrequestOptions_editIcon-${position}`} />
      </button>
    </div>
  );
}

function wordButton(word: WordData | null, wordsAsButtons: boolean, labelId: string): ReactElement | null {
  return word && button(
    [getWordWithPrefix(word.word)], 0, EditSvg, LOCALIZATION.wordInputs.edit.title,
    [embedNodes(LOCALIZATION.wordInputs.edit.label, <SimpleWordContainer word={word.word} />)], labelId,
    true, word.onClicked, Horizontal.Left, wordsAsButtons
  );
}

export function SubrequestOptions({
  options, selectedIndex, onSwitched, leftWord, rightWord, wordsAsButtons, topCollapsed, onTopSwitchClicked
}: Props) {
  const leftLabelId = useUniqueId();
  const rightLabelId = useUniqueId();
  const headerButtonLabelId = useUniqueId();
  return (
    <div className="subrequestOptions">
      <div className="subrequestOptions_left">
        {wordButton(leftWord, wordsAsButtons, leftLabelId)}
        {leftWord !== null && rightWord !== null && (
          <div className="subrequestOptions_wordSeparator">/</div>
        )}
        {wordButton(rightWord, wordsAsButtons, rightLabelId)}
      </div>
      <HorizontalScroller className="subrequestOptions_options"
                          label={LOCALIZATION.senses.label}
                          options={options}
                          selectedIndex={selectedIndex}
                          onSwitched={onSwitched} />
      <div className="subrequestOptions_right">
        {button(
          [LOCALIZATION.menu.show.title, LOCALIZATION.menu.hide.title], topCollapsed ? 0 : 1, HideSvg, null,
          [LOCALIZATION.menu.show.label, LOCALIZATION.menu.hide.label], headerButtonLabelId, false, onTopSwitchClicked,
          Horizontal.Right, true, ['topSwitch', topCollapsed ? 'topCollapsed' : 'topExpanded']
        )}
      </div>
    </div>
  );
}