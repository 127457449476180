import smoothscroll from 'smoothscroll-polyfill';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {LOCALIZATION} from '../../constants/localization';
import {LANGUAGES_OF_INTEREST} from '../../constants/constants';
import {APIRequestType} from '../../types/APIRequest';
import {APIResponseStatus, APIUserLanguages} from '../../types/APIResponse';
import {getInitialState, State, StateContext} from '../../context/StateContext';
import {getUpdater, Update, UpdateContext} from '../../context/UpdateContext';
import {urlAtLocation} from '../../helpers/locationToUrl';
import {useApi} from '../../hooks/useApi';
import {useHash} from '../../hooks/useHash';
import {useUrlController} from '../../hooks/useUrlController';
import {ErrorPage} from '../ErrorPage/ErrorPage';
import {Collapsable} from '../Collapsable/Collapsable';
import {Menu} from '../Menu/Menu';
import {Header} from '../Header/Header';
import {Content} from '../Content/Content';
import {AutoFocusedContainer} from '../AutoFocusedContainer/AutoFocusedContainer';
import './App.scss';

smoothscroll.polyfill();

function updateInputLanguages(state: State, update: Update): void {
  if (state.api === null || state.api.response.status !== APIResponseStatus.OK) {
    return;
  }
  let languageA = null;
  let languageB = null;
  switch (state.api.response.data.type) {
    case APIRequestType.Etymology:
    case APIRequestType.Cognates: {
      const language = state.api.response.data.response.word.language;
      if (state.inputA.word !== null && !state.inputA.word.language.name && language.name) {
        languageA = language;
      }
      break;
    }
    case APIRequestType.Relation: {
      const [a, b] = state.api.response.data.response.words.map(({language}) => language);
      if (state.inputA.word !== null && !state.inputA.word.language.name && a.name) {
        languageA = a;
      }
      if (state.inputB.word !== null && !state.inputB.word.language.name && b.name) {
        languageB = b;
      }
      break;
    }
    default:
      // Nothing to do;
      break;
  }
  if (languageA !== null) {
    update.updateInputALanguage(languageA);
  }
  if (languageB !== null) {
    update.updateInputBLanguage(languageB);
  }
}

export function App() {
  const rendering = useRef(false);
  const pendingUpdates = useRef<(() => void)[]>([]);
  rendering.current = true;
  pendingUpdates.current = [];
  const [setUrl, resetHash] = useUrlController();
  const hash = useHash();
  const [state, setState] = useState<State | null>(
    () => getInitialState(window.location.pathname, window.location.search)
  );
  const response = useApi(state?.pendingApi?.request ?? state?.api?.request ?? null);
  useLayoutEffect(() => pendingUpdates.current.forEach((update) => update()));
  const [update] = useState<Update>(() => getUpdater(
    setUrl,
    resetHash,
    (callback) => {
      const setter = () => setState((state) => state === null ? null : callback(state));
      if (rendering.current) { // Called inside the rendering cycle
        pendingUpdates.current.push(setter);
      } else {
        setter(); // Called outside of the rendering cycle
      }
    }
  ));
  const userLanguages = useApi(state?.languagesOfInterest.initialized === false ? {
    type: APIRequestType.UserLanguages
  } : null);
  if (state?.languagesOfInterest.initialized === false && userLanguages !== null) {
    if (userLanguages.status === APIResponseStatus.OK) {
      update.setLanguagesOfInterest((userLanguages.data.response as APIUserLanguages)
        .slice(0, LANGUAGES_OF_INTEREST.maxCount));
    } else if (userLanguages.status !== APIResponseStatus.Loading || userLanguages.slow) {
      update.setLanguagesOfInterest(LANGUAGES_OF_INTEREST.default);
    }
  }
  if (state !== null && state.pendingApi !== null) {
    update.pickPendingApi(hash, response!);
  }
  if (state !== null && state.api !== null && response !== state.api.response) {
    update.updateResponse(response!);
  }
  if (state !== null && state.api !== null
    && urlAtLocation(window.location.pathname, window.location.search, state.api.location)
    && state.api.subrequest !== hash) {
    update.updateSubrequest(hash);
  }
  if (state !== null) {
    updateInputLanguages(state, update);
  }
  const result = state === null ? <ErrorPage localization={LOCALIZATION.notFound} linkLocation="/" /> : (
    <StateContext.Provider value={state}>
      <UpdateContext.Provider value={update}>
        <div className="app">
          <div className="app_top">
            <Collapsable collapsed={state.topCollapsed}>
              <Menu />
              <Header />
            </Collapsable>
          </div>
          <main className="app_content" aria-live="polite">
            {state.coldStart && <AutoFocusedContainer preventScroll={true} />}
            <Content />
          </main>
        </div>
      </UpdateContext.Provider>
    </StateContext.Provider>
  );
  rendering.current = false;
  return result;
}