import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {LOCALIZATION} from '../../constants/localization';
import {Box} from '../../types/Geometry';
import {EventDispatcher} from '../../helpers/eventDispatcher';
import {
  anyCombiningKeyPressed,
  focusFirstOrLastChild,
  getDocumentCoordinates,
  isButtonClickKey,
  pixel
} from '../../helpers/domHelper';
import './Popup.scss';

const rootId = 'popup-root';
let popupCount = 0;

const wrapperMinHeightVariableName = '--popupWrapper-min-height';

const cssVariableNames = {
  closedLabel: '--popup-closed-label',
  initialLeft: '--popup-anchor-left',
  initialRight: '--popup-anchor-right',
  initialTop: '--popup-anchor-top',
  initialBottom: '--popup-anchor-bottom',
  currentLeft: '--popup-current-anchor-left',
  currentRight: '--popup-current-anchor-right',
  currentTop: '--popup-current-anchor-top',
  currentBottom: '--popup-current-anchor-bottom',
  popupWidth: '--popup-computed-width',
  popupHeight: '--popup-computed-height'
};

export enum PopupType {
  Popover = 'popover',
  Tooltip = 'tooltip'
}

export enum PopupPosition {
  Left = 'left',
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom'
}

type Props = {
  type: PopupType,
  label: string,
  children: React.ReactNode,
  shrinkToContent?: boolean,
  anchor: React.RefObject<HTMLElement | SVGElement>,
  shouldStayOnAnchorInteraction?: boolean,
  anchorKey?: any,
  anchorBox?: Box, // Document coordinates to be used for positioning instead of the anchor
  positionPreference?: PopupPosition[],
  moveEventDispatcher?: EventDispatcher,
  onDismissed: () => void
};

const defaultPositionPreference = [PopupPosition.Bottom];

function getRoot(): HTMLElement {
  return document.getElementById(rootId)!;
}

export function popupsOpen(): boolean {
  return popupCount > 0;
}

export function Popup({
  type, label, children, shrinkToContent = false, anchor, shouldStayOnAnchorInteraction = true, anchorKey, anchorBox,
  positionPreference = defaultPositionPreference, moveEventDispatcher, onDismissed
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const measurerRef = useRef<HTMLDivElement>(null);
  const dismiss = useCallback((nextFocus: HTMLElement | SVGElement | null) => {
    if (containerRef.current !== null && document.activeElement instanceof HTMLElement &&
      containerRef.current.contains(document.activeElement)) {
      document.activeElement.blur(); // This is so Safari doesn't scroll to the bottom of the page when the popup closes
    }
    if (nextFocus !== null) {
      nextFocus.focus();
    }
    onDismissed();
  }, [onDismissed]);
  const initialCoordinates = useRef<Box | null>(null);
  useEffect(() => {
    getRoot().style.setProperty(
      cssVariableNames.closedLabel,
      `"${LOCALIZATION.popups.closedLabel.replace(/"/g, '\\"')}"`
    );
    popupCount++;
    return () => {
      popupCount--;
    };
  }, []);
  useLayoutEffect(() => {
    const anchorElement = anchor.current;
    const wrapper = wrapperRef.current;
    const container = containerRef.current;
    const popup = popupRef.current;
    const measurer = measurerRef.current;
    if (anchorElement === null || wrapper === null || container === null || popup === null || measurer === null) {
      return;
    }

    wrapper.style.setProperty(wrapperMinHeightVariableName, pixel(document.documentElement.scrollHeight - 1));
    // Since scrollHeight is a rounded value, failure to subtract 1 causes scroll bar showing up at some scales

    const bounds = anchorBox ? anchorBox : getDocumentCoordinates(anchorElement);
    if (initialCoordinates.current === null) {
      initialCoordinates.current = bounds;
    }
    const initialWidth = document.documentElement.clientWidth;
    const scrollWidth = document.documentElement.scrollWidth;
    const scrollHeight = document.documentElement.scrollHeight;

    const setPosition = (position: PopupPosition) => {
      const getPositionClassName = (position: PopupPosition) => `popup-${position}`;
      container.classList.remove(...Object.values(PopupPosition).map(getPositionClassName));
      container.classList.add(getPositionClassName(position));
    };
    const isInsideDocument = () => {
      const bounds = getDocumentCoordinates(measurer);
      return bounds.min.x >= 0 && bounds.min.y >= 0 && bounds.max.x <= scrollWidth && bounds.max.y <= scrollHeight;
    };

    for (const position of positionPreference) {
      setPosition(position);
      const popupBounds = popup.getBoundingClientRect();
      const [popupWidth, popupHeight] = [popupBounds.width, popupBounds.height];
      for (const [name, value] of Object.entries({
        [cssVariableNames.initialLeft]: initialCoordinates.current.min.x,
        [cssVariableNames.initialTop]: initialCoordinates.current.min.y,
        [cssVariableNames.initialRight]: initialCoordinates.current.max.x,
        [cssVariableNames.initialBottom]: initialCoordinates.current.max.y,
        [cssVariableNames.currentLeft]: bounds.min.x,
        [cssVariableNames.currentTop]: bounds.min.y,
        [cssVariableNames.currentRight]: bounds.max.x,
        [cssVariableNames.currentBottom]: bounds.max.y,
        [cssVariableNames.popupWidth]: popupWidth,
        [cssVariableNames.popupHeight]: popupHeight
      })) {
        container.style.setProperty(name, pixel(Math.round(value)));
      }
      if (isInsideDocument()) {
        break;
      }
    }

    const processTarget = (target: EventTarget | null) => {
      if ((target instanceof Element) && target.isConnected // An element could have been just removed from the popup
          && !container.contains(target) && (!shouldStayOnAnchorInteraction || !anchorElement.contains(target))) {
        dismiss(null);
      }
    };
    // Seems like TypeScript has a bug thinking that focusout on SVGElement isn't a FocusEvent even though it is
    const onFocusOut = (event: Event) => processTarget(event instanceof FocusEvent ? event.relatedTarget : null);
    const onMouseDown = (event: MouseEvent) => processTarget(event.target);
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dismiss(type === PopupType.Popover ? anchor.current : null);
      } else if (!anyCombiningKeyPressed(event, false) // ctrl/cmd + c, etc.
        && (
          type === PopupType.Tooltip ||
          !(event.key === 'Tab' || event.key === 'Shift') // Focusing back on a field when some text is selected
        )
      ) {
        processTarget(event.target);
      }
    };
    const onResize = () => {
      // Occasional height change is possible on mobile devices due to address bar appearing/disappearing
      // In desktop Safari, tab bar can also show up when a second tab is opened
      if (document.documentElement.clientWidth !== initialWidth) {
        dismiss(null);
      }
    };
    const onMove = () => dismiss(null);
    container.addEventListener('focusout', onFocusOut);
    anchorElement.addEventListener('focusout', onFocusOut);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', onKeyDown);
    window.addEventListener('resize', onResize);
    if (moveEventDispatcher) {
      moveEventDispatcher.addEventListener(onMove);
    }
    return () => {
      if (moveEventDispatcher) {
        moveEventDispatcher.removeEventListener(onMove);
      }
      window.removeEventListener('resize', onResize);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mousedown', onMouseDown);
      anchorElement.removeEventListener('focusout', onFocusOut);
      container.removeEventListener('focusout', onFocusOut);
    };
  }, [
    type, anchor, shouldStayOnAnchorInteraction, anchorKey, anchorBox, positionPreference, moveEventDispatcher,
    dismiss
  ]);
  const closeAndFocusAnchor = () => dismiss(anchor.current);
  const getAriaCloseButton = () => type === PopupType.Popover && (
    <div className="popup_ariaCloseButton"
         role="button"
         aria-label={LOCALIZATION.popups.closeButtonLabel}
         onClick={() => closeAndFocusAnchor()}
         onKeyDown={(event) => {
           if (isButtonClickKey(event)) {
             closeAndFocusAnchor();
           }
         }}
    />
  );
  const getFocusCatcher = (focusFirst: boolean) => type === PopupType.Popover && (
    <div className="popup_focusCatcher" tabIndex={0} onFocus={() => {
      if (contentRef.current === null) {
        return;
      }
      for (const onlyTabbable of [true, false]) {
        if (focusFirstOrLastChild(focusFirst, contentRef.current, onlyTabbable)) {
          return;
        }
      }
    }} />
  );
  return ReactDOM.createPortal((
    // The wrapper is so that when content's height overflows the screen, popups can still appear next to the
    // content at the bottom
    <div ref={wrapperRef} className="popupWrapper">
      <div ref={containerRef}
           className={`popup popup-${type} popup-${shrinkToContent ? 'shrink' : 'fixed'}`}
           role={type === PopupType.Popover ? 'dialog' : 'tooltip'}
           {...(type === PopupType.Popover ? {'aria-live': 'off', 'aria-modal': true} : {})}
           aria-label={label}
      >
        <div ref={popupRef} className="popup_popup">
          <div ref={measurerRef} className="popup_measurer" />
          {getAriaCloseButton()}
          {getFocusCatcher(false)}
          <div className="popup_content" ref={contentRef}>
            {children}
          </div>
          {getFocusCatcher(true)}
          {getAriaCloseButton()}
        </div>
        <div className="popup_arrowContainer" />
      </div>
    </div>
  ), getRoot());
}