import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNewItems} from '../../hooks/useNewItems';
import {ListEditorItem} from '../ListEditorItem/ListEditorItem';
import './ListEditor.scss';

type ListEditorItemData = {
  title: string,
  key: string,
  data: any
};

type Props = {
  items: ListEditorItemData[],
  labelledBy: string,
  inactive?: boolean,
  autoFocus: boolean,
  preventScrollOnFocus: boolean,
  onChanged: (list: any[]) => void
};

type State = {
  currentItems: ListEditorItemData[],
  nextItems: ListEditorItemData[],
  shifts: number[],
  shiftsReset: boolean,
  animatedItems: string[],
  newItems: string[],
  breakingChanges: number,
  lastReset: number
};

enum ActionType {
  ResetState = 'reset_state',
  Shift = 'shift',
  Remove = 'remove',
  MarkAnimationStarted = 'mark_animation_started',
  MarkAnimationFinished = 'mark_animation_finished'
}

type Action = {
  type: ActionType.Remove | ActionType.MarkAnimationStarted | ActionType.MarkAnimationFinished,
  key: string
} | {
  type: ActionType.Shift,
  key: string,
  shift: number,
  updateAnimated: boolean
} | {
  type: ActionType.ResetState,
  items: ListEditorItemData[],
  newItems: string[],
  breaking: boolean
};

function getKeys(items: ListEditorItemData[]): string[] {
  return items.map(({key}) => key);
}

function getCombinedKey(items: ListEditorItemData[]): string {
  return getKeys(items).join(',');
}

function getZeroShifts(items: ListEditorItemData[]): number[] {
  return new Array(items.length).fill(0);
}

function initializeState(items: ListEditorItemData[], newItems: string[]): State {
  return {
    currentItems: items,
    nextItems: items,
    shifts: getZeroShifts(items),
    shiftsReset: true,
    animatedItems: newItems,
    newItems: newItems,
    breakingChanges: 0,
    lastReset: new Date().getTime()
  };
}

function calculatePositions(state: State, key: string, shift: number): {old: number, new: number} | null {
  const index = state.currentItems.findIndex(({key: itemKey}) => itemKey === key);
  if (index < 0) {
    return null;
  }
  const oldPosition = index + state.shifts[index];
  const newPosition = Math.max(0, Math.min(state.currentItems.length - 1, index + shift));
  return {
    old: oldPosition,
    new: newPosition
  };
}

function reduceState(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.ResetState: {
      return {
        ...initializeState(action.items, action.newItems),
        breakingChanges: state.breakingChanges + (action.breaking ? 1 : 0)
      };
    }
    case ActionType.MarkAnimationStarted: {
      return state.animatedItems.includes(action.key) ? state : {
        ...state,
        animatedItems: [...state.animatedItems, action.key]
      };
    }
    case ActionType.MarkAnimationFinished: {
      return {
        ...state,
        animatedItems: state.animatedItems.filter((key) => key !== action.key),
        newItems: state.newItems.filter((key) => key !== action.key)
      };
    }
    case ActionType.Remove: {
      return {
        ...state,
        nextItems: state.currentItems.filter((item) => item.key !== action.key),
        animatedItems: [...state.animatedItems, action.key]
      };
    }
    case ActionType.Shift: {
      const positions = calculatePositions(state, action.key, action.shift);
      if (positions === null || positions.new === positions.old) {
        return state;
      }
      const shifts = state.shifts.map((shift, index) => {
        const position = index + shift;
        if (position === positions.old) {
          return positions.new - index;
        } else if ((position - positions.old) * (position - positions.new) <= 0) {
          return shift + Math.sign(positions.old - positions.new);
        } else {
          return shift;
        }
      });
      return {
        ...state,
        nextItems: state.currentItems.map(
          (item, index) => [item, index + shifts[index]] as [ListEditorItemData, number]
        ).sort(([, a], [, b]) => a - b).map(([item]) => item),
        shifts,
        shiftsReset: false,
        animatedItems: action.updateAnimated ? [
          ...state.animatedItems,
          ...state.currentItems.flatMap(
            ({key}, index) => shifts[index] === state.shifts[index] || state.animatedItems.includes(key) ? [] : [key]
          )
        ] : state.animatedItems
      };
    }
  }
}

export function ListEditor({items, labelledBy, inactive, autoFocus, preventScrollOnFocus, onChanged}: Props) {
  const containerRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (autoFocus && containerRef.current !== null) {
      containerRef.current.focus({preventScroll: preventScrollOnFocus});
    }
  }, [autoFocus, preventScrollOnFocus]);
  const [state, setState] = useState(() => initializeState(items, getKeys(items)));
  const [shouldCommit, setShouldCommit] = useState(false);
  const dispatch = useCallback((action: Action) => {
    setState((state) => reduceState(state, action));
  }, []);
  const dispatchAndCommit = useCallback((action: Action) => {
    dispatch(action);
    setShouldCommit(true);
  }, [dispatch]);
  useEffect(() => {
    if (shouldCommit) {
      setShouldCommit(false);
      if (getCombinedKey(state.nextItems) !== getCombinedKey(items)) {
        onChanged(state.nextItems.map(({data}) => data));
      }
    }
  }, [shouldCommit, state.nextItems, items, onChanged]);
  const onAnimationStarted = useCallback((key) => dispatch({
    type: ActionType.MarkAnimationStarted,
    key
  }), [dispatch]);
  const onAnimationEnded = useCallback((key) => dispatch({
    type: ActionType.MarkAnimationFinished,
    key
  }), [dispatch]);
  const onShiftRequested = useCallback((key, shift) => dispatchAndCommit({
    type: ActionType.Shift,
    key,
    shift,
    updateAnimated: true
  }), [dispatchAndCommit]);
  const onTemporaryShiftRequested = useCallback((key, shift) => dispatch({
    type: ActionType.Shift,
    key,
    shift,
    updateAnimated: false
  }), [dispatch]);
  const onStartDrag = useCallback((key) => {
    const index = state.currentItems.findIndex((item) => item.key === key);
    const [maxUp, maxDown] = index < 0 ? [0, 0] : [index, state.currentItems.length - index - 1];
    dispatch({
      type: ActionType.MarkAnimationStarted,
      key
    });
    return {maxUp, maxDown};
  }, [dispatch, state.currentItems]);
  const onEndDrag = useCallback(() => setShouldCommit(true), []);
  const onRemoved = useCallback((key) => {
    dispatchAndCommit({
      type: ActionType.Remove,
      key
    });
    containerRef.current?.focus(); // Otherwise NVDA moves its virtual focus out of the dialog
  }, [dispatchAndCommit]);
  const newItems = useNewItems(getKeys(items));
  const noPending = state.animatedItems.length === 0 && newItems.length === 0;
  const itemsKey = getCombinedKey(items);
  if ((itemsKey !== getCombinedKey(state.nextItems) && (noPending || itemsKey !== getCombinedKey(state.currentItems)))
      || (state.nextItems !== state.currentItems && noPending)) {
    dispatch({
      type: ActionType.ResetState,
      items,
      newItems,
      breaking: state.animatedItems.length > 0
    });
  }
  return (
    <ul
      className="listEditor"
      tabIndex={-1}
      ref={containerRef}
      key={state.breakingChanges}
      aria-labelledby={
        labelledBy
        // The label is for the VoiceOver (desktop), especially when it jumps to the list from the inactivated input
      }
    >
      {state.currentItems.map(({title, key, data}, index) => (
        <ListEditorItem
          key={key}
          callbackKey={key}
          justAdded={[...state.newItems, ...newItems].includes(key)}
          title={title}
          position={index + state.shifts[index]}
          focusCapturer={containerRef}
          shift={state.shifts[index]}
          animateShift={!state.shiftsReset}
          canStartAction={!(inactive ?? false) && state.currentItems.length > 1 && noPending}
          inactive={(inactive ?? false) || state.currentItems.length <= 1}
          lastReset={state.lastReset}
          onAnimationStarted={onAnimationStarted}
          onAnimationEnded={onAnimationEnded}
          onShiftRequested={onShiftRequested}
          onTemporaryShiftRequested={onTemporaryShiftRequested}
          onStartDrag={onStartDrag}
          onEndDrag={onEndDrag}
          onRemoved={onRemoved}
        />
      ))}
    </ul>
  );
}