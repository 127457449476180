import {Location} from '../types/Location';
import {Word} from '../types/Word';
import {Section} from '../types/Section';
import {APIResponseData} from '../types/APIResponse';
import {APIRequestType} from '../types/APIRequest';

function extractFromLocation(location: Location, first: boolean): Word | null {
  switch (location.section) {
    case Section.Etymology:
    case Section.Cognates:
      return (first ? location.query?.word : null) ?? null;
    case Section.Relation:
      return (first ? location.query?.wordA : location.query?.wordB) ?? null;
    default:
      return null;
  }
}

export function extractAFromLocation(location: Location): Word | null {
  return extractFromLocation(location, true);
}

export function extractBFromLocation(location: Location): Word | null {
  return extractFromLocation(location, false);
}

function extractFromResponseData(data: APIResponseData, first: boolean): Word | null {
  switch (data.type) {
    case APIRequestType.Etymology:
    case APIRequestType.Cognates:
      return first ? data.response.word : null;
    case APIRequestType.Relation:
      return first ? data.response.words[0] : data.response.words[1];
    default:
      return null;
  }
}

export function extractAFromResponseData(data: APIResponseData): Word | null {
  return extractFromResponseData(data, true);
}

export function extractBFromResponseData(data: APIResponseData): Word | null {
  return extractFromResponseData(data, false);
}