import React from 'react';
import './ErrorPage.scss';

type Props = {
  localization: {
    heading: string,
    text: string,
    link: string
  },
  linkLocation: string
};

export function ErrorPage({localization, linkLocation}: Props) {
  return (
    <div className="errorPage">
      <h1 className="errorPage_heading">{localization.heading}</h1>
      <p className="errorPage_text">{localization.text}</p>
      <p className="errorPage_linkContainer">
        <a className="errorPage_link a-colored" href={linkLocation}>{localization.link}</a>
      </p>
    </div>
  );
}